import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, convertLabel, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldInputCounter,
  FieldRadioButton,
  FieldSelectModern,
  Form,
} from '../../components';

import css from './EditListingFeaturesForm.module.css';
import {
  composeValidators,
  numberValidate,
  required,
  requiredFieldArrayCheckbox,
  sharedBedroomValidate,
  zeroNumberValidate,
} from '../../util/validators';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        invalid,
        intl,
        values,
        newLabel,
        privateSelected,
        form,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const amentiesRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.amentiesRequired',
      });
      const numberWithoutFractions = numberValidate(
        intl.formatMessage({ id: 'EditListingFeaturesForm.numberWithoutFractions' })
      );

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;
      const showAsRequired = pristine && required('required');
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;
      const bedroomValidate =
        values?.type?.key === 'private_room' || values?.type?.key === 'shared_room'
          ? sharedBedroomValidate(
              intl.formatMessage({
                id: 'EditListingFeaturesForm.EditListingFeaturesForm.sharedBedroomValidateMessage',
              })
            )
          : zeroNumberValidate(
              <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.bathroomsCantbeZeroRequired" />
            );
      const options = findOptionsForSelectFilter('amenities', filterConfig);
      // console.log('newLabel', newLabel);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {values?.stay ? null : (
            <FieldSelectModern
              className={css.features}
              id="type"
              name="type"
              label={<FormattedMessage id="EditListingFeaturesForm.type" />}
              options={newLabel}
              placeholder={intl.formatMessage({
                id: 'EditListingPoliciesForm.EditListingPoliciesForm.selectPlaceholder',
              })}
              validate={requiredFieldArrayCheckbox(
                <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.typeOfAccommodationIsRequired" />
              )}
              custom={true}
              onFieldChange={e => {
                if (e?.key === 'private_room' || e?.key === 'shared_room') {
                  form.change('bedrooms', 1);
                } else {
                  form.change('bedrooms', 0);
                }
              }}
            />
          )}
          {/* {values?.type?.key === 'private_room' || values?.type?.key === 'shared_room' ? (
            <FieldSelectModern
              className={css.features}
              id="private_selected"
              name="private_selected"
              label={<FormattedMessage id="EditListingFeaturesForm.privateSelected" />}
              options={privateSelected}
              validate={requiredFieldArrayCheckbox(
                <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.typeOfStayIsRequired" />
              )}
              custom={true}
            />
          ) : null} */}
          <p className={css.label}>
            <FormattedMessage id="EditListingFeaturesForm.counterHeading" />
          </p>
          <div className={css.counterContainer}>
            <FieldInputCounter
              className={css.counter}
              id="guests"
              name="guests"
              type="number"
              min={0}
              label={<FormattedMessage id="EditListingFeaturesForm.guests" />}
              validate={zeroNumberValidate(
                <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.GuestsRequired" />
              )}
            />
            {/* <FieldInputCounter
              className={css.counter}
              id="persons"
              name="persons"
              type="number"
              min={0}
              label={intl.formatMessage({
                id: 'EditListingFeaturesForm.EditListingFeaturesForm.numberOfPersons',
              })}
              // validate={zeroNumberValidate('Nº of persons is required')}
            /> */}
            <FieldInputCounter
              className={css.counter}
              id="beds"
              name="beds"
              type="number"
              min={0}
              label={<FormattedMessage id="EditListingFeaturesForm.beds" />}
              validate={zeroNumberValidate(
                <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.bedRequired" />
              )}
            />
            <FieldInputCounter
              className={css.counter}
              id="bedrooms"
              name="bedrooms"
              type="number"
              min={0}
              label={<FormattedMessage id="EditListingFeaturesForm.bedrooms" />}
              validate={composeValidators(
                zeroNumberValidate(
                  <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.bedroomsRequired" />
                ),
                bedroomValidate
              )}
            />
            <FieldInputCounter
              className={css.counter}
              id="bathrooms"
              name="bathrooms"
              type="number"
              min={0}
              label={<FormattedMessage id="EditListingFeaturesForm.bathrooms" />}
              validate={zeroNumberValidate(
                <FormattedMessage id="EditListingFeaturesForm.EditListingFeaturesForm.bathroomsRequiredMessageLabel" />
              )}
            />
            <p className={css.label}>
              <FormattedMessage id="EditListingFeaturesForm.counterHeading2" />
            </p>
            {convertLabel(config.custom.bedroomOptions, intl)?.map(user => (
              <FieldRadioButton
                className={css.features}
                id={`${user.key}`}
                name="privateBathrooms"
                label={user.label}
                value={user.key}
                showAsRequired={showAsRequired}
              />
            ))}
          </div>
          <br />
          {/* {values?.type ? null : (
            <FieldSelectModern
              className={css.features}
              id="stay"
              name="stay"
              label={<FormattedMessage id="EditListingFeaturesForm.stay" />}
              options={config.custom.stayOptions}
              validate={requiredFieldArrayCheckbox(
                <FormattedMessage id="EditListingFeaturesForm.stayRequired" />
              )}
            />
          )} */}
          <FieldCheckboxGroup
            className={css.features}
            id={name}
            name={name}
            label={<FormattedMessage id="EditListingFeaturesForm.amenties" />}
            options={config.custom.amenitiesOptions}
            validate={requiredFieldArrayCheckbox(amentiesRequiredMessage)}
            twoColumns
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default injectIntl(EditListingFeaturesForm);
