import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import css from './TransportationPanel.module.css';
import { TransportationForm } from '../../forms';
import { PRIVATE_TOUR } from '../EditListingWizard/EditListingWizardTab';
import useInitialValues from '../useInitialValueHook/useInitialValueHook';
import { getTotalWithCommissionWithRound } from '../BookingBreakdown/LineItemRefundMaybe';
const TransportationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    sectionCategoryLabel,
    isLanguageEnglish,
  } = props;
  const normalizeValue = value => {
    return value?.map((item, index) => {
      return {
        id: index + 1,
        ...item,
        price: item?.price?.amount,
        priceWithCommission: getTotalWithCommissionWithRound(item?.price?.amount),
      };
    });
  };
  const normalizeInitialValues = initialValues => {
    return initialValues?.map((item, index) => {
      return {
        ...item,
        price: item.price ? new Money(item.price, config.currency) : null,
      };
    });
  };
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const category = publicData?.category ?? params?.category;
  const { people } = publicData || {};
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="TransportationPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="TransportationPanel.createListingTitle" />
  );
  const isPrivateTour = category === PRIVATE_TOUR;
  const titleInitialValue = title === 'Draft' ? undefined : title;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const oldInitialValues = {
    included: publicData?.included,
    notIncluded: publicData?.notIncluded,
    extra: normalizeInitialValues(publicData?.extra),
  };
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <TransportationForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { included = [], notIncluded = [], extra = [] } = values;

          const updateValues = {
            publicData: {
              included,
              notIncluded,
              extra: normalizeValue(extra),
            },
          };
          updateInitialValues({
            ...values,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        isPrivateTour={isPrivateTour}
        isLanguageEnglish={isLanguageEnglish}
        people={people}
      />
    </div>
  );
};

TransportationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

TransportationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default TransportationPanel;
