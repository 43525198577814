import React, { useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { BiSearch } from 'react-icons/bi';
import { Form, PrimaryButton, DatePicker, FieldTextInput } from '../../components';
import LocationSearch from './LocationSearch';
import css from './SearchBar.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { maxNumber } from '../../util/validators';
function SearchForm({ onSubmit }) {
  return (
    <FinalForm
      initialValues={{ type: 'location' }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { handleSubmit, values, pristine } = formRenderProps;
        const [dateError, setDateError] = useState(false);
        useEffect(() => {
          setDateError(false);
          const startDateString = moment(new Date(values?.startDate)).unix();
          const endDateString = moment(new Date(values?.endDate)).unix();
          const difference = endDateString - startDateString;
          if (difference <= 0 && values?.startDate && values?.endDate) {
            setDateError(true);
          }
        }, [values?.startDate, values?.endDate]);
        const intl = useIntl();
        const searchDisabled =
          pristine || !values.location || !values.startDate || !values.endDate || dateError;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.locationContainer}>
              <div className={css.searchField}>
                <p className={css.labelOne}>
                  {intl.formatMessage({ id: 'SearchBar.SearchBar.locationLabel' })}
                </p>
                <LocationSearch id="location" name="location" className={css.dateField} />
              </div>
              <div className={css.fieldContainer}>
                <p className={css.label}>
                  {intl.formatMessage({ id: 'SearchBar.SearchBar.arrivalLabel' })}
                </p>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  rootClassName={css.startDateRoot}
                  className={css.dateField}
                  placeholder={intl.formatMessage({ id: 'SearchBar.SearchBar.addDates' })}
                  minDate={new Date()}
                  //   formatStyle="medium"
                />
              </div>
              <div className={css.fieldContainer}>
                <p className={css.label}>
                  {intl.formatMessage({ id: 'SearchBar.SearchBar.departureLabel' })}
                </p>
                <DatePicker
                  id="endDate"
                  name="endDate"
                  rootClassName={css.startDateRoot}
                  className={css.dateField}
                  placeholder={intl.formatMessage({ id: 'SearchBar.SearchBar.addDates' })}
                  minDate={values?.startDate ? values?.startDate : new Date()}
                  //   formatStyle="medium"
                />
              </div>
              <div className={css.fieldContainer}>
                <p className={css.label}>
                  {intl.formatMessage({ id: 'SearchBar.SearchBar.numberOfPeopleLabel' })}
                </p>
                <FieldTextInput
                  inputRootClass={css.input}
                  id="guests"
                  name="guests"
                  type="number"
                  className={css.dateField}
                  placeholder={intl.formatMessage({
                    id: 'SearchBar.SearchBar.addGuestsPlaceholder',
                  })}
                  isLandingPage={true}
                  // min={0}
                  onClick={e => {
                    e.target.focus();
                  }}
                  onMouseUp={e => {
                    e.target.blur();
                  }}
                  step={1}
                  validate={maxNumber(
                    <FormattedMessage
                      id="SearchBar.SearchBar.maxNumber"
                      values={{ maxNumber: 15 }}
                    />,
                    15
                  )}
                />
              </div>
              {dateError ? (
                <p className={css.errorMobile}>
                  {intl.formatMessage({
                    id: 'LandingPage.SearchForm.dateError',
                  })}
                </p>
              ) : null}
              <div>
                <PrimaryButton
                  type="submit"
                  className={css.searchButton}
                  // disabled={searchDisabled}
                >
                  {intl.formatMessage({ id: 'SearchBar.SearchBar.searchButtonLabel' })}
                </PrimaryButton>
              </div>
              {/* <button
                disabled={searchDisabled}
                className={searchDisabled ? css.searchButtonDisabled : css.searchButton}
              >
                <BiSearch className={css.searchIcon} />
              </button>
              {dateError ? (
                <p className={css.errorMobile}>
                  {intl.formatMessage({
                    id: 'LandingPage.SearchForm.dateError',
                  })}
                </p>
              ) : null} */}

              {/* <PrimaryButton
                type="submit"
                className={css.searchButtonMobile}
                disabled={searchDisabled}
              >
                Search
              </PrimaryButton> */}
            </div>
            {dateError ? (
              <p className={css.error}>
                {intl.formatMessage({
                  id: 'LandingPage.SearchForm.dateError',
                })}
              </p>
            ) : null}
          </Form>
        );
      }}
    />
  );
}

export default SearchForm;
