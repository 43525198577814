import React, { Component } from 'react';
import { string, func, oneOfType } from 'prop-types';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { AvatarLarge, NamedLink, InlineTextButton } from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import css from './UserCard.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, bio, sectionTeachingStyle } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? (
          <>
            {bio}
            {sectionTeachingStyle}
          </>
        ) : (
          <>
            {truncatedBio}
            {bio == truncatedBio ? sectionTeachingStyle : null}
          </>
        )}
        {bio !== truncatedBio && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const { rootClassName, className, user, currentUser, onContactUser, listing, language } = props;
  const { publicData } = user?.attributes?.profile ?? {};
  // const { publicData } = listing?.attributes ?? {};
  const {
    languages_taught,
    languages_spoken,
    yearsOfExperience,
    customOption,
    customOptionList,
    otherCustomOption,
    lessons,
    qualification,
    otherQualification = [],
    qualifiedTeacher,
    activities,
  } = publicData ?? {};
  const isLanguageEnglish = language === 'english';
  const lang = language == 'english' ? 'en' : 'es';
  const intl = useIntl();
  const isOtherCustomOptionSelected = customOptionList?.find(item => item === 'other');
  const activitiesOptions = activities?.length
    ? activities?.map(item => ({ name: item?.name, es_name: item?.es_name }))
    : [];
  const sectionActivities = activities?.length ? (
    <div className={css.sectionTeach}>
      <FormattedMessage
        id="UserCard.UserCard.activities"
        values={{
          activities: (
            <ul className={css.activitiesOption}>
              {activitiesOptions?.map((item, index) => (
                <li key={index} className={css.sectionTeach}>
                  - {isLanguageEnglish ? item?.name : item?.es_name || item?.name}
                </li>
              ))}
            </ul>
          ),
        }}
      />
    </div>
  ) : null;
  const sectionLessons = lessons ? (
    <div className={css.sectionTeach}>
      <span>
        {/* <FormattedMessage
          id="UserCard.UserCard.lessons"
          values={{
            n: (
              <span>
                n<sup>o</sup>
              </span>
            ),
            lessons: lessons,
          }}
        /> */}
        <FormattedMessage
          id="UserCard.UserCard.yearsofexperience"
          values={{ yearsOfExperience: yearsOfExperience }}
        />
      </span>
    </div>
  ) : null;
  const customOptions = customOptionList
    ?.filter(k => k !== 'other')
    ?.map(i => convertLabel(config.custom.customProgramOptions, intl).find(item => item.key === i));
  const finalCustomOptions = isOtherCustomOptionSelected
    ? [...customOptions, ...otherCustomOption?.map(s => ({ label: s, key: s, value: s }))]
    : customOptions;
  const sectionCustom =
    customOption == 'yes' ? (
      <div className={css.sectionTeach}>
        <span>
          <FormattedMessage
            id="UserCard.UserCard.customProgram"
            values={{
              customOption: finalCustomOptions?.map((item, index) => (
                <span className={css.li}>
                  {item?.label}
                  {index < finalCustomOptions?.length - 1 ? ', ' : ''}
                </span>
              )),
            }}
          />
        </span>
      </div>
    ) : null;

  const otherDigreeOptions = otherQualification
    ? otherQualification?.map(item => ({
        label: item,
        key: item,
        value: item,
        other: true,
      }))
    : [];
  const sectionDegrees =
    qualifiedTeacher == 'yes' && qualification?.length ? (
      <div className={css.sectionTeach}>
        <FormattedMessage
          id="UserCard.UserCard.degreeLabel"
          values={{
            yearsOfExperience: [
              ...qualification?.filter(k => k !== 'other'),
              ...otherDigreeOptions,
            ]?.map((item, index) => (
              <span className={css.li}>
                {item?.other ? (
                  item?.label
                ) : (
                  <FormattedMessage
                    id={
                      convertLabel(config.custom.qualificationOptions, intl)?.find(
                        option => option.key === item
                      )?.abrivated
                    }
                  />
                )}
                {index <
                [...qualification?.filter(k => k !== 'other'), ...otherDigreeOptions]?.length - 1
                  ? ' + '
                  : ''}
              </span>
            )),
          }}
        />
      </div>
    ) : null;
  const sectionLanguages = languages_spoken?.length ? (
    <div className={css.sectionTeach}>
      <FormattedMessage id="UserCard.UserCard.speaksLabel" />
      :&nbsp;
      {languages_spoken.map((language, index) => {
        return (
          <span>
            <span key={index}>
              {
                convertLabel(config.custom.languageOptions, intl)?.find(
                  option => option.key === language?.language
                )?.label
              }
            </span>
            <span>
              &nbsp;(
              {
                convertLabel(config.custom?.levelOptions, intl)?.find(
                  option => option.key === language?.level
                )?.label
              }
              )
            </span>
            {index < languages_spoken?.length - 1 ? ', ' : ''}
          </span>
        );
      })}
    </div>
  ) : null;
  const sectionTeach = languages_taught?.length ? (
    <div className={css.sectionTeach}>
      {intl.formatMessage({ id: 'UserCard.UserCard.teachLabel' })}:&nbsp;
      {languages_taught.map((language, index) => {
        return (
          <span key={index}>
            {
              convertLabel(config.custom.languageOptions, intl)?.find(
                option => option.key === language?.language
              )?.label
            }
            &nbsp;(
            {language?.level?.map((level, index) => {
              return (
                <span key={index}>
                  {level?.label}
                  {index < language?.level?.length - 1 ? ', ' : ''}
                </span>
              );
            })}
            ){index < languages_taught?.length - 1 ? ', ' : ''}
          </span>
        );
      })}
    </div>
  ) : null;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, publicData: authorPublicData } = ensuredUser.attributes.profile;
  let { bio = '' } = ensuredUser.attributes.profile;
  let { es_bio, teachingStyle, es_teachingStyle } = authorPublicData || {};
  // const teachingStyle = ensuredUser.attributes.profile?.publicData?.teachingStyle;
  const sectionTeachingStyle =
    teachingStyle || es_teachingStyle ? (
      <div>
        <p className={css.teachingStyleHeading}>
          <FormattedMessage id="UserCard.UserCard.teachingStyle" />
        </p>
        <p className={css.teachingStyleText}>
          {isLanguageEnglish ? teachingStyle : es_teachingStyle || teachingStyle}
        </p>
      </div>
    ) : null;
  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const separator = isCurrentUser ? null : <span className={css.linkSeparator}>•</span>;

  const contact = (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  );

  const editProfileMobile = (
    <span className={css.editProfileMobile}>
      <span className={css.linkSeparator}>•</span>
      <NamedLink name="ProfileSettingsPage">
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
    </span>
  );

  const editProfileDesktop = isCurrentUser ? (
    <NamedLink className={css.editProfileDesktop} name="ProfileSettingsPage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  const links = ensuredUser.id ? (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid, lang }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {separator}
      {isCurrentUser ? editProfileMobile : contact}
    </p>
  ) : null;

  return (
    <div className={classes}>
      <AvatarLarge className={css.avatarMobile} user={user} />
      <div className={css.content}>
        {/* <AvatarLarge className={css.avatar} user={user} /> */}
        <div className={css.info}>
          <div className={css.headingRow}>
            <div>
              <h3 className={css.heading}>
                <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
              </h3>
              <div className={css.topHeading}>
                {sectionTeach}
                {sectionLanguages}
                {sectionDegrees}
                {/* {sectionYearsOfExperience} */}
                {/* {sectionCustom} */}
                {sectionLessons}
                {/* {sectionActivities} */}
                {/* {sectionTeachingStyle} */}
              </div>
            </div>
            {editProfileDesktop}
          </div>

          {hasBio ? (
            <ExpandableBio
              className={css.desktopBio}
              bio={isLanguageEnglish ? bio : es_bio || bio}
              sectionTeachingStyle={sectionTeachingStyle}
            />
          ) : null}
          {links}
        </div>
      </div>
      {hasBio ? <ExpandableBio className={css.mobileBio} bio={bio} /> : null}
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
};

export default UserCard;
