import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  // {
  //   id: 'barcelona',
  //   predictionPlace: {
  //     address: 'Barcelona',
  //     bounds: new LatLngBounds(new LatLng(42.323302, 2.8125), new LatLng(41.1206863, 1.360412)),
  //   },
  // },
  // {
  //   id: 'default-madrid',
  //   predictionPlace: {
  //     address: 'Madrid',
  //     bounds: new LatLngBounds(new LatLng(41.165845, -3.052983), new LatLng(39.884719, -4.579076)),
  //   },
  // },
  // {
  //   id: 'default-malaga',
  //   predictionPlace: {
  //     address: 'Málaga',
  //     bounds: new LatLngBounds(new LatLng(37.2822, -3.765967), new LatLng(36.238057, -5.611777)),
  //   },
  // },
  // // Valencia%2C%20Spain&bounds=40.211683%2C0.0703898%2C38.686554%2C-1.528916
  // {
  //   id: 'default-valencia',
  //   predictionPlace: {
  //     address: 'Valencia',
  //     bounds: new LatLngBounds(new LatLng(40.211683, 0.0703898), new LatLng(38.686554, -1.528916)),
  //   },
  // },
  // // Sevilla%20la%20Nueva%2C%20Madrid%2C%20Spain&bounds=40.381088%2C-3.998095%2C40.332598%2C-4.076669
  // {
  //   id: 'default-sevilla',
  //   predictionPlace: {
  //     address: 'Sevilla',
  //     bounds: new LatLngBounds(new LatLng(40.381088, -3.998095), new LatLng(40.332598, -4.076669)),
  //   },
  // },
];
export default defaultLocations;
