import React from 'react';
import css from './DescriptionPrivateTourForm.module.css';
import config from '../../config';
import { FieldCheckboxGroup, FieldTextInput, TranslateText } from '../../components';
import { composeValidators, required, requiredFieldArrayCheckbox } from '../../util/validators';
import { convertLabel } from '../../util/reactIntl';
const PrivateTour = ({
  isLanguageEnglish,
  values,
  intl,
  maxLength60Message,
  form,
  descriptionMaxLength,
  TITLE_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  SUBTITLE_MAX_LENGTH,
  maxLengthSubtitleMessage,
  programMaxLength,
  PROGRAM,
}) => {
  return (
    <div>
      {isLanguageEnglish ? (
        <div>
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="textarea"
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.title',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titleRequired',
                })
              ),
              maxLength60Message
            )}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_title}
                fromKey="es_title"
                toKey="title"
                target="en"
                id="title"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_title}
            fromKey="es_title"
            toKey="title"
            target="en"
            id="title"
          /> */}

          <FieldTextInput
            id="es_title"
            name="es_title"
            className={css.title}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishTitle',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titleRequired',
                })
              ),
              maxLength60Message
            )}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.title}
                fromKey="title"
                toKey="es_title"
                target="es"
                id="es_title"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.title}
            fromKey="title"
            toKey="es_title"
            target="es"
            id="es_title"
          /> */}
          <FieldTextInput
            id="subtitle"
            name="subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleRequired',
                })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_subtitle}
                fromKey="es_subtitle"
                toKey="subtitle"
                target="en"
                id="subtitle"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_subtitle}
            fromKey="es_subtitle"
            toKey="subtitle"
            target="en"
            id="subtitle"
          /> */}
          <FieldTextInput
            id="es_subtitle"
            name="es_subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishLabel',
            })}
            placeholder={intl.formatMessage({
              id:
                'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleRequired',
                })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.subtitle}
                fromKey="subtitle"
                toKey="es_subtitle"
                target="es"
                id="es_subtitle"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.subtitle}
            fromKey="subtitle"
            toKey="es_subtitle"
            target="es"
            id="es_subtitle"
          /> */}

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_description}
                fromKey="es_description"
                toKey="description"
                target="en"
                id="description"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_description}
            fromKey="es_description"
            toKey="description"
            target="en"
            id="description"
          /> */}
          <FieldTextInput
            id="es_description"
            name="es_description"
            className={css.description}
            type="textarea"
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id:
                'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.description}
                fromKey="description"
                toKey="es_description"
                target="es"
                id="es_description"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.description}
            fromKey="description"
            toKey="es_description"
            target="es"
            id="es_description"
          /> */}
          <FieldTextInput
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programLabel',
            })}
            id="program"
            name="program"
            className={css.description}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programRequired',
                })
              ),
              programMaxLength
            )}
            showCounter={true}
            maxCharacters={PROGRAM}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_program}
                fromKey="es_program"
                toKey="program"
                target="en"
                id="program"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_program}
            fromKey="es_program"
            toKey="program"
            target="en"
            id="program"
          /> */}
          <FieldTextInput
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programSpanishLabel',
            })}
            id="es_program"
            name="es_program"
            className={css.description}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programSpanishPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programRequired',
                })
              ),
              programMaxLength
            )}
            showCounter={true}
            maxCharacters={PROGRAM}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.program}
                fromKey="program"
                toKey="es_program"
                target="es"
                id="es_program"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.program}
            fromKey="program"
            toKey="es_program"
            target="es"
            id="es_program"
          /> */}
        </div>
      ) : (
        <div>
          <FieldTextInput
            id="es_title"
            name="es_title"
            className={css.title}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishTitle',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titleRequired',
                })
              ),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.title}
                fromKey="title"
                toKey="es_title"
                target="es"
                id="es_title"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.title}
            fromKey="title"
            toKey="es_title"
            target="es"
            id="es_title"
          /> */}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishPlaceholder',
            })}
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.title',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.titleRequired',
                })
              ),
              maxLength60Message
            )}
            showCounter={true}
            maxCharacters={TITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_title}
                fromKey="es_title"
                toKey="title"
                target="en"
                id="title"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_title}
            fromKey="es_title"
            toKey="title"
            target="en"
            id="title"
          /> */}

          <FieldTextInput
            id="es_subtitle"
            name="es_subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitlePlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleRequired',
                })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.subtitle}
                fromKey="subtitle"
                toKey="es_subtitle"
                target="es"
                id="es_subtitle"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.subtitle}
            fromKey="subtitle"
            toKey="es_subtitle"
            target="es"
            id="es_subtitle"
          /> */}
          <FieldTextInput
            id="subtitle"
            name="subtitle"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleLabel',
            })}
            placeholder={intl.formatMessage({
              id:
                'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleSpanishPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.subTitleRequired',
                })
              ),
              maxLengthSubtitleMessage
            )}
            showCounter={true}
            maxCharacters={SUBTITLE_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_subtitle}
                fromKey="es_subtitle"
                toKey="subtitle"
                target="en"
                id="subtitle"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_subtitle}
            fromKey="es_subtitle"
            toKey="subtitle"
            target="en"
            id="subtitle"
          /> */}

          <FieldTextInput
            id="es_description"
            name="es_description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.description}
                fromKey="description"
                toKey="es_description"
                target="es"
                id="es_description"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.description}
            fromKey="description"
            toKey="es_description"
            target="es"
            id="es_description"
          /> */}
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionLabel',
            })}
            placeholder={intl.formatMessage({
              id:
                'DescriptionPrivateTourForm.DescriptionPrivateTourForm.spanishDescriptionPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.descriptionRequired',
                })
              ),
              descriptionMaxLength
            )}
            showCounter={true}
            maxCharacters={DESCRIPTION_MAX_LENGTH}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_description}
                fromKey="es_description"
                toKey="description"
                target="en"
                id="description"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_description}
            fromKey="es_description"
            toKey="description"
            target="en"
            id="description"
          /> */}

          <FieldTextInput
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programSpanishLabel',
            })}
            id="es_program"
            name="es_program"
            className={css.description}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programRequired',
                })
              ),
              programMaxLength
            )}
            showCounter={true}
            maxCharacters={PROGRAM}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.program}
                fromKey="program"
                toKey="es_program"
                target="es"
                id="es_program"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.program}
            fromKey="program"
            toKey="es_program"
            target="es"
            id="es_program"
          /> */}
          <FieldTextInput
            label={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programLabel',
            })}
            id="program"
            name="program"
            className={css.description}
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programSpanishPlaceholder',
            })}
            validate={composeValidators(
              required(
                intl.formatMessage({
                  id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.programRequired',
                })
              ),
              programMaxLength
            )}
            showCounter={true}
            maxCharacters={PROGRAM}
            transalateComponent={
              <TranslateText
                form={form}
                value={values?.es_program}
                fromKey="es_program"
                toKey="program"
                target="en"
                id="program"
              />
            }
          />
          {/* <TranslateText
            form={form}
            value={values?.es_program}
            fromKey="es_program"
            toKey="program"
            target="en"
            id="program"
          /> */}
        </div>
      )}
      <FieldCheckboxGroup
        id="immersion_program"
        name="immersion_program"
        label={intl.formatMessage({
          id: 'DescriptionPrivateTourForm.DescriptionPrivateTourForm.immersionProgramLabel',
        })}
        options={convertLabel(config.custom.immersionProgramOptions, intl)}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({
            id: 'DescriptionPrivateTourForm.PrivateTour.immersionProgramRequired',
          })
        )}
      />
    </div>
  );
};

export default PrivateTour;
