import { fetchInvitedUsersViaApi, fetchTransactionsViaApi } from '../../util/api';

export const FETCH_INVITED_FRIENDS_REQUEST = 'app/InviteFriendPage/FETCH_INVITED_FRIENDS_REQUEST';
export const FETCH_INVITED_FRIENDS_SUCCESS = 'app/InviteFriendPage/FETCH_INVITED_FRIENDS_SUCCESS';
export const FETCH_INVITED_FRIENDS_ERROR = 'app/InviteFriendPage/FETCH_INVITED_FRIENDS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/InviteFriendPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InviteFriendPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InviteFriendPage/FETCH_TRANSACTIONS_ERROR';

const initialState = {
  invitedFriends: [],
  fetchInvitedFriendsError: null,
  fetchInprogress: false,
  fetchTransactionsError: null,
  totalUserPages: 1,
  currentUserPage: 1,
  fetchTransactionsInprogress: false,
  transactions: [],
  completedAmount: 0,
  totaltxPages: 1,
  totalEntries: 0,
  currenttxPage: 1,
  total: 0,
};

function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INVITED_FRIENDS_REQUEST:
      return {
        ...state,
        fetchInprogress: true,
        invitedFriends: [],
        fetchInvitedFriendsError: null,
        total: 0,
      };
    case FETCH_INVITED_FRIENDS_SUCCESS:
      return {
        ...state,
        fetchInprogress: false,
        invitedFriends: payload?.users,
        totalUserPages: payload?.totalPages,
        currentUserPage: payload?.page,
        total: payload?.total,
      };
    case FETCH_INVITED_FRIENDS_ERROR:
      return {
        ...state,
        fetchInprogress: false,
        invitedFriends: [],
        fetchInvitedFriendsError: payload,
        total: 0,
      };

    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchTransactionsInprogress: true,
        fetchTransactionsError: null,
        completedAmount: 0,
        transactions: [],
        totalEntries: 0,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        fetchTransactionsInprogress: false,
        transactions: payload.transactions,
        completedAmount: payload.amount,
        totaltxPages: payload.totalPages,
        currenttxPage: payload.page,
        totalEntries: payload.totalEntries,
        pendingAmount: payload?.pendingAmount,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        fetchTransactionsInprogress: false,
        fetchTransactionsError: payload,
        completedAmount: 0,
        transactions: [],
        totalEntries: 0,
      };
    default:
      return state;
  }
}
export default reducer;
export const fetchInvitedUsers = (page = 1) => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_INVITED_FRIENDS_REQUEST });

  return fetchInvitedUsersViaApi({ page })
    .then(response => {
      const users = response?.users;
      console.log('response', response);
      const data = {
        users,
        totalPages: response?.totalPages,
        page: response?.currentPage,
        total: response?.total,
      };
      dispatch({ type: FETCH_INVITED_FRIENDS_SUCCESS, payload: data });
      return response;
    })
    .catch(e => {
      dispatch({ type: FETCH_INVITED_FRIENDS_ERROR, payload: e });
    });
};
export const fetchUserTransactions = (page = 1) => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_TRANSACTIONS_REQUEST });

  return fetchTransactionsViaApi({ page })
    .then(response => {
      const data = {
        transactions: response.transactions,
        amount: response.totalAmountByInvitedBy,
        totalPages: response?.totalPages,
        page: response?.currentPage,
        totalEntries: response?.totalEntries,
        pendingAmount: response?.pendingAmount,
      };
      dispatch({ type: FETCH_TRANSACTIONS_SUCCESS, payload: data });
      return response;
    })
    .catch(e => {
      dispatch({ type: FETCH_TRANSACTIONS_ERROR, payload: e });
    });
};
export const loadData = search => dispatch => {
  return Promise.all([dispatch(fetchInvitedUsers()), dispatch(fetchUserTransactions())])
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log('e', e);
    });
};
