import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldInputCounter,
  SecondaryButton,
  TranslateText,
  FieldCheckboxGroup,
} from '../../components';
import arrayMutators from 'final-form-arrays';
import css from './DescriptionPrivateTourForm.module.css';
import config from '../../config';
import PrivateTour from './PrivateTour';
import Activity from './Activity';
import { PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';

const TITLE_MAX_LENGTH = 90;
const DESCRIPTION_MAX_LENGTH = 1500;
const DESCRIPTION_MAX_LENGTH_P = 1200;
const SUBTITLE_MAX_LENGTH = 160;
const PROGRAM = 2000;

const DescriptionPrivateTourFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        isLanguageEnglish,
        category,
      } = formRenderProps;

      // console.log('values', values);  /
      const titleMessage = intl.formatMessage({ id: 'DescriptionPrivateTourForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'DescriptionPrivateTourForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'DescriptionPrivateTourForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLengthDescriptionMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );
      const maxLengthDescriptionpMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH_P,
        }
      );
      const maxLengthSubtitleMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: SUBTITLE_MAX_LENGTH,
        }
      );
      const maxLengthProgramMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: PROGRAM,
        }
      );
      const descriptionMessage = intl.formatMessage({
        id: 'DescriptionPrivateTourForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'DescriptionPrivateTourForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionMaxLength = maxLength(maxLengthDescriptionMessage, DESCRIPTION_MAX_LENGTH);
      const descriptionPMaxLength = maxLength(maxLengthDescriptionpMessage, DESCRIPTION_MAX_LENGTH);
      const subtitleMaxLength = maxLength(maxLengthSubtitleMessage, SUBTITLE_MAX_LENGTH);
      const programMaxLength = maxLength(maxLengthProgramMessage, PROGRAM);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'DescriptionPrivateTourForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="DescriptionPrivateTourForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="DescriptionPrivateTourForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="DescriptionPrivateTourForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          {category === PRIVATE_TOUR ? (
            <PrivateTour
              isLanguageEnglish={isLanguageEnglish}
              values={values}
              intl={intl}
              maxLength60Message={maxLength60Message}
              TITLE_MAX_LENGTH={TITLE_MAX_LENGTH}
              DESCRIPTION_MAX_LENGTH={DESCRIPTION_MAX_LENGTH_P}
              form={form}
              descriptionMaxLength={descriptionPMaxLength}
              maxLengthSubtitleMessage={subtitleMaxLength}
              SUBTITLE_MAX_LENGTH={SUBTITLE_MAX_LENGTH}
              programMaxLength={programMaxLength}
              PROGRAM={PROGRAM}
            />
          ) : (
            <Activity
              isLanguageEnglish={isLanguageEnglish}
              values={values}
              intl={intl}
              maxLength60Message={maxLength60Message}
              TITLE_MAX_LENGTH={TITLE_MAX_LENGTH}
              DESCRIPTION_MAX_LENGTH={DESCRIPTION_MAX_LENGTH}
              SUBTITLE_MAX_LENGTH={SUBTITLE_MAX_LENGTH}
              maxLengthSubtitleMessage={subtitleMaxLength}
              form={form}
              descriptionMaxLength={descriptionMaxLength}
            />
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

DescriptionPrivateTourFormComponent.defaultProps = { className: null, fetchErrors: null };

DescriptionPrivateTourFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(DescriptionPrivateTourFormComponent);
