import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';

import css from './DurationPanel.module.css';
import { DurationForm } from '../../forms';
import { PRIVATE_TOUR } from '../EditListingWizard/EditListingWizardTab';

const DurationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    sectionCategoryLabel,
    isLanguageEnglish,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const category = publicData?.category ?? params?.category;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const priceType = publicData?.priceType;
  const pricePerPerson = publicData?.pricePerPerson || [];
  const panelTitle = isPublished ? (
    <FormattedMessage
      id={category === PRIVATE_TOUR ? 'DurationPanel.title' : 'DurationPanel.activeListingTitle'}
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage
      id={
        category === PRIVATE_TOUR
          ? 'DurationPanel.createListingTitle'
          : 'DurationPanel.createListingActivityTitle'
      }
    />
  );
  const titleInitialValue = title === 'Draft' ? undefined : title;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <DurationForm
        className={css.form}
        initialValues={{
          duration: publicData?.duration ? publicData?.duration : category === PRIVATE_TOUR ? 2 : 1,
          people: publicData?.people ? publicData?.people : 1,
          transportation: publicData?.transportation,
          es_transportation: publicData?.es_transportation,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { duration, people, transportation, es_transportation } = values;
          const isAlreadyPerpersonPrice = pricePerPerson?.length > 0;
          const newPriceValues = isAlreadyPerpersonPrice
            ? pricePerPerson?.filter(item => item.person <= +people)
            : null;
          if (isAlreadyPerpersonPrice && isAlreadyPerpersonPrice < +people) {
            const newPersons = +people - newPriceValues?.length;
            for (let i = 0; i < newPersons; i++) {
              newPriceValues?.push({ person: newPriceValues?.length + 1, price: 0 });
            }
          }
          console.log('newPriceValues', newPriceValues);

          const updateValues = {
            publicData: {
              duration,
              people,
              transportation,
              es_transportation,
              pricePerPerson: newPriceValues,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        isLanguageEnglish={isLanguageEnglish}
        category={category}
      />
    </div>
  );
};

DurationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

DurationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default DurationPanel;
