import React from 'react';
import css from './PrivacyPolicy.module.css';
export default function PrivacySp() {
  return (
    <>
      <h1>Política de Privacidad</h1>
      <p className={css.lastUpdated}>Fecha de vigencia: abril de 2023</p>

      <p>
        Esta Política de Privacidad describe Nuestras políticas y procedimientos sobre la
        recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le
        informa sobre sus derechos de Privacidad y cómo la ley lo protege.
      </p>
      <p>
        Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio,
        acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad.{' '}
      </p>
      <p>Lea detenidamente esta Política de Privacidad antes de utilizar nuestro Servicio.</p>
      <h2>1 - Interpretación y Definiciones</h2>
      <h3>1.1 - Interpretación</h3>
      <p>
        Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las
        siguientes condiciones. Las siguientes definiciones tendrán el mismo significado
        independientemente de que aparezcan en singular o en plural.
      </p>
      <h3>1.2 - Definiciones</h3>
      <p>A los efectos de esta Política de Privacidad:</p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Afiliado</b> significa una entidad que controla, es controlada o está bajo el control
          común de una parte, donde "control" significa propiedad del 50% o más de las acciones,
          participación accionaria u otros valores con derecho a voto para la elección de
          directores. u otra autoridad de gestión.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Aplicación</b> significa el programa de software proporcionado por la Empresa o el
          Operador descargado por Usted en cualquier dispositivo electrónico, denominado LinGo
          Immersion.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Consumidor</b> se refiere a Usted.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Controlador de Datos,</b> a los efectos del RGPD (Reglamento General de Protección de
          Datos), se refiere a la Empresa u Operador como la persona jurídica que, sola o junto con
          otros, determina los fines y medios del procesamiento de Datos Personales.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Cookies</b> son pequeños archivos que un sitio web coloca en su computadora,
          dispositivo móvil o cualquier otro dispositivo, y contienen los detalles de su historial
          de navegación en ese sitio web entre sus muchos usos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Cuenta</b>, significa una cuenta única creada para que Usted acceda a nuestro Servicio
          o partes de nuestro Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Datos personales </b> es cualquier información relacionada con una persona física
          identificada o identificable. <br />A los efectos del RGPD, los datos personales se
          refieren a cualquier información relacionada con Usted, como un nombre, un número de
          identificación, datos de ubicación, un identificador en línea o uno o más factores
          específicos del estado físico, fisiológico, genético, mental, económico, cultural o
          social. identidad. A los efectos de la CCPA, los datos personales significan cualquier
          información que identifique, se relacione, describa o pueda asociarse con, o podría
          vincularse razonablemente, directa o indirectamente, con Usted.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Datos de Uso</b> se refiere a los datos recopilados automáticamente, ya sea generados
          por el uso del Servicio o de la propia infraestructura del Servicio (por ejemplo, la
          duración de una visita a la página).
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Dispositivo</b>, significa cualquier dispositivo que pueda acceder al Servicio, como
          una computadora, un teléfono celular o una tableta digital.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Do Not Track</b> (DNT) es un concepto que ha sido promovido por las autoridades
          reguladoras de EE. UU., en particular, la Comisión Federal de Comercio (FTC) de EE. UU.,
          para que la industria de Internet desarrolle e implemente un mecanismo que permita a los
          usuarios de Internet controlar el seguimiento de sus actividades en línea a través de
          sitios web.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Empresa</b> (referida como "la Empresa" o "Nosotros"en este Acuerdo) se refiere a LinGo
          Immersion. A los efectos del RGPD, la Empresa o el Operador es el Controlador de datos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Negocio</b> se refiere a la Empresa u Operador como la entidad legal que recopila la
          información personal de los Consumidores y determina los propósitos y medios del
          procesamiento de la información personal de los Consumidores, o en nombre de la cual se
          recopila dicha información y eso solo o junto con otros. , determina los fines y medios
          del tratamiento de la información personal de los consumidores.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b> Operador </b>(referido como "el Operador" o "Nosotros" en este Acuerdo) se refiere a
          LinGo Immersion. A los efectos del RGPD, la Empresa o el Operador es el Controlador de
          datos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>País</b>, se refiere a España.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Proveedor de Servicios</b> significa cualquier persona física o jurídica que procesa
          los datos en nombre de la Empresa o el Operador. Se refiere a empresas de terceros o
          personas empleadas por la Empresa o el Operador para facilitar el Servicio, para
          proporcionar el Servicio en nombre de la Empresa o el Operador, para realizar servicios
          relacionados con el Servicio o para ayudar a la Empresa o el Operador a analizar cómo el
          Se utiliza el servicio. A los efectos del RGPD, los Proveedores de servicios se consideran
          Procesadores de datos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Servicio</b> se refiere a la Aplicación o al Sitio Web o a ambos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Servicio de Redes Sociales de Terceros </b> se refiere a cualquier sitio web o
          cualquier sitio web de red social a través del cual un Usuario puede iniciar sesión o
          crear una cuenta para usar el Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Sitio web</b> e refiere a lingoimmersion.com, accesible desde la url
          https://www.lingoimmersion.com.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Usted</b> se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra
          entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según
          corresponda. <br /> Según el RGPD (Reglamento General de Protección de Fatos), se le puede
          denominar sujeto de los datos o usuario, ya que es la persona que utiliza el servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Venta</b> significa vender, alquilar, liberar, divulgar, difundir, poner a disposición,
          transferir o comunicar de otra manera oralmente, por escrito, por medios electrónicos o de
          otro tipo, la información personal de un Consumidor a otra empresa o a un tercero a cambio
          de dinero o de otro valor. consideración.
        </li>
      </ul>
      <h2>2 - Contacto</h2>
      <p>Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Por correo electrónico:{' '}
        <a href="mailto:contact@lingoimmersion.com">contact@lingoimmersion.com</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Visitando esta página en nuestro sitio web: :{' '}
        <a href="https://www.lingoimmersion.com/contact">https://www.lingoimmersion.com/contact</a>.
      </li>
      <h2>3 – Recopilación y Uso de sus Datos Personales</h2>
      <h3>3.1 - Tipos de Datos Recopilados</h3>
      <p>3.1.1 – Datos Personales</p>
      <p>
        Mientras usa nuestro Servicio, podemos pedirle que nos proporcione cierta información de
        identificación personal que se puede usar para contactarlo o identificarlo. La información
        de identificación personal puede incluir, entre otros:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Dirección de correo electrónico</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Nombre y apellido</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Número de teléfono</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Dirección, Provincia, Código postal, Ciudad.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Información cuenta bancaria</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Número de tarjeta de crédito y fecha de vencimiento
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Datos de uso</li>
      <p>3.1.2 – Datos de Uso</p>
      <p>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</p>
      <p>
        Los datos de uso pueden incluir información como la dirección del protocolo de Internet de
        su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del
        navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el
        tiempo que pasa en esas páginas, dispositivo único identificadores y otros datos de
        diagnóstico.
      </p>
      <p>
        Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta
        información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que
        utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo
        móvil, su sistema operativo, el tipo de navegador de Internet móvil que utiliza,
        identificadores únicos de dispositivos y otros datos de diagnóstico.
      </p>
      <p>
        También podemos recopilar información que su navegador envía cada vez que visita nuestro
        Servicio o cuando accede al Servicio a través de un dispositivo móvil.
      </p>
      <p>3.1.3 - Información de Servicios de Redes Sociales de Terceros</p>
      <p>
        La Empresa o el Operador pueden permitirle crear una cuenta e iniciar sesión para usar el
        Servicio a través de los siguientes Servicios de redes sociales de terceros. Estos Servicios
        de redes sociales de terceros pueden incluir, entre otros:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Google</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Facebook</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Twitter</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>LinkedIn</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Apple</li>
      <p>
        Si decide registrarse o concedernos acceso a un Servicio de redes sociales de terceros,
        podemos recopilar datos personales que ya están asociados con la cuenta de su Servicio de
        redes sociales de terceros, como su nombre, su dirección de correo electrónico, sus
        actividades o su lista de contactos asociada con esa cuenta.
      </p>
      <p>
        También puede tener la opción de compartir información adicional con la Empresa o el
        Operador a través de la cuenta de su Servicio de redes sociales de terceros. Si elige
        proporcionar dicha información y Datos personales, durante el registro o de otro modo, le
        está dando permiso a la Empresa o al Operador para usarla, compartirla y almacenarla de
        manera consistente con esta Política de Privacidad.
      </p>
      <p>3.1.4 – Tecnologías de Seguimiento y Cookies</p>
      <p>
        Usamos Cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro
        Servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas son
        balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar
        nuestro Servicio. Las tecnologías que utilizamos pueden incluir:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies o Cookies del Navegador.</b> Una cookie es un pequeño archivo que se coloca en su
        dispositivo. Puede indicar a su navegador que rechace todas las cookies o que indique cuándo
        se envía una cookie. Sin embargo, si no acepta las Cookies, es posible que no pueda utilizar
        algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su
        navegador para que rechace las Cookies, nuestro Servicio puede usar Cookies.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Balizas web. </b> Ciertas secciones de nuestro Servicio y nuestros correos electrónicos
        pueden contener pequeños archivos electrónicos conocidos como web beacons (también conocidos
        como gifs transparentes, etiquetas de píxeles y gifs de un solo píxel) que permiten a la
        Empresa o al Operador, por ejemplo, contar los usuarios que han visitado esas páginas o
        abrió un correo electrónico y para otras estadísticas relacionadas con la aplicación o el
        sitio web (por ejemplo, registrar la popularidad de una determinada sección y verificar la
        integridad del sistema y del servidor).
      </li>
      <p>
        Las cookies pueden ser cookies "persistentes" o de "sesión". Las cookies persistentes
        permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que
        las cookies de sesión se eliminan tan pronto como cierra su navegador web.
      </p>
      <p>Utilizamos cookies de sesión y persistentes para los fines establecidos a continuación:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies necesarias/esenciales </b>
        <br />
        <span style={{ marginLeft: '20px' }}>Tipo: Cookies de sesión </span>
        <br />
        <span style={{ marginLeft: '20px' }}> Administrado por: Nosotros</span>
        <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Propósito: Estas Cookies son esenciales para brindarle los servicios disponibles a través
          de la Aplicación o el Sitio web y para permitirle usar algunas de sus funciones. Ayudan a
          autenticar a los usuarios y previenen el uso fraudulento de cuentas de usuario. Sin estas
          Cookies, no se pueden proporcionar los servicios que ha solicitado, y solo usamos estas
          Cookies para proporcionarle esos servicios..
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Política de Cookies/aviso de aceptación de Cookies</b>
        <br /> <span style={{ marginLeft: '20px' }}>Tipo: Cookies persistentes </span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administrado por: Nosotros</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Propósito: Estas Cookies identifican si los usuarios han aceptado el uso de cookies en a
          Aplicación o Sitio Web.
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies de funcionalidad</b>
        <br /> <span style={{ marginLeft: '20px' }}>Tipo: Cookies persistentes</span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administrado por: Nosotros</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Propósito: estas cookies nos permiten recordar las elecciones que hace cuando usa la
          aplicación o el sitio web, como recordar sus datos de inicio de sesión o preferencia de
          idioma. El propósito de estas Cookies es brindarle una experiencia más personal y evitar
          que tenga que volver a ingresar sus preferencias cada vez que use la Aplicación o el Sitio
          web.
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies de seguimiento y rendimiento</b>
        <br /> <span style={{ marginLeft: '20px' }}>Tipo: Cookies persistentes</span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administrado por: Terceros</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Propósito: estas cookies se utilizan para rastrear información sobre el tráfico a la
          aplicación o el sitio web y cómo los usuarios usan la aplicación o el sitio web. La
          información recopilada a través de estas cookies puede identificarlo directa o
          indirectamente como un visitante individual. Esto se debe a que la información recopilada
          generalmente está vinculada a un identificador seudónimo asociado con el dispositivo que
          utiliza para acceder a la Aplicación o al Sitio web. También podemos usar estas Cookies
          para probar nuevas páginas, características o nuevas funcionalidades de la Aplicación o el
          Sitio web para ver cómo reaccionan nuestros usuarios ante ellas.
        </span>
      </li>
      <h3>3.2 – Uso de sus Datos Personales</h3>
      <p>La Empresa u Operador podrá utilizar los Datos Personales para los siguientes fines:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Proporcionar y mantener nuestro Servicio,</b>, incluso para monitorear el uso de nuestro
        Servicio
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Gestionar su Cuenta:</b> para gestionar su registro como usuario del Servicio. Los Datos
        Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que
        están disponibles para Usted como usuario registrado.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Ejecución de un contrato:</b> el desarrollo, cumplimiento y realización del contrato de
        compra de los productos, artículos o servicios que haya adquirido o de cualquier otro
        contrato con Nosotros a través del Servicio.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Contactarle:</b> Para contactarlo a través de correo electrónico, llamadas telefónicas,
        SMS u otras formas de comunicación electrónica equivalentes, como notificaciones push de una
        aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las
        funcionalidades, productos o servicios contratados, incluidas las actualizaciones de
        seguridad. , cuando sea necesario o razonable para su ejecución.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Proporcionarle noticias, </b> ofertas especiales e información general sobre otros
        bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó,
        a menos que haya optado por no recibir dicha información.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Gestionar sus solicitudes: </b> Para atender y gestionar sus solicitudes hacia Nosotros.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Envío de publicidad dirigida:</b>podemos usar su información para desarrollar y mostrar
        contenido y publicidad (y trabajar con proveedores externos que lo hagan) adaptados a sus
        intereses y/o ubicación y para medir su eficacia.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Transferencias comerciales: </b> podemos usar su información para evaluar o realizar una
        fusión, desinversión, reestructuración, reorganización, disolución u otra venta o
        transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como
        parte de una quiebra, liquidación o procedimiento similar, en el que los Datos personales
        que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos
        transferidos.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Otros fines:</b> podemos usar su información para otros fines, como el análisis de datos,
        la identificación de tendencias de uso, la determinación de la eficacia de nuestras campañas
        promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y
        su experiencia.
      </li>
      <p>Podemos compartir su información personal en las siguientes situaciones:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Con proveedores de servicios:</b> podemos compartir su información personal con
        proveedores de servicios para monitorear y analizar el uso de nuestro Servicio, para
        anunciarle en sitios web de terceros después de que haya visitado nuestro Servicio, para el
        procesamiento de pagos, para contactarlo.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Para transferencias comerciales: </b> Podemos compartir o transferir su información
        personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos
        de la Empresa o del Operador, financiación o adquisición de todo o una parte de nuestro
        negocio a otra empresa.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Con Afiliados: </b>Podemos compartir su información con nuestros afiliados, en cuyo caso
        exigiremos a esos afiliados que respeten esta Política de Privacidad. Los afiliados incluyen
        nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras
        empresas que controlamos o que están bajo control común con nosotros.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Con socios comerciales:</b> Podemos compartir su información con nuestros socios
        comerciales para ofrecerle ciertos productos, servicios o promociones.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Con otros usuarios:</b> cuando comparte información personal o interactúa en las áreas
        públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y
        puede distribuirse públicamente al exterior. Si interactúa con otros usuarios o se registra
        a través de un Servicio de redes sociales de terceros, sus contactos en el Servicio de redes
        sociales de terceros pueden ver su nombre, perfil, imágenes y descripción de su actividad.
        De manera similar, otros usuarios podrán ver descripciones de su actividad, comunicarse con
        Usted y ver su perfil.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Con su consentimiento: </b> podemos divulgar su información personal para cualquier otro
        propósito con su consentimiento.
      </li>
      <h3>3.3 - Retención de sus Datos Personales</h3>
      <p>
        La Empresa o el Operador retendrá sus Datos personales solo durante el tiempo que sea
        necesario para los fines establecidos en esta Política de Privacidad. Conservaremos y
        utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras
        obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir
        con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas
        legales.
      </p>
      <p>
        La Empresa o el Operador también conservará los Datos de uso para fines de análisis interno.
        Los Datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando
        estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de nuestro
        Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo
        más largos.
      </p>
      <p>
        Cuando la ley o los derechos u obligaciones de nosotros o de Usted ya no exijan sus datos
        personales, eliminaremos los datos personales. En la mayoría de los casos, los Datos
        personales se eliminarán al finalizar o expirar el acuerdo entre Usted y la Empresa o el
        Operador o cuando lo solicite por escrito.
      </p>
      <h3>3.4 – Transferencia de sus Datos Personales</h3>
      <p>
        Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la
        Empresa o del Operador y en cualquier otro lugar donde se encuentren las partes involucradas
        en el procesamiento. Significa que esta información puede transferirse y mantenerse en
        computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental
        donde las leyes de protección de datos pueden diferir de las de su jurisdicción.
      </p>
      <p>
        Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información
        representa Su acuerdo con esa transferencia.
      </p>
      <p>
        La Empresa o el Operador tomarán todas las medidas razonablemente necesarias para garantizar
        que sus datos se traten de forma segura y de acuerdo con esta Política de Privacidad y no se
        realizará ninguna transferencia de sus datos personales a una organización o país a menos
        que existan controles adecuados establecidos, incluido el seguridad de sus datos y otra
        información personal.
      </p>
      <h3>3.5 – Eliminación de sus Datos Personales</h3>
      <p>
        Tiene derecho a eliminar o solicitar que lo ayudemos a eliminar los Datos personales que
        hemos recopilado sobre Usted.
      </p>
      <p>
        Nuestro Servicio puede darle la capacidad de eliminar cierta información sobre Usted dentro
        del Servicio.
      </p>
      <p>
        Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión
        en su cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le
        permite administrar su información personal. También puede comunicarse con nosotros para
        solicitar acceso, corregir o eliminar cualquier información personal que nos haya
        proporcionado.
      </p>
      <p>
        Sin embargo, tenga en cuenta que es posible que necesitemos conservar cierta información
        cuando tengamos una obligación legal o una base legal para hacerlo.
      </p>
      <h3>3.6 – Divulgación de sus Datos Personales</h3>
      <p>3.6.1 – Transacciones Comerciales</p>
      <p>
        Si la Empresa o el Operador están involucrados en una fusión, adquisición o venta de
        activos, sus Datos personales pueden transferirse. Le enviaremos un aviso antes de que sus
        Datos personales se transfieran y queden sujetos a una Política de Privacidad diferente.
      </p>
      <p>3.6.2 – Cumplimiento de la Ley</p>
      <p>
        En determinadas circunstancias, la Empresa o el Operador pueden estar obligados a divulgar
        Sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las
        autoridades públicas (p. ej., un tribunal o una agencia gubernamental).
      </p>
      <p>3.6.3 – Otros requisitos legales</p>
      <p>
        La Empresa o el Operador pueden divulgar sus Datos personales de buena fe cuando consideren
        que dicha acción es necesaria para lo siguiente:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Cumplir con una obligación legal</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Proteger y defender los derechos o la propiedad de la Empresa o el Operador
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Prevenir o investigar posibles irregularidades en relación con el Servicio
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Proteger la seguridad personal de los Usuarios del Servicio o del público
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Proteger contra la responsabilidad legal
      </li>
      <h3>3.7 – Seguridad de sus Datos Personales</h3>
      <p>
        La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún
        método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro.
        Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos
        personales, no podemos garantizar su seguridad absoluta.
      </p>
      <h2>4 - Información detallada sobre el procesamiento de sus datos personales</h2>
      <p>
        Los proveedores de servicios que utilizamos pueden tener acceso a sus datos personales.
        Estos proveedores externos recopilan, almacenan, usan, procesan y transfieren información
        sobre su actividad en nuestro servicio de acuerdo con sus Políticas de Privacidad.
      </p>
      <h3>4.1 - Analítica</h3>
      <p>
        Podemos utilizar proveedores de Servicios de terceros para monitorear y analizar el uso de
        nuestro Servicio. Pueden incluir, pero no se limitan a:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Google analitico</b> <br />
        Google Analytics es un servicio de análisis web ofrecido por Google que rastrea e informa el
        tráfico del sitio web. Google utiliza los datos recopilados para rastrear y monitorear el
        uso de nuestro Servicio. Estos datos se comparten con otros servicios de Google. Google
        puede utilizar los datos recopilados para contextualizar y personalizar los anuncios de su
        propia red publicitaria.
        <br />
        Puede optar por no permitir que su actividad en el Servicio esté disponible para Google
        Analytics instalando el complemento del navegador de exclusión voluntaria de Google
        Analytics. El complemento evita que el JavaScript de Google Analytics (ga.js, analytics.js y
        dc.js) comparta información con Google Analytics sobre la actividad de las visitas.
        <br /> Puede optar por no participar en ciertas funciones de Google Analytics a través de la
        configuración de su dispositivo móvil, como la configuración de publicidad de su dispositivo
        o siguiendo las instrucciones proporcionadas por Google en su Política de Privacidad:{' '}
        <a href="https://policies.google.com/privacy ">https://policies.google.com/privacy </a>
        <br />
        For more information on the privacy practices of Google, please visit the Google Privacy &
        Terms web page:{' '}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Matomo</b> <br /> Matomo es un servicio de análisis web. Puede visitar su página de
        Política de Privacidad aquí:{' '}
        <a href="https://matomo.org/privacy-policy">https://matomo.org/privacy-policy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Fathom</b> <br /> Fathom es un servicio de análisis web. Puede visitar su página de
        Política de Privacidad aquí:{' '}
        <a href="https://www.fathomhq.com/privacy">https://www.fathomhq.com/privacy</a>
      </li>
      <h3>4.2 - Email de Propaganda</h3>
      <p>
        Podemos utilizar sus datos personales para ponernos en contacto con Usted con boletines,
        materiales promocionales o de marketing y otra información que pueda ser de su interés.
        Puede optar por no recibir ninguna o todas estas comunicaciones nuestras siguiendo el enlace
        para cancelar la suscripción o las instrucciones proporcionadas en cualquier correo
        electrónico que enviemos o poniéndose en contacto con nosotros.
      </p>
      <p>
        Podemos utilizar proveedores de servicios de marketing por correo electrónico para
        administrar y enviarle correos electrónicos. Pueden incluir, pero no se limitan a:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Mailchimp</b> <br />
        Mailchimp es un servicio de envío de marketing por correo electrónico proporcionado por The
        Rocket Science Group LLC. Para obtener más información sobre las Políticas de Privacidad de
        Mailchimp, visite su Política de Privacidad:{' '}
        <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>
      </li>
      <h3>4.3 - Pagos</h3>
      <p>
        Podemos proporcionar productos y/o servicios pagos dentro del Servicio. En ese caso, podemos
        utilizar servicios de terceros para el procesamiento de pagos (por ejemplo, procesadores de
        pagos).
      </p>
      <p>
        No almacenaremos ni recopilaremos los datos de su tarjeta de pago. Esa información se
        proporciona directamente a nuestros procesadores de pago de terceros, cuyo uso de Su
        información personal se rige por su Política de Privacidad. Estos procesadores de pago se
        adhieren a los estándares establecidos por PCI-DSS administrados por el PCI Security
        Standards Council, que es un esfuerzo conjunto de marcas como Visa, Mastercard, American
        Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo seguro de la
        información de pago.
      </p>
      <p>Pueden incluir, pero no se limitan a:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Stripe</b> <br /> Su Política de Privacidad se puede ver en{' '}
        <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>PayPal</b> <br /> Su Política de Privacidad se puede ver en{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
        >
          https://www.paypal.com/us/webapps/mpp/ua/privacy-full
        </a>
      </li>
      <h3>4.4 – Remarketing Conductual</h3>
      <p>
        La Empresa o el Operador utiliza servicios de remarketing para anunciarle después de que
        haya accedido o visitado nuestro Servicio. Nosotros y nuestros proveedores externos usamos
        cookies y tecnologías que no son cookies para ayudarnos a reconocer su dispositivo y
        comprender cómo usa nuestro servicio para que podamos mejorar nuestro servicio para reflejar
        sus intereses y mostrarle anuncios que probablemente sean más interesantes a Usted.
      </p>
      <p>
        Estos proveedores externos recopilan, almacenan, usan, procesan y transfieren información
        sobre su actividad en nuestro Servicio de acuerdo con sus Políticas de Privacidad y para
        permitirnos:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Medir y analizar el tráfico y la actividad de navegación en nuestro Servicio
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Mostrarle anuncios de nuestros productos y/o servicios en sitios web o aplicaciones de
        terceros
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Medir y analizar el rendimiento de Nuestras campañas publicitarias.
      </li>
      <p>
        Algunos de estos proveedores externos pueden usar tecnologías que no son cookies que pueden
        no verse afectadas por la configuración del navegador que bloquea las cookies. Es posible
        que su navegador no le permita bloquear dichas tecnologías. Puede utilizar las siguientes
        herramientas de terceros para rechazar la recopilación y el uso de información con el fin de
        ofrecerle publicidad basada en sus intereses:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        La plataforma de exclusión voluntaria de NAI:{' '}
        <a href="http://www.networkadvertising.org/choices/">
          http://www.networkadvertising.org/choices/
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        La plataforma de exclusión voluntaria de EDAA{' '}
        <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        La plataforma de exclusión voluntaria de la DAA:{' '}
        <a href="http://optout.aboutads.info/?c=2&lang=EN">
          http://optout.aboutads.info/?c=2&lang=EN
        </a>
      </li>
      <p>
        Puede optar por no recibir toda la publicidad personalizada habilitando funciones de
        Privacidad en su dispositivo móvil, como Limitar el seguimiento de anuncios (iOS) y Optar
        por no recibir personalización de anuncios (Android). Consulte el sistema de ayuda de su
        dispositivo móvil para obtener más información.
      </p>
      <p>
        Podemos compartir información, como direcciones de correo electrónico codificadas (si están
        disponibles) u otros identificadores en línea recopilados en nuestro Servicio con estos
        proveedores externos. Esto permite que nuestros proveedores externos reconozcan y le
        entreguen anuncios en todos los dispositivos y navegadores. Para obtener más información
        sobre las tecnologías utilizadas por estos proveedores externos y sus capacidades entre
        dispositivos, consulte la Política de Privacidad de cada proveedor que se detalla a
        continuación.
      </p>
      <p>Los proveedores externos que podemos utilizar son, entre otros:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Anuncios de Google (AdWords)</b> <br /> El servicio de remarketing de Google Ads
        (AdWords) es proporcionado por Google Inc. Puede darse de baja de Google Analytics para
        publicidad gráfica y personalizar los anuncios de la Red de Display de Google visitando la
        página de configuración de anuncios de Google:
        <br />
        <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a> <br />
        Google también recomienda instalar el complemento de inhabilitación para navegadores de
        Google Analytics{' '}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>{' '}
        - for your web browser. El complemento de inhabilitación para navegadores de Google
        Analytics ofrece a los visitantes la posibilidad de evitar que Google Analytics recopile y
        utilice sus datos. Para obtener más información sobre las Políticas de Privacidad de Google,
        visite la página web de Términos y Privacidad de Google:{' '}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Twitter</b> <br />
        El servicio de remarketing de Twitter es proporcionado por Twitter Inc. Puede darse de baja
        de los anuncios basados ​​en intereses de Twitter siguiendo sus instrucciones:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://support.twitter.com/articles/20170405">
          https://support.twitter.com/articles/20170405{' '}
        </a>
        <br /> Puede obtener más información sobre las Políticas y Políticas de Privacidad de
        Twitter visitando su página de Política de Privacidad:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://twitter.com/privacy">
          https://twitter.com/privacy
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Facebook / Meta</b>
        <br />
        El servicio de remarketing de Facebook o Meta es proporcionado por Facebook Inc. y Meta Inc.
        <br />
        Puede obtener más información sobre la publicidad basada en intereses de Facebook visitando
        esta página:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/help/516147308587266">
          https://www.facebook.com/help/516147308587266
        </a>
        <br />
        Para darse de baja de los anuncios basados ​​en intereses de Facebook, siga estas
        instrucciones de Facebook:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/help/568137493302217">
          https://www.facebook.com/help/568137493302217
        </a>
        <br />
        Facebook se adhiere a los Principios de autorregulación para la publicidad conductual en
        línea establecidos por Digital Advertising Alliance. También puede darse de baja de Facebook
        y otras empresas participantes a través de Digital Advertising Alliance en EE. UU.
        <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, Digital
        Advertising Alliance of Canada en Canadá{' '}
        <a href="http://youradchoices.ca/">http://youradchoices.ca/ </a>o la Alianza Europea de
        Publicidad Digital Interactiva en Europa{' '}
        <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/ </a>, o optar
        por no participar utilizando la configuración de su dispositivo móvil.
        <br />
        Para obtener más información sobre las Políticas de Privacidad de Facebook, visite la
        Política de datos de Facebook:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/privacy/explanation">
          https://www.facebook.com/privacy/explanation
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Pinterest</b> <br /> El servicio de remarketing de Pinterest es proporcionado por
        Pinterest Inc.
        <br />
        Puede darse de baja de los anuncios basados ​​en intereses de Pinterest habilitando la
        función "No rastrear" de su navegador web o siguiendo las instrucciones de Pinterest:{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="http://help.pinterest.com/en/articles/personalization-and-data"
        >
          http://help.pinterest.com/en/articles/personalization-and-data
        </a>{' '}
        <br />
        Puede obtener más información sobre las Políticas y Políticas de Privacidad de Pinterest
        visitando su página de Política de privacidad:{' '}
        <a href="https://about.pinterest.com/en/privacy-policy">
          https://about.pinterest.com/en/privacy-policy
        </a>
      </li>
      <h3>4.5 - Uso, rendimiento y varios</h3>
      <p>
        Podemos utilizar proveedores de servicios de terceros para proporcionar una mejor mejora de
        nuestro servicio. Pueden incluir, pero no se limitan a:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Intercomunicador</b> <br /> Su Política de Privacidad se puede ver en{' '}
        <a href="https://www.intercom.com/legal/privacy">https://www.intercom.com/legal/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Facebook Messenger</b> <br /> Su Política de Privacidad se puede ver en{' '}
        <a href="https://www.facebook.com/privacy/policy">
          https://www.facebook.com/privacy/policy
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Zendesk <br />
        Su Política de Privacidad se puede ver en{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"
        >
          https://www.zendesk.com/company/agreements-and-terms/privacy-notice/
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Tawk</b> <br /> Su Política de Privacidad se puede ver en{' '}
        <a href="https://www.tawk.to/privacy-policy/"> https://www.tawk.to/privacy-policy/ </a>
      </li>
      <h2>5 - Enlaces a otros sitios web</h2>
      <p>
        Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros.
        Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le
        recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.
      </p>
      <p>
        No tenemos control ni asumimos ninguna responsabilidad por el contenido, las Políticas de
        Privacidad o las Políticas de los sitios o servicios de terceros.
      </p>
      <h2>6 - Privacidad RGPD</h2>
      <h3>6.1 - Base legal para el procesamiento de datos personales bajo RGPD</h3>
      <p>Podemos procesar datos personales bajo las siguientes condiciones:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Consentimiento:</b> ha dado su consentimiento para procesar datos personales para uno o
        más propósitos específicos.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Ejecución de un contrato:</b> El suministro de Datos Personales es necesario para la
        ejecución de un acuerdo con Usted y/o para cualquier obligación precontractual del mismo.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b> Obligaciones legales: </b> el Tratamiento de Datos Personales es necesario para el
        cumplimiento de una obligación legal a la que está sujeta la Empresa o el Operador.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b> Intereses vitales:</b> el procesamiento de datos personales es necesario para proteger
        sus intereses vitales o los de otra persona física.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b> Intereses públicos:</b> el procesamiento de datos personales está relacionado con una
        tarea que se lleva a cabo en interés público o en el ejercicio de la autoridad oficial
        conferida a la Empresa o al Operador.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Intereses legítimos:</b> el procesamiento de datos personales es necesario para los fines
        de los intereses legítimos perseguidos por la Empresa o el Operador.
      </li>
      <p>
        En cualquier caso, la Empresa o el Operador estarán encantados de ayudar a aclarar la base
        legal específica que se aplica al procesamiento y, en particular, si el suministro de Datos
        personales es un requisito legal o contractual, o un requisito necesario para celebrar un
        contrato.
      </p>
      <h3>6.2 - Sus derechos bajo el RGPD</h3>
      <p>
        La Empresa u Operador se compromete a respetar la confidencialidad de sus Datos Personales y
        garantizar que Usted pueda ejercer sus derechos.
      </p>
      <p>
        Tiene derecho en virtud de esta Política de Privacidad, y por ley si se encuentra dentro de
        la UE, a:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Solicitar acceso a sus datos personales.</b> El derecho a acceder, actualizar o eliminar
        la información que tenemos sobre Usted. Siempre que sea posible, puede acceder, actualizar o
        solicitar la eliminación de sus datos personales directamente en la sección de configuración
        de su cuenta. Si no puede realizar estas acciones Usted mismo, contáctenos para ayudarlo.
        Esto también le permite recibir una copia de los Datos personales que tenemos sobre Usted.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Solicitar la corrección de los Datos personales que tenemos sobre Usted.</b> Tiene
        derecho a que se corrija cualquier información incompleta o inexacta que tengamos sobre
        Usted.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Objetar el procesamiento de sus datos personales.</b> Este derecho existe cuando nos
        basamos en un interés legítimo como base legal para nuestro procesamiento y hay algo en su
        situación particular que hace que desee oponerse a nuestro procesamiento de sus datos
        personales por este motivo. También tiene derecho a oponerse cuando procesamos sus datos
        personales con fines de marketing directo.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Solicitar la eliminación de sus datos personales.</b> Tiene derecho a solicitarnos que
        eliminemos o eliminemos Datos personales cuando no haya una buena razón para que sigamos
        procesándolos.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b> Solicitar la transferencia de sus Datos Personales. </b> Le proporcionaremos a Usted, o
        a un tercero que haya elegido, sus datos personales en un formato estructurado, de uso común
        y legible por máquina. Tenga en cuenta que este derecho solo se aplica a la información
        automatizada que inicialmente nos dio su consentimiento para usar o cuando usamos la
        información para realizar un contrato con Usted.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Retirar su consentimiento.</b> Tiene derecho a retirar su consentimiento sobre el uso de
        sus datos personales. Si retira su consentimiento, es posible que no podamos brindarle
        acceso a ciertas funcionalidades específicas del Servicio.
      </li>
      <h3>6.3 - Ejercicio de sus derechos de protección de datos en el RGPD</h3>
      <p>
        Puede ejercer sus derechos de acceso, rectificación, cancelación y oposición poniéndose en
        contacto con nosotros. Tenga en cuenta que podemos pedirle que verifique su identidad antes
        de responder a dichas solicitudes. Si realiza una solicitud, haremos todo lo posible para
        responderle lo antes posible.
      </p>
      <p>
        Tiene derecho a quejarse ante una autoridad de protección de datos sobre nuestra
        recopilación y uso de sus datos personales. Para obtener más información, si se encuentra en
        el Espacio Económico Europeo (EEE), comuníquese con su autoridad local de protección de
        datos en el EEE.
      </p>
      <h2>7 - Cambios a esta Política de Privacidad</h2>
      <p>
        Es posible que actualicemos nuestra Política de Privacidad de vez en cuando. Le
        notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.
      </p>
      <p>
        Le informaremos por correo electrónico y/o un aviso destacado en nuestro Servicio, antes de
        que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la
        parte superior de esta Política de Privacidad.
      </p>
      <p>
        Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio.
        Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.
      </p>
    </>
  );
}
