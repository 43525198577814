import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';

import css from './ActivityTransportationPanel.module.css';
import { ActivityTransportationForm } from '../../forms';
import { PRIVATE_TOUR } from '../EditListingWizard/EditListingWizardTab';

const ActivityTransportationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    sectionCategoryLabel,
    isLanguageEnglish,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, geolocation, publicData } = currentListing.attributes;
  const category = publicData?.category ?? params?.category;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id={'ActivityTransportationPanel.activeListingTitle'}
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id={'ActivityTransportationPanel.createListingActivityTitle'} />
  );
  const titleInitialValue = title === 'Draft' ? undefined : title;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

  // Only render current search if full place object is available in the URL params
  // TODO bounds are missing - those need to be queried directly from Google Places
  const locationFieldsPresent =
    publicData && publicData.location && publicData.location.address && geolocation;
  const location = publicData && publicData.location ? publicData.location : {};
  const { address, building } = location;

  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <ActivityTransportationForm
        className={css.form}
        initialValues={{
          transportation: publicData?.transportation,
          es_transportation: publicData?.es_transportation,
          es_meetingPoint: publicData?.es_meetingPoint,
          meetingPoint: publicData?.meetingPoint,
          building,
          location: locationFieldsPresent
            ? {
                search: address,
                selectedPlace: { address, origin: geolocation },
              }
            : null,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            transportation,
            es_transportation,
            es_meetingPoint,
            meetingPoint,
            building = '',
            location,
          } = values;
          const {
            selectedPlace: { address, origin },
          } = location;
          const updateValues = {
            geolocation: origin,
            publicData: {
              location: { address, building },
              transportation,
              es_transportation,
              es_meetingPoint,
              meetingPoint,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        isLanguageEnglish={isLanguageEnglish}
        category={category}
      />
    </div>
  );
};

ActivityTransportationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

ActivityTransportationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default ActivityTransportationPanel;
