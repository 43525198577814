import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { convertLabel, FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import config from '../../config';
import useInitialValues from '../useInitialValueHook/useInitialValueHook';
import css from './TourPricingPanel.module.css';
import { TourPricingForm } from '../../forms';
import { ACTIVITY, PRIVATE_TOUR } from '../EditListingWizard/EditListingWizardTab';
import { useIntl } from 'react-intl';
import { getTotalWithCommissionWithRound } from '../BookingBreakdown/LineItemRefundMaybe';

const TourPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    sectionCategoryLabel,
    isLanguageEnglish,
  } = props;
  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, price, title, publicData } = currentListing.attributes;
  const { people, extra } = publicData || {};
  const normalizeInitialValues = initialValues => {
    return initialValues?.map((item, index) => {
      return {
        ...item,
        price: item.price_original ? new Money(item.price_original, config.currency) : null,
      };
    });
  };
  let peopleOptions = [];
  if (people) {
    for (let i = 1; i <= people; i++) {
      peopleOptions.push({
        person: i,
        price: 0,
      });
    }
  }
  const category = publicData?.category ?? params?.category;
  const isActivity = category === ACTIVITY;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id={isActivity ? 'TourPricingPanel.titleActivity' : 'TourPricingPanel.title'}
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage
      id={
        isActivity
          ? 'TourPricingPanel.createListingTitleActivity'
          : 'TourPricingPanel.createListingTitle'
      }
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  );
  const titleInitialValue = title === 'Draft' ? undefined : title;
  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const normalizeValue = value => {
    return value?.map((item, index) => {
      return {
        id: index + 1,
        ...item,
        price: getTotalWithCommissionWithRound(item?.price?.amount),
        price_original: item?.price?.amount,
      };
    });
  };
  const isPrivateTour = category === PRIVATE_TOUR;
  const priceOptions = convertLabel(config.custom.privateTourPricingOptions, intl);
  const oldInitialValues = {
    pricePerPerson: normalizeInitialValues(publicData?.pricePerPerson) || peopleOptions,
    extra: normalizeInitialValues(extra),
    includeAccommodation: publicData?.includeAccommodation,
    priceType: publicData?.priceType
      ? priceOptions?.find(option => option.key === publicData?.priceType)
      : [],
    price:
      publicData?.priceType === 'fixed_price'
        ? publicData?.price_original
          ? new Money(publicData?.price_original, config.currency)
          : price
        : null,
  };

  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <TourPricingForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { priceType, extra, price, pricePerPerson, includeAccommodation } = values;
          const priceWithCommission = new Money(
            getTotalWithCommissionWithRound(
              priceType?.value === 'fixed_price' ? price?.amount : pricePerPerson[0].price?.amount
            ),
            config.currency
          );
          const updateValues = {
            price: priceWithCommission,
            // price: priceType?.value === 'fixed_price' ? price : pricePerPerson[0].price,
            publicData: {
              priceType: priceType?.value,
              includeAccommodation,
              price_original:
                priceType?.value === 'fixed_price'
                  ? price?.amount
                  : pricePerPerson[0].price?.amount,
              // extra: normalizeValue(extra),
              pricePerPerson:
                priceType?.value === 'price_per_person' ? normalizeValue(pricePerPerson) : null,
            },
          };
          updateInitialValues({
            ...values,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        isLanguageEnglish={isLanguageEnglish}
        people={people}
        category={category}
        priceOptions={priceOptions}
        isPrivateTour={isPrivateTour}
      />
    </div>
  );
};

TourPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

TourPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default TourPricingPanel;
