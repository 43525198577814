import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, convertLabel, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import {
  Button,
  FieldCurrencyInput,
  FieldInputCounter,
  FieldRadioButton,
  FieldSelectModern,
  FieldTextInput,
  Form,
  TranslateText,
} from '../../components';
import { propTypes } from '../../util/types';
import css from './TourPricingForm.module.css';
import {
  composeValidators,
  moneySubUnitAmountAtLeast,
  required,
  zeroNumberValidate,
} from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FieldArray } from 'react-final-form-arrays';
import { PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';
const { Money } = sdkTypes;
const TourPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        invalid,
        form,
        values,
        isLanguageEnglish,
        intl,
        people,
        category,
        isPrivateTour,
        priceOptions,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const priceRequired = required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? composeValidators(priceRequired, minPriceRequired)
        : priceRequired;
      const showAsRequired = pristine && required('required');
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="TourPricingForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="TourPricingForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {category === PRIVATE_TOUR ? (
            <p className={css.tipnote}>
              {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.privateTourNoteOne' })}
            </p>
          ) : (
            <p className={css.tipnote}>
              {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteTwo' })}
            </p>
          )}
          <FieldSelectModern
            id="priceType"
            name="priceType"
            className={css.priceInput}
            label={intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.typeLabel' })}
            options={priceOptions}
            // options={config.custom.privateTourPricingOptions}
            placeholder={intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.selectType' })}
            validate={required(
              intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.typeIsRequired' })
            )}
          />
          {values && values?.priceType && values?.priceType?.value ? (
            <div>
              {values?.priceType?.value === 'fixed_price' ? (
                <div className={css.container}>
                  <FieldCurrencyInput
                    id="price"
                    name="price"
                    className={css.priceInput}
                    label={intl.formatMessage({
                      id:
                        category === PRIVATE_TOUR
                          ? 'TourPricingForm.TourPricingForm.pricePerTourLabel'
                          : 'TourPricingForm.TourPricingForm.pricePerTourLabelActivity',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'TourPricingForm.TourPricingForm.pricePlaceholder',
                    })}
                    currencyConfig={config.currencyConfig}
                    validate={priceValidators}
                  />
                </div>
              ) : (
                <div>
                  {category === PRIVATE_TOUR ? (
                    <p className={css.tipnote}>
                      {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteThree' })}
                    </p>
                  ) : (
                    <p className={css.tipnote}>
                      {intl.formatMessage({
                        id: 'TourPricingForm.TourPricingForm.noteThreeActivity',
                      })}
                    </p>
                  )}
                  <FieldArray name="pricePerPerson">
                    {({ fields }) => (
                      <div>
                        {fields.map((name, index) => (
                          <div key={name} className={css.fieldArrayContainer}>
                            <p>
                              {values?.pricePerPerson[index]?.person}{' '}
                              {values?.pricePerPerson[index]?.person > 1
                                ? intl.formatMessage({
                                    id: 'TourPricingForm.TourPricingForm.perosns',
                                  })
                                : intl.formatMessage({
                                    id: 'TourPricingForm.TourPricingForm.perosn',
                                  })}
                            </p>

                            <FieldCurrencyInput
                              id={`${name}.price`}
                              name={`${name}.price`}
                              //   className={css.priceInput}
                              placeholder={intl.formatMessage({
                                id: 'TourPricingForm.TourPricingForm.enterPricePlaceholder',
                              })}
                              currencyConfig={config.currencyConfig}
                              validate={priceValidators}
                            />
                            <p>
                              {intl.formatMessage({
                                id: 'TourPricingForm.TourPricingForm.perPersonLabel',
                              })}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>
              )}
            </div>
          ) : null}
          {category === PRIVATE_TOUR ? (
            <div>
              <p className={css.label}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteFour' })}
              </p>
              {convertLabel(config.custom.privateTour, intl)?.map(user => (
                <FieldRadioButton
                  //   className={css.features}
                  id={`${user.key}`}
                  name="includeAccommodation"
                  label={user.label}
                  value={user.key}
                  showAsRequired={showAsRequired}
                />
              ))}
            </div>
          ) : null}
          {/* <p className={css.label}>
            {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.priceForExtras' })}
          </p>
          {isPrivateTour ? (
            <div>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteFive' })}
              </p>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteSix' })}
              </p>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteSeven' })}
              </p>
            </div>
          ) : (
            <div>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteEight' })}
              </p>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteNine' })}
              </p>
              <p className={css.tipnote}>
                {intl.formatMessage({ id: 'TourPricingForm.TourPricingForm.noteTen' })}
              </p>
            </div>
          )}
          <FieldArray name="extra">
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <div key={name}>
                    <div>
                      <FieldCurrencyInput
                        id={`${name}.price`}
                        name={`${name}.price`}
                        className={css.priceInput}
                        label={`Price for ${values?.extra[index]?.name}`}
                        placeholder={intl.formatMessage({
                          id: 'TourPricingForm.TourPricingForm.enterPricePlaceholderMessage',
                        })}
                        currencyConfig={config.currencyConfig}
                        validate={priceValidators}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray> */}
          <div style={{ marginTop: '1rem' }}>
            <p
              style={{
                borderLeft: '5px solid #17a2b8',
                padding: '1rem .5rem',
                lineHeight: '1.4',
                background: 'hsl(188deg 78% 89%)',
              }}
            >
              <p className={css.tip} style={{ marginBottom: '1px' }}>
                {intl.formatMessage({
                  id: 'EditListingPricingForm.EditListingPricingForm.noteFour',
                })}
              </p>
            </p>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

TourPricingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

TourPricingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const TourPricingForm = TourPricingFormComponent;

export default injectIntl(TourPricingForm);
