import React from 'react';
import { FieldTextInput, FieldCheckbox, FieldCurrencyInput } from '..';
import config from '../../config';
import css from './CheckboxWithField.module.css';

function CheckboxWithField(props) {
  const {
    commonId,
    commonName,
    prefix,
    checkboxProps,
    inputProps,
    checkboxDefaultValue,
    variant,
    showInput,
  } = props;
  const inputId = `${commonId}_${prefix}`;
  const inputName = `${commonName}_${prefix}`;

  return (
    <div className={css.root}>
      <FieldCheckbox
        id={commonId}
        name={commonName}
        // value={checkboxDefaultValue}
        {...checkboxProps}
      />

      {showInput ? (
        variant == 'text' ? (
          <FieldTextInput id={inputId} name={inputName} {...inputProps} className={css.input} />
        ) : variant == 'price' ? (
          <FieldCurrencyInput
            id={inputId}
            name={inputName}
            className={css.input}
            currencyConfig={config.currencyConfig}
            custom={true}
            withoutError
            {...inputProps}
          />
        ) : null
      ) : null}
    </div>
  );
}

CheckboxWithField.defaultProps = {
  inputProps: {},
  checkboxProps: {},
  commonId: null,
  commonName: null,
  prefix: 'field',
  checkboxDefaultValue: 'true',
  variant: 'price',
  showInput: true,
};

export default CheckboxWithField;
