import React, { useState, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, IconSpinner, Modal } from '..';
import classNames from 'classnames';
import { Player, BigPlayButton } from 'video-react';
import css from './FileVideoUpload.module.css';
import { deleteVideo, uploadVideo } from '../../containers/EditListingPage/EditListingPage.duck';
import { PrimaryButton, SecondaryButton } from '../Button/Button';

const FILE_SIZE_LIMIT_IN_MEGA_BYTES = 500;

const ACCEPT_FILES = 'video/3gpp,video/mp4,video/mpeg,video/ogg,video/webm,video/quicktime';
const cloudStorageBucket = process.env.REACT_APP_GOOGLE_STORAGE_BUCKET;

function FieldUpload(props) {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [uploading, setUploading] = useState(false);

  return (
    <FinalForm
      {...props}
      isFileUploaded={isFileUploaded}
      setIsFileUploaded={setIsFileUploaded}
      uploadedFileData={uploadedFileData}
      setUploadedFileData={setUploadedFileData}
      uploading={uploading}
      setUploading={setUploading}
      onSubmit={console.log}
      render={formRenderProps => {
        const {
          form,
          onUploadFile,
          setIsFileUploaded,
          uploadedFileData,
          setUploadedFileData,
          uploading,
          setUploading,
          values,
          onDeleteFile,
          urlParam,
          publicDataHint,
          parentForm,
          parentValues,
        } = formRenderProps;

        const [deleting, setDeleting] = useState(null);
        const [fileSizeError, setFileSizeError] = useState(null);
        const [deleteId, setDeleteId] = useState(null);
        const [showConfirmationModal, setShowConfirmationModal] = useState(false);

        const handleDelete = id => {
          setDeleteId(id);
          setShowConfirmationModal(true);
        };

        const deleteFile = () => {
          setDeleting(deleteId);
          onDeleteFile({ id: deleteId, listingId: urlParam, key: publicDataHint })
            .then(res => {
              parentForm.change(
                `${publicDataHint}`,
                (parentValues?.[publicDataHint] || [])?.filter(p => !p.endsWith(deleteId))
              );
              form.change(`${publicDataHint}-file`, '');
              setUploadedFileData(null);
              setDeleting(null);
              setDeleteId(null);
              setShowConfirmationModal(false);
            })
            .catch(err => {
              setDeleting(null);
              setDeleteId(null);
              setShowConfirmationModal(false);
              console.log(err);
            });
        };

        const ConfirmationModal = (
          <Modal
            id="delete-video-confirmation"
            name="delete-video-confirmation"
            onManageDisableScrolling={() => ({})}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            usePortal
          >
            <h1>Confirmation</h1>
            <p>Are you sure, you want to delete it.</p>

            <PrimaryButton
              inProgress={deleting}
              style={{ marginTop: '1rem' }}
              type="button"
              onClick={deleteFile}
            >
              Confirm
            </PrimaryButton>
            <SecondaryButton
              style={{ marginTop: '1rem' }}
              type="button"
              onClick={() => {
                setDeleteId(null);
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </SecondaryButton>
          </Modal>
        );

        return (
          <Form onSubmit={console.log}>
            <div
              className={css.container}
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px,270px))',
                gridTemplateRows: '220px',
                gridAutoRows: '220px',
                gridGap: '1rem',
              }}
            >
              {parentValues?.[publicDataHint]?.map((link, index) => (
                <div key={link}>
                  <Player src={link} fluid={false} width="100%" height={180}>
                    <BigPlayButton position="center" />
                  </Player>
                  <button
                    type="button"
                    onClick={() =>
                      handleDelete(
                        parentValues?.[publicDataHint]?.[index]?.replace(
                          `https://storage.googleapis.com/${cloudStorageBucket}/`,
                          ''
                        )
                      )
                    }
                    className={classNames(
                      link.endsWith(deleting) ? css.deleting : '',
                      css.deleteVideoButton
                    )}
                  >
                    {link.endsWith(deleting) ? 'Deleting Video ...' : 'Delete Video'}
                  </button>
                </div>
              ))}

              {(parentValues?.[publicDataHint] || []).length < 1 && (
                <Field
                  id={`${publicDataHint}-file`}
                  name={`${publicDataHint}-file`}
                  accept={ACCEPT_FILES}
                  form={null}
                  label={
                    values[`${publicDataHint}-file`]
                      ? values[`${publicDataHint}-file`].name
                      : 'Click to Upload Video'
                  }
                  type="file"
                  disabled={uploading}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      setFileSizeError(null);
                      const file = e.target.files[0];
                      const fileSizeInMegaBytes = file.size / 1024 / 1024;
                      if (fileSizeInMegaBytes > FILE_SIZE_LIMIT_IN_MEGA_BYTES) {
                        setFileSizeError('Video size should not exceed 500mb');
                        return;
                      }
                      form.change(`${publicDataHint}-file`, file);
                      form.blur(`${publicDataHint}-file`);
                      setUploading(true);
                      onUploadFile({ urlParam, publicDataHint }, file)
                        .then(res => {
                          setIsFileUploaded(true);
                          parentForm.change(`${publicDataHint}`, [
                            ...(parentValues?.[publicDataHint] || []),
                            res[publicDataHint],
                          ]);
                          form.change(`${publicDataHint}-file`, '');
                          setUploading(false);
                        })
                        .catch(err => {
                          setFileSizeError(err?.response?.data?.message ?? e.message);
                          setUploading(false);
                          setIsFileUploaded(false);
                        });
                    };
                    const inputProps = {
                      accept,
                      id: name,
                      name,
                      onChange,
                      type,
                    };
                    return (
                      <div>
                        <div
                          className={classNames(
                            css.addImageWrapper,
                            parentValues[publicDataHint] ? css.disabledField : ''
                          )}
                          style={{
                            float: 'unset',
                            width: '300px',
                            position: 'relative',
                            // margin: '1rem',
                          }}
                        >
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {label}
                            </label>
                          </div>
                          {uploading && (
                            <div className={css.loadingContainer}>
                              <IconSpinner className={css.spinner} />
                            </div>
                          )}
                        </div>
                        {fileSizeError && (
                          <span style={{ color: 'red', fontSize: '.9rem' }}>{fileSizeError}</span>
                        )}
                      </div>
                    );
                  }}
                </Field>
              )}
              {ConfirmationModal}
            </div>
          </Form>
        );
      }}
    />
  );
}

const mapStateToProps = state => {
  // const currentUser = state.user.currentUser;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadFile: (extraData, file) => dispatch(uploadVideo(extraData, file)),
    onDeleteFile: id => dispatch(deleteVideo(id)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FieldUpload);
