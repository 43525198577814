import React from 'react';
import css from './DateRangeInput.module.css';
import { useIntl } from 'react-intl';
const ColorInformation = () => {
  const intl = useIntl();
  return (
    <div className={css.colorContainer}>
      <div className={css.available}>
        {intl.formatMessage({ id: 'FieldDateRangeInput.ColorInformation.availableLabel' })}
      </div>
      <div className={css.unavailable}>
        {intl.formatMessage({ id: 'FieldDateRangeInput.ColorInformation.unavailableLabel' })}
      </div>
      <div className={css.short}>
        {intl.formatMessage({ id: 'FieldDateRangeInput.ColorInformation.shortNoticeLabel' })}
      </div>
      <div className={css.past}>
        {intl.formatMessage({ id: 'FieldDateRangeInput.ColorInformation.pastLabel' })}
      </div>
    </div>
  );
};

export default ColorInformation;
