import React, { Component, useEffect, useState } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
  notGreaterThanLessons,
  minimumStayRequired,
  maximumStayRequired,
  allowBookingBeforeStart,
  notLessthanLessons,
} from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  FieldSelect,
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldTextInput,
  FieldInputCounter,
  FieldSelectModern,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import arrayMutators from 'final-form-arrays';

import debounce from 'lodash/debounce';

import css from './BookingDatesForm.module.css';
import { formatMoney } from '../../util/currency';
const { Money, UUID } = sdkTypes;
const identity = v => v;

const debouncedOnFetch = debounce(function(onFetchTransactionLineItems, params) {
  onFetchTransactionLineItems(params);
}, 1000);

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(previousValues, formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};

    const {
      'booking-type': bookingType,
      additional_services = [],
      additional_lessons = 0,
      activities,
      singlePack_counter,
      singlePack,
    } = formValues.values || {};

    const packOfLessons = [];
    for (const key in formValues?.values) {
      if (key.startsWith('packOfLesson_') && !key.endsWith('_counter')) {
        const lessonPack = key;
        const value = formValues?.values[lessonPack];
        if (!value) continue;
        const counterKey = `${lessonPack}_counter`;
        const newObject = {
          lesson: lessonPack,
          key: lessonPack.split('_')[1],
          quantity: formValues?.values[counterKey],
        };
        if (formValues?.values[counterKey]) {
          packOfLessons.push(newObject);
        }
      }
    }
    const activitiesArray = [];
    for (const key in formValues?.values) {
      if (key.startsWith('activity_') && !key.endsWith('_counter')) {
        const activity = key;
        const counterKey = `${activity}_counter`;
        const value = formValues?.values[activity];
        if (!value) continue;
        const newObject = {
          activity: activity,
          key: activity.split('_')[1],
          quantity: formValues?.values[counterKey],
        };

        activitiesArray.push(newObject);
      }
    }

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    // const checkAndGetPriceFromDatePriceArray = date => {
    //   const convertSelectedDate = date ? date?.date?.toISOString().split('T')[0] : null;
    //   const priceFromArr = this.props.publicData?.datePriceArray?.map(item => {
    //     const convertedStartDate = new Date(item.start_date).toISOString().split('T')[0];
    //     const convertedEndDate = item?.end_date
    //       ? new Date(item.end_date).toISOString().split('T')[0]
    //       : '';
    //     // console.log({ convertSelectedDate, convertedEndDate, convertedStartDate });
    //     if (convertSelectedDate >= convertedStartDate && convertSelectedDate < convertedEndDate) {
    //       return item?.price;
    //     }
    //   });
    //   return { isDatePrice: true, price: priceFromArr && priceFromArr[0] };
    // };
    // const priceFromDatePriceArray = checkAndGetPriceFromDatePriceArray(bookingStartDate);
    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      const params = {
        bookingData: {
          startDate,
          endDate,
          bookingType,
          additional_services,
          additional_lessons,
          activities,
          r_activities: activitiesArray,
          packOfLessons,
          singlePack: singlePack ? singlePack_counter : 0,
        },
        listingId,
        isOwnListing,

        // priceFromDatePriceArray,
      };

      if (previousValues.additional_lessons != formValues.values.additional_lessons) {
        debouncedOnFetch(this.props.onFetchTransactionLineItems, params);
      } else {
        this.props.onFetchTransactionLineItems(params);
      }
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        mutators={{ ...arrayMutators }}
        {...rest}
        unitPrice={unitPrice}
        initialValues={{ additional_lessons: 0 }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            paymentMethods,
            listing,
            invalid,
            bufferTime,
            disabled,
            form,
            errors,
            language,
          } = fieldRenderProps;
          const [days, setDays] = useState(0);
          const [lessons, setLessons] = useState(false);
          useEffect(() => {
            const packOfLessons = Object.keys(values).filter(
              k => k.startsWith('packOfLesson_') && !k.endsWith('_counter')
            );
            const isAnyPackOfLessons = packOfLessons?.some(k => values[k]);
            const singlePack = values?.singlePack;
            if (isAnyPackOfLessons || singlePack) {
              setLessons(true);
            } else {
              setLessons(false);
            }
          }, [values]);

          useEffect(() => {
            form.change(
              'singlePack_counter',
              values?.singlePack_counter ? values?.singlePack_counter : 1
            );
            if (values?.bookingDates?.startDate && values?.bookingDates?.endDate) {
              //calculate number of days
              const startDate = values?.bookingDates?.startDate;
              const endDate = values?.bookingDates?.endDate;
              const diffTime = Math.abs(endDate - startDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              setDays(diffDays);
            }
          }, [values]);
          const isLanguageEnglish = language === 'english';
          const isError = !!errors?.additional_lessons;
          const displayName = listing?.author?.attributes?.profile?.displayName || '';
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const maxLessons = listing?.attributes?.publicData?.lessons;
          const minLessons = listing?.attributes?.publicData?.min_lessons || 0;
          const guests = listing?.attributes?.publicData?.guests;
          const packOfLessons = listing?.attributes?.publicData?.packOfLessons || [];
          let peopleOptions = [];
          if (guests) {
            for (let i = 1; i <= guests; i++) {
              peopleOptions.push({
                key: i,
                label: i,
                value: i,
              });
            }
          }
          const packOfLessonsArray =
            packOfLessons?.length > 0
              ? packOfLessons?.map((item, index) => {
                  form.change(
                    `packOfLesson_${item?.key}_counter`,
                    values?.[`packOfLesson_${item?.key}_counter`] || 1
                  );
                  return (
                    <div className={css.packOfLessons}>
                      <FieldCheckbox
                        id={`packOfLesson_${item?.key}`}
                        name={`packOfLesson_${item?.key}`}
                        label={
                          <div className={css.packOfLesson}>
                            <div className={css.packOfLessonName}>
                              {
                                <FormattedMessage
                                  id="BookingDatesForm.BookingDatesForm.packOfLessons"
                                  values={{ hours: item?.hours }}
                                />
                              }
                            </div>
                            {item?.price ? (
                              <div className={css.packOfLessonPrice}>
                                {formatMoney(
                                  intl,
                                  new Money(
                                    item?.priceWithCommission ||
                                      item?.price + (item?.price * 10) / 100,
                                    config.currency
                                  )
                                )}
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                      <FieldInputCounter
                        className={css.counter}
                        id={`packOfLesson_${item?.key}_counter`}
                        name={`packOfLesson_${item?.key}_counter`}
                        type="number"
                        min={0}
                      />
                    </div>
                  );
                })
              : null;
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const cashless = values?.['booking-type'] == 'cash_on_arrival' ? true : false;

          const submitDisabled =
            invalid ||
            disabled ||
            !startDate ||
            !endDate ||
            fetchLineItemsInProgress ||
            !lessons ||
            !values?.ageCheck;
          // || !values?.['booking-type'];

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                cashless={cashless}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingTypeDisabled = !startDate || !endDate;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const additionalPrices = listing.attributes.publicData?.additionalPrices ?? [];
          const activities = listing.attributes.publicData?.activities ?? [];
          const recurringActivities = activities?.filter(k => k.recuring);
          const isIncluded = key => additionalPrices.find(ap => ap.fieldname == key && ap.selected);
          const minimumStay = listing.attributes.publicData?.min_stay || 1;
          const maximumStay = listing.attributes.publicData?.max_stay || 120;
          const recuringData =
            recurringActivities?.length > 0
              ? recurringActivities?.map((item, index) => {
                  form.change(
                    `activity_${item?.key}_counter`,
                    values?.[`activity_${item?.key}_counter`] || 1
                  );
                  return (
                    <div className={css.packOfLessons}>
                      <FieldCheckbox
                        id={`activity_${item?.key}`}
                        name={`activity_${item?.key}`}
                        label={
                          <div className={css.packOfLesson}>
                            <div className={css.packOfLessonName}>
                              {isLanguageEnglish ? item?.name : item?.es_name || item?.name}{' '}
                              {item.hours
                                ? item.hours > 1
                                  ? `(${item?.hours} ${intl.formatMessage({
                                      id: 'BookingDatesForm.BookingDatesForm.hours',
                                    })})`
                                  : item.hours
                                  ? `(${item?.hours} ${intl.formatMessage({
                                      id: 'BookingDatesForm.BookingDatesForm.hour',
                                    })})`
                                  : null
                                : null}
                            </div>
                            {item?.price ? (
                              <div className={css.packOfLessonPrice}>
                                {formatMoney(
                                  intl,
                                  new Money(
                                    item?.priceWithCommission ||
                                      item?.price + (item?.price * 10) / 100,
                                    config.currency
                                  )
                                )}
                              </div>
                            ) : null}
                          </div>
                        }
                        // value={item?.key}
                      />
                      <FieldInputCounter
                        className={css.counter}
                        id={`activity_${item?.key}_counter`}
                        name={`activity_${item?.key}_counter`}
                        type="number"
                        min={0}
                      />
                    </div>
                  );
                })
              : null;
          const priceOptions = config.custom.additionalPriceOptions
            .map(v => ({ ...v, key: v.id }))
            .filter(v => isIncluded(v.key));

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(fieldRenderProps.values, values);
                }}
              />

              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                bufferTime={bufferTime}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage),
                  minimumStayRequired(
                    <FormattedMessage
                      id="BookingDatesForm.BookingDatesForm.minMaxStay"
                      values={{
                        displayName: displayName,
                        minimumStay: minimumStay,
                        maximumStay: maximumStay,
                      }}
                    />,
                    minimumStay
                  ),
                  maximumStayRequired(
                    <FormattedMessage
                      id="BookingDatesForm.BookingDatesForm.minMaxStay"
                      values={{
                        displayName: displayName,
                        minimumStay: minimumStay,
                        maximumStay: maximumStay,
                      }}
                    />,
                    maximumStay
                  )
                )}
                disabled={fetchLineItemsInProgress}
              />
              {guests ? (
                <FieldSelectModern
                  id="persons"
                  name="persons"
                  label={intl.formatMessage({
                    id: 'BookingDatesForm.BookingTourForm.personsLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'BookingDatesForm.BookingTourForm.selectPlaceholder',
                  })}
                  className={css.field}
                  options={peopleOptions}
                  validate={required(
                    intl.formatMessage({
                      id: 'BookingDatesForm.BookingTourForm.personRequiredMessage',
                    })
                  )}
                />
              ) : null}
              <div className={css.bookingTypesContainer}>
                <div className={css.container}>
                  {packOfLessonsArray || additionalPrices.length ? (
                    <div className={css.packOfLessonsContainer}>
                      <h3 className={css.lessonLabel}>
                        {intl.formatMessage({ id: 'BookingDatesForm.BookingDatesForm.lessons' })}
                      </h3>
                      {additionalPrices.length > 0 && (
                        <div className={css.additionalPrices}>
                          <div className={css.packOfLessons}>
                            <FieldCheckbox
                              id={`singlePack`}
                              name={`singlePack`}
                              label={
                                <div className={css.packOfLesson}>
                                  <div className={css.packOfLessonName}>
                                    {intl.formatMessage({
                                      id: 'BookingDatesForm.BookingDatesForm.singleLesson',
                                    })}
                                  </div>
                                  {additionalPrices[0]?.price ? (
                                    <div className={css.packOfLessonPrice}>
                                      {formatMoney(
                                        intl,
                                        new Money(
                                          additionalPrices[0]?.priceWithCommission ||
                                            additionalPrices[0]?.price +
                                              (additionalPrices[0]?.price * 10) / 100,
                                          config.currency
                                        )
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                              }
                              // value={item?.key}
                            />
                            <FieldInputCounter
                              className={css.counter}
                              id={`singlePack_counter`}
                              name={`singlePack_counter`}
                              type="number"
                              min={0}
                            />
                          </div>
                        </div>
                      )}
                      {}
                      {packOfLessonsArray ? packOfLessonsArray : null}
                    </div>
                  ) : null}

                  {activities.length > 0 && (
                    <div className={css.activities}>
                      <h3 className={css.lessonLabel}>
                        {intl.formatMessage({
                          id: 'BookingDatesForm.BookingDatesForm.activitiesLabel',
                        })}
                      </h3>
                      {recuringData}
                      <FieldCheckboxGroup
                        id="activities"
                        name="activities"
                        isBooking={true}
                        // label={intl.formatMessage({
                        //   id: 'BookingDatesForm.BookingDatesForm.activitiesLabel',
                        // })}
                        options={activities
                          ?.filter(k => !k.recuring)
                          .map(item => ({
                            key: item?.key,
                            label: (
                              <div className={css.activityContainer}>
                                <div className={css.activityName}>
                                  {isLanguageEnglish ? item?.name : item?.es_name || item?.name}{' '}
                                  {item.hours
                                    ? item.hours > 1
                                      ? `(${item?.hours} ${intl.formatMessage({
                                          id: 'BookingDatesForm.BookingDatesForm.hours',
                                        })})`
                                      : item.hours
                                      ? `(${item?.hours} ${intl.formatMessage({
                                          id: 'BookingDatesForm.BookingDatesForm.hour',
                                        })})`
                                      : null
                                    : null}
                                </div>
                                <div className={css.activityPrice}>
                                  {formatMoney(
                                    intl,
                                    new Money(
                                      item?.priceWithCommission ||
                                        item?.price + (item?.price * 10) / 100,
                                      config.currency
                                    )
                                  )}
                                </div>
                              </div>
                            ),
                          }))}
                        // twoColumns
                      />
                    </div>
                  )}
                </div>
              </div>

              {isError ? null : bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <p className={css.smallPrint}>
                {intl.formatMessage({ id: 'BookingDatesForm.BookingDatesForm.noteAirbnb' })}
              </p>
              <FieldCheckbox
                id="ageCheck"
                name="ageCheck"
                // className={css.ageCheck}
                className={css.field}
                label={
                  <span className={css.age}>
                    {intl.formatMessage({ id: 'BookingDatesForm.BookingDatesForm.ageCheck' })}
                  </span>
                }
              />
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
