import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import config from '../../config';
import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'amenities';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    sectionCategoryLabel,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const amenities = publicData && publicData.amenities;

  const newLabel = convertLabel(config.custom.TypeOfAcommodationOptions, intl);
  const privateSelected = convertLabel(config.custom.privateSelectedOptions, intl);
  const initialValues = {
    amenities,
    stay: convertLabel(config.custom.stayOptions, intl)?.find(
      item => item.value === publicData.stay
    ),
    type: newLabel?.find(item => item.value === publicData.type),
    guests: publicData.guests ? publicData.guests : 0,
    beds: publicData.beds ? publicData.beds : 0,
    bedrooms: publicData.bedrooms ? publicData.bedrooms : 0,
    // persons: publicData.persons ? publicData.persons : 0,
    bathrooms: publicData.bathrooms ? publicData.bathrooms : 0,
    privateBathrooms: publicData.privateBathrooms,
    // private_selected: privateSelected?.find(item => item.value === publicData.private_selected),
  };
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingFeaturesForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        newLabel={newLabel}
        privateSelected={privateSelected}
        onSubmit={values => {
          const {
            amenities = [],
            stay,
            type,
            guests,
            beds,
            bedrooms,
            bathrooms,
            privateBathrooms,
            // private_selected,
            // persons,
          } = values;
          const personsFilter = [];
          for (let i = guests; i > 0; i--) {
            personsFilter.push(String(i));
          }
          const isWifiSelected = !!amenities.find(item => item === 'wifi');
          const isdedicatedWorkspaceSelected = !!amenities.find(
            item => item === 'dedicated_workspace'
          );
          // console.log('isdedicatedWorkspaceSelected', isdedicatedWorkspaceSelected);
          const updatedValues = {
            // title: 'Draft Listing',
            publicData: {
              amenities,
              stay: type ? null : stay?.key,
              type: stay ? null : type?.key,
              guests,
              beds,
              bedrooms,
              wifi: isWifiSelected,
              dedicatedWorkspace: isdedicatedWorkspaceSelected,
              // persons,
              bathrooms,
              guestFilter: personsFilter,
              privateBathrooms,
              // private_selected: private_selected ? private_selected?.key : null,
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
