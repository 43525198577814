import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TermsEn from './TermsEn';
import TermsSp from './TermsSp';
import css from './TermsOfService.module.css';
import { Helmet } from 'react-helmet';
const TermsOfService = props => {
  const { rootClassName, className, divId } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  let cookieValue;
  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'english';
  }
  // prettier-ignore
  return (
    <div className={classes}>
      <Helmet>
      <link
          rel="canonical"
          href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}/es/terms-of-service`}
          hreflang="es"
        />
        <link
          rel="alternate"
          href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}/en/terms-of-service`}
          hreflang="en"
        />
        
      </Helmet>
      {cookieValue === 'english' ? <TermsEn divId={divId} /> : <TermsSp divId={divId} />}
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
