import React, { useEffect, useRef } from 'react';
import css from './ListingPage.module.css';

import { Player, BigPlayButton, ControlBar, VolumeMenuButton, PlayToggle } from 'video-react';
import { FormattedMessage } from '../../util/reactIntl';

function SectionVideosMaybe({ publicData }) {
  const videos = publicData?.videos;
  const sectionVideosHeading = <FormattedMessage id="ListingPage.sectionVideosMaybeHeading" />;

  return videos?.length > 0 ? (
    <div className={css.sectionDescription}>
      <h2 className={css.featuresTitle}>{sectionVideosHeading}</h2>
      <div className={css.videoSection}>
        {videos?.map(video => (
          <Player src={video} key={video} fluid={false} width="100%" height={220}>
            <BigPlayButton position="center" />
            {/* <PlayToggle /> */}
            {/* <VolumeMenuButton vertical /> */}
            <ControlBar />
          </Player>
        ))}
      </div>
    </div>
  ) : null;
}

export default SectionVideosMaybe;
