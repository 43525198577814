/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const privateTourPricingOptions = [
  {
    label: 'src.marketplace-custom-config.fixed',
    key: 'fixed_price',
    value: 'fixed_price',
  },
  {
    label: 'src.marketplace-custom-config.perperson',
    key: 'price_per_person',
    value: 'price_per_person',
  },
];
export const immersionProgramOptions = [
  {
    label: 'src.marketplace-custom-config.biginner',
    key: 'beginner',
    value: 'beginner',
  },
  {
    label: 'src.marketplace-custom-config.intermidate',
    key: 'intermediate',
    value: 'intermediate',
  },
  {
    label: 'src.marketplace-custom-config.advacnce',
    key: 'advanced',
    value: 'advanced',
  },
];
export const teachingAvailability = [
  {
    label: 'src.marketplace-custom-config.mondayLabel',
    key: 'monday',
    value: 'monday',
  },
  {
    label: 'src.marketplace-custom-config.tuesdayLabel',
    key: 'tuesday',
    value: 'tuesday',
  },
  {
    label: 'src.marketplace-custom-config.wednesdayLabel',
    key: 'wednesday',
    value: 'wednesday',
  },
  {
    label: 'src.marketplace-custom-config.thursdayLabel',
    key: 'thursday',
    value: 'thursday',
  },
  {
    label: 'src.marketplace-custom-config.fridayLabel',
    key: 'friday',
    value: 'friday',
  },
  {
    label: 'src.marketplace-custom-config.saturdayLabel',
    key: 'saturday',
    value: 'saturday',
  },
  {
    label: 'src.marketplace-custom-config.sundayLabel',
    key: 'sunday',
    value: 'sunday',
  },
];
export const customProgramOptions = [
  {
    label: 'src.marketplace-custom-config.specificPurposes',
    key: 'specific_purposes',
    value: 'specific_purposes',
  },
  {
    label: 'src.marketplace-custom-config.business',
    key: 'business',
    value: 'business',
  },
  {
    label: 'src.marketplace-custom-config.examPreparation',
    key: 'exam_preparation',
    value: 'exam_preparation',
  },
  {
    label: 'src.marketplace-custom-config.grammer',
    key: 'grammar',
    value: 'grammar',
  },
  {
    label: 'src.marketplace-custom-config.vocabulary',
    key: 'vocabulary',
    value: 'vocabulary',
  },
  {
    label: 'src.marketplace-custom-config.conversation',
    key: 'conversation',
    value: 'conversation',
  },
  {
    label: 'src.marketplace-custom-config.otherCustom',
    key: 'other',
    value: 'other',
  },
];
export const qualificationOptions = [
  {
    label: 'src.marketplace-custom-config.diplomaOrCertificateLabel',
    key: 'diploma',
    value: 'diploma',
    abrivated: 'src.marketplace-custom-config.diplomaLabel',
  },
  {
    label: 'src.marketplace-custom-config.universityDegreeLabel',
    key: 'university',
    value: 'university',
    abrivated: 'src.marketplace-custom-config.bachelorsLabel',
  },
  {
    label: 'src.marketplace-custom-config.masterDegreeLabel',
    key: 'master',
    value: 'master',
    abrivated: 'src.marketplace-custom-config.masterLabel',
  },
  {
    label: 'src.marketplace-custom-config.otherQualifications',
    key: 'other',
    value: 'other',
  },
];
export const profileTypes = [
  {
    label: 'src.marketplace-custom-config.teacherLabel',
    key: 'teacher',
    value: 'teacher',
  },
  {
    label: 'src.marketplace-custom-config.studentLabel',
    key: 'student',
    value: 'student',
  },
];
export const countries = [
  {
    label: 'src.marketplace-custom-config.Afghanistan',
    key: 'AF',
    value: 'AF',
  },
  {
    label: 'src.marketplace-custom-config.alandIslands',
    key: 'AX',
    value: 'AX',
  },
  {
    label: 'src.marketplace-custom-config.albaniaLabel',
    key: 'AL',
    value: 'AL',
  },
  {
    label: 'src.marketplace-custom-config.algeria',
    key: 'DZ',
    value: 'DZ',
  },
  {
    label: 'src.marketplace-custom-config.americaSamoa',
    key: 'AS',
    value: 'AS',
  },
  {
    label: 'src.marketplace-custom-config.andorra',
    key: 'AD',
    value: 'AD',
  },
  {
    label: 'src.marketplace-custom-config.angola',
    key: 'AO',
    value: 'AO',
  },
  {
    label: 'src.marketplace-custom-config.Anguilla',
    key: 'AI',
    value: 'AI',
  },
  {
    label: 'src.marketplace-custom-config.Antarctica',
    key: 'AQ',
    value: 'AQ',
  },
  {
    label: 'src.marketplace-custom-config.AntiguaandBarbuda',
    key: 'AG',
    value: 'AG',
  },
  {
    label: 'src.marketplace-custom-config.Argentina',
    key: 'AR',
    value: 'AR',
  },
  {
    label: 'src.marketplace-custom-config.Armenia',
    key: 'AM',
    value: 'AM',
  },
  {
    label: 'src.marketplace-custom-config.Aruba',
    key: 'AW',
    value: 'AW',
  },
  {
    label: 'src.marketplace-custom-config.Australia',
    key: 'AU',
    value: 'AU',
  },
  {
    label: 'src.marketplace-custom-config.Austria',
    key: 'AT',
    value: 'AT',
  },
  {
    label: 'src.marketplace-custom-config.Azerbaijan',
    key: 'AZ',
    value: 'AZ',
  },
  {
    label: 'src.marketplace-custom-config.Bahamas',
    key: 'BS',
    value: 'BS',
  },
  {
    label: 'src.marketplace-custom-config.Bahrain',
    key: 'BH',
    value: 'BH',
  },
  {
    label: 'src.marketplace-custom-config.Bangladesh',
    key: 'BD',
    value: 'BD',
  },
  {
    label: 'src.marketplace-custom-config.Barbados',
    key: 'BB',
    value: 'BB',
  },
  {
    label: 'src.marketplace-custom-config.Belarus',
    key: 'BY',
    value: 'BY',
  },
  {
    label: 'src.marketplace-custom-config.Belgium',
    key: 'BE',
    value: 'BE',
  },
  {
    label: 'src.marketplace-custom-config.Belize',
    key: 'BZ',
    value: 'BZ',
  },
  {
    label: 'src.marketplace-custom-config.Benin',
    key: 'BJ',
    value: 'BJ',
  },
  {
    label: 'src.marketplace-custom-config.Bermuda',
    key: 'BM',
    value: 'BM',
  },
  {
    label: 'src.marketplace-custom-config.Bhutan',
    key: 'BT',
    value: 'BT',
  },
  {
    label: 'src.marketplace-custom-config.Bolivia',
    key: 'BO',
    value: 'BO',
  },
  {
    label: 'src.marketplace-custom-config.BosniaAndHerzegovina',
    key: 'BA',
    value: 'BA',
  },
  {
    label: 'src.marketplace-custom-config.Botswana',
    key: 'BW',
    value: 'BW',
  },
  {
    label: 'src.marketplace-custom-config.BouvetIsland',
    key: 'BV',
    value: 'BV',
  },
  {
    label: 'src.marketplace-custom-config.Brazil',
    key: 'BR',
    value: 'BR',
  },
  {
    label: 'src.marketplace-custom-config.BritishIndianOceanTerritory',
    key: 'IO',
    value: 'IO',
  },
  {
    label: 'src.marketplace-custom-config.Brunei',
    key: 'BN',
    value: 'BN',
  },
  {
    label: 'src.marketplace-custom-config.Bulgaria',
    key: 'BG',
    value: 'BG',
  },
  {
    label: 'src.marketplace-custom-config.BurkinaFaso',
    key: 'BF',
    value: 'BF',
  },
  {
    label: 'src.marketplace-custom-config.Burundi',
    key: 'BI',
    value: 'BI',
  },
  {
    label: 'src.marketplace-custom-config.Cambodia',
    key: 'KH',
    value: 'KH',
  },
  {
    label: 'src.marketplace-custom-config.Cameroon',
    key: 'CM',
    value: 'CM',
  },
  {
    label: 'src.marketplace-custom-config.Canada',
    key: 'CA',
    value: 'CA',
  },
  {
    label: 'src.marketplace-custom-config.CapeVerde',
    key: 'CV',
    value: 'CV',
  },
  {
    label: 'src.marketplace-custom-config.CaymanIslands',
    key: 'KY',
    value: 'KY',
  },
  {
    label: 'src.marketplace-custom-config.CentralAfricanRepublic',
    key: 'CF',
    value: 'CF',
  },
  {
    label: 'src.marketplace-custom-config.Chad',
    key: 'TD',
    value: 'TD',
  },
  {
    label: 'src.marketplace-custom-config.Chile',
    key: 'CL',
    value: 'CL',
  },
  {
    label: 'src.marketplace-custom-config.China',
    key: 'CN',
    value: 'CN',
  },
  {
    label: 'src.marketplace-custom-config.ChristmasIsland',
    key: 'CX',
    value: 'CX',
  },
  {
    label: 'src.marketplace-custom-config.CocosIslands',
    key: 'CC',
    value: 'CC',
  },
  {
    label: 'src.marketplace-custom-config.Colombia',
    key: 'CO',
    value: 'CO',
  },
  {
    label: 'src.marketplace-custom-config.Comoros',
    key: 'KM',
    value: 'KM',
  },
  {
    label: 'src.marketplace-custom-config.Congo',
    key: 'CG',
    value: 'CG',
  },
  {
    label: 'src.marketplace-custom-config.Congo',
    key: 'CD',
    value: 'CD',
  },
  {
    label: 'src.marketplace-custom-config.CookIslands',
    key: 'CK',
    value: 'CK',
  },
  {
    label: 'src.marketplace-custom-config.CostaRica',
    key: 'CR',
    value: 'CR',
  },
  {
    label: 'src.marketplace-custom-config.CoteDIvoire',
    key: 'CI',
    value: 'CI',
  },
  {
    label: 'src.marketplace-custom-config.Croatia',
    key: 'HR',
    value: 'HR',
  },
  {
    label: 'src.marketplace-custom-config.Cubalabel',
    key: 'CU',
    value: 'CU',
  },
  {
    label: 'src.marketplace-custom-config.Cyprus',
    key: 'CY',
    value: 'CY',
  },
  {
    label: 'src.marketplace-custom-config.CzechRepublic',
    key: 'CZ',
    value: 'CZ',
  },
  {
    label: 'src.marketplace-custom-config.Denmark',
    key: 'DK',
    value: 'DK',
  },
  {
    label: 'src.marketplace-custom-config.Djibouti',
    key: 'DJ',
    value: 'DJ',
  },
  {
    label: 'src.marketplace-custom-config.Dominica',
    key: 'DM',
    value: 'DM',
  },
  {
    label: 'src.marketplace-custom-config.DominicanRepublic',
    key: 'DO',
    value: 'DO',
  },
  {
    label: 'src.marketplace-custom-config.Ecuador',
    key: 'EC',
    value: 'EC',
  },
  {
    label: 'src.marketplace-custom-config.Egypt',
    key: 'EG',
    value: 'EG',
  },
  {
    label: 'src.marketplace-custom-config.ElSalvador',
    key: 'SV',
    value: 'SV',
  },
  {
    label: 'src.marketplace-custom-config.EquatorialGuinea',
    key: 'GQ',
    value: 'GQ',
  },
  {
    label: 'src.marketplace-custom-config.Eritrea',
    key: 'ER',
    value: 'ER',
  },
  {
    label: 'src.marketplace-custom-config.Estonia',
    key: 'EE',
    value: 'EE',
  },
  {
    label: 'src.marketplace-custom-config.Ethiopia',
    key: 'ET',
    value: 'ET',
  },
  {
    label: 'src.marketplace-custom-config.FalklandIslands',
    key: 'FK',
    value: 'FK',
  },
  {
    label: 'src.marketplace-custom-config.FaroeIslands',
    key: 'FO',
    value: 'FO',
  },
  {
    label: 'src.marketplace-custom-config.Fiji',
    key: 'FJ',
    value: 'FJ',
  },
  {
    label: 'src.marketplace-custom-config.Finland',
    key: 'FI',
    value: 'FI',
  },
  {
    label: 'src.marketplace-custom-config.France',
    key: 'FR',
    value: 'FR',
  },
  {
    label: 'src.marketplace-custom-config.FrenchGuiana',
    key: 'GF',
    value: 'GF',
  },
  {
    label: 'src.marketplace-custom-config.FrenchPolynesia',
    key: 'PF',
    value: 'PF',
  },
  {
    label: 'src.marketplace-custom-config.FrenchSouthernTerritories',
    key: 'TF',
    value: 'TF',
  },
  {
    label: 'src.marketplace-custom-config.Gabon',
    key: 'GA',
    value: 'GA',
  },
  {
    label: 'src.marketplace-custom-config.Gambia',
    key: 'GM',
    value: 'GM',
  },
  {
    label: 'src.marketplace-custom-config.Georgia',
    key: 'GE',
    value: 'GE',
  },
  {
    label: 'src.marketplace-custom-config.Germany',
    key: 'DE',
    value: 'DE',
  },
  {
    label: 'src.marketplace-custom-config.Ghana',
    key: 'GH',
    value: 'GH',
  },
  {
    label: 'src.marketplace-custom-config.Gibraltar',
    key: 'GI',
    value: 'GI',
  },
  {
    label: 'src.marketplace-custom-config.Greece',
    key: 'GR',
    value: 'GR',
  },
  {
    label: 'src.marketplace-custom-config.Greenland',
    key: 'GL',
    value: 'GL',
  },
  {
    label: 'src.marketplace-custom-config.Grenada',
    key: 'GD',
    value: 'GD',
  },
  {
    label: 'src.marketplace-custom-config.Guadeloupe',
    key: 'GP',
    value: 'GP',
  },
  {
    label: 'src.marketplace-custom-config.Guam',
    key: 'GU',
    value: 'GU',
  },
  {
    label: 'src.marketplace-custom-config.Guatemala',
    key: 'GT',
    value: 'GT',
  },
  {
    label: 'src.marketplace-custom-config.Guernsey',
    key: 'GG',
    value: 'GG',
  },
  {
    label: 'src.marketplace-custom-config.Guinea',
    key: 'GN',
    value: 'GN',
  },
  {
    label: 'src.marketplace-custom-config.Guinea-Bissau',
    key: 'GW',
    value: 'GW',
  },
  {
    label: 'src.marketplace-custom-config.Guyana',
    key: 'GY',
    value: 'GY',
  },
  {
    label: 'src.marketplace-custom-config.Haiti',
    key: 'HT',
    value: 'HT',
  },
  {
    label: 'src.marketplace-custom-config.HeardIsland',
    key: 'HM',
    value: 'HM',
  },
  {
    label: 'src.marketplace-custom-config.HolySee',
    key: 'VA',
    value: 'VA',
  },
  {
    label: 'src.marketplace-custom-config.Honduras',
    key: 'HN',
    value: 'HN',
  },
  {
    label: 'src.marketplace-custom-config.HongKong',
    key: 'HK',
    value: 'HK',
  },
  {
    label: 'src.marketplace-custom-config.Hungary',
    key: 'HU',
    value: 'HU',
  },
  {
    label: 'src.marketplace-custom-config.Iceland',
    key: 'IS',
    value: 'IS',
  },
  {
    label: 'src.marketplace-custom-config.India',
    key: 'IN',
    value: 'IN',
  },
  {
    label: 'src.marketplace-custom-config.Indonesia',
    key: 'ID',
    value: 'ID',
  },
  {
    label: 'src.marketplace-custom-config.Iran',
    key: 'IR',
    value: 'IR',
  },
  {
    label: 'src.marketplace-custom-config.Iraq',
    key: 'IQ',
    value: 'IQ',
  },
  {
    label: 'src.marketplace-custom-config.Ireland',
    key: 'IE',
    value: 'IE',
  },
  {
    label: 'src.marketplace-custom-config.IsleofMan',
    key: 'IM',
    value: 'IM',
  },
  {
    label: 'src.marketplace-custom-config.Israel',
    key: 'IL',
    value: 'IL',
  },
  {
    label: 'src.marketplace-custom-config.Italy',
    key: 'IT',
    value: 'IT',
  },
  {
    label: 'src.marketplace-custom-config.Jamaica',
    key: 'JM',
    value: 'JM',
  },
  {
    label: 'src.marketplace-custom-config.Japan',
    key: 'JP',
    value: 'JP',
  },
  {
    label: 'src.marketplace-custom-config.Jersey',
    key: 'JE',
    value: 'JE',
  },
  {
    label: 'src.marketplace-custom-config.Jordan',
    key: 'JO',
    value: 'JO',
  },
  {
    label: 'src.marketplace-custom-config.Kazakhstan',
    key: 'KZ',
    value: 'KZ',
  },
  {
    label: 'src.marketplace-custom-config.Kenya',
    key: 'KE',
    value: 'KE',
  },
  {
    label: 'src.marketplace-custom-config.Kiribati',
    key: 'KI',
    value: 'KI',
  },
  {
    label: 'src.marketplace-custom-config.KoreaDemoractic',
    key: 'KP',
    value: 'KP',
  },
  {
    label: 'src.marketplace-custom-config.KoreaRepublic',
    key: 'KR',
    value: 'KR',
  },
  {
    label: 'src.marketplace-custom-config.Kuwait',
    key: 'KW',
    value: 'KW',
  },
  {
    label: 'src.marketplace-custom-config.Kyrgyzstan',
    key: 'KG',
    value: 'KG',
  },
  {
    label: 'src.marketplace-custom-config.LaoPeople',
    key: 'LA',
    value: 'LA',
  },
  {
    label: 'src.marketplace-custom-config.Latvia',
    key: 'LV',
    value: 'LV',
  },
  {
    label: 'src.marketplace-custom-config.Lebanon',
    key: 'LB',
    value: 'LB',
  },
  {
    label: 'src.marketplace-custom-config.Lesotho',
    key: 'LS',
    value: 'LS',
  },
  {
    label: 'src.marketplace-custom-config.Liberia',
    key: 'LR',
    value: 'LR',
  },
  {
    label: 'src.marketplace-custom-config.LibyanArabJamahiriya',
    key: 'LY',
    value: 'LY',
  },
  {
    label: 'src.marketplace-custom-config.Liechtenstein',
    key: 'LI',
    value: 'LI',
  },
  {
    label: 'src.marketplace-custom-config.Lithuania',
    key: 'LT',
    value: 'LT',
  },
  {
    label: 'src.marketplace-custom-config.Luxembourg',
    key: 'LU',
    value: 'LU',
  },
  {
    label: 'src.marketplace-custom-config.Macao',
    key: 'MO',
    value: 'MO',
  },
  {
    label: 'src.marketplace-custom-config.Macedonia',
    key: 'MK',
    value: 'MK',
  },
  {
    label: 'src.marketplace-custom-config.Madagascar',
    key: 'MG',
    value: 'MG',
  },
  {
    label: 'src.marketplace-custom-config.Malawi',
    key: 'MW',
    value: 'MW',
  },
  {
    label: 'src.marketplace-custom-config.Malaysia',
    key: 'MY',
    value: 'MY',
  },
  {
    label: 'src.marketplace-custom-config.Maldives',
    key: 'MV',
    value: 'MV',
  },
  {
    label: 'src.marketplace-custom-config.Mali',
    key: 'ML',
    value: 'ML',
  },
  {
    label: 'src.marketplace-custom-config.Malta',
    key: 'MT',
    value: 'MT',
  },
  {
    label: 'src.marketplace-custom-config.MarshallIslands',
    key: 'MH',
    value: 'MH',
  },
  {
    label: 'src.marketplace-custom-config.Martinique',
    key: 'MQ',
    value: 'MQ',
  },
  {
    label: 'src.marketplace-custom-config.Mauritania',
    key: 'MR',
    value: 'MR',
  },
  {
    label: 'src.marketplace-custom-config.Mauritius',
    key: 'MU',
    value: 'MU',
  },
  {
    label: 'src.marketplace-custom-config.Mayotte',
    key: 'YT',
    value: 'YT',
  },
  {
    label: 'src.marketplace-custom-config.Mexico',
    key: 'MX',
    value: 'MX',
  },
  {
    label: 'src.marketplace-custom-config.Micronesia',
    key: 'FM',
    value: 'FM',
  },
  {
    label: 'src.marketplace-custom-config.Moldova',
    key: 'MD',
    value: 'MD',
  },
  {
    label: 'src.marketplace-custom-config.Monaco',
    key: 'MC',
    value: 'MC',
  },
  {
    label: 'src.marketplace-custom-config.Mongolia',
    key: 'MN',
    value: 'MN',
  },
  {
    label: 'src.marketplace-custom-config.Montserrat',
    key: 'MS',
    value: 'MS',
  },
  {
    label: 'src.marketplace-custom-config.Morocco',
    key: 'MA',
    value: 'MA',
  },
  {
    label: 'src.marketplace-custom-config.Mozambique',
    key: 'MZ',
    value: 'MZ',
  },
  {
    label: 'src.marketplace-custom-config.Myanmar',
    key: 'MM',
    value: 'MM',
  },
  {
    label: 'src.marketplace-custom-config.Namibia',
    key: 'NA',
    value: 'NA',
  },
  {
    label: 'src.marketplace-custom-config.Nauru',
    key: 'NR',
    value: 'NR',
  },
  {
    label: 'src.marketplace-custom-config.Nepal',
    key: 'NP',
    value: 'NP',
  },
  {
    label: 'src.marketplace-custom-config.Netherlands',
    key: 'NL',
    value: 'NL',
  },
  {
    label: 'src.marketplace-custom-config.NetherlandsAntilles',
    key: 'AN',
    value: 'AN',
  },
  {
    label: 'src.marketplace-custom-config.ncLabel',
    key: 'NC',
    value: 'NC',
  },
  {
    label: 'src.marketplace-custom-config.NewZealand',
    key: 'NZ',
    value: 'NZ',
  },
  {
    label: 'src.marketplace-custom-config.Nicaragua',
    key: 'NI',
    value: 'NI',
  },
  {
    label: 'src.marketplace-custom-config.Niger',
    key: 'NE',
    value: 'NE',
  },
  {
    label: 'src.marketplace-custom-config.Nigeria',
    key: 'NG',
    value: 'NG',
  },
  {
    label: 'src.marketplace-custom-config.Niue',
    key: 'NU',
    value: 'NU',
  },
  {
    label: 'src.marketplace-custom-config.NorfolkIsland',
    key: 'NF',
    value: 'NF',
  },
  {
    label: 'src.marketplace-custom-config.NorthernMarianaIslands',
    key: 'MP',
    value: 'MP',
  },
  {
    label: 'src.marketplace-custom-config.Norway',
    key: 'NO',
    value: 'NO',
  },
  {
    label: 'src.marketplace-custom-config.Oman',
    key: 'OM',
    value: 'OM',
  },
  {
    label: 'src.marketplace-custom-config.Pakistan',
    key: 'PK',
    value: 'PK',
  },
  {
    label: 'src.marketplace-custom-config.Palau',
    key: 'PW',
    value: 'PW',
  },
  {
    label: 'src.marketplace-custom-config.Palestinian',
    key: 'PS',
    value: 'PS',
  },
  {
    label: 'src.marketplace-custom-config.Panama',
    key: 'PA',
    value: 'PA',
  },
  {
    label: 'src.marketplace-custom-config.PapuaNewGuinea',
    key: 'PG',
    value: 'PG',
  },
  {
    label: 'src.marketplace-custom-config.Paraguay',
    key: 'PY',
    value: 'PY',
  },
  {
    label: 'src.marketplace-custom-config.Peru',
    key: 'PE',
    value: 'PE',
  },
  {
    label: 'src.marketplace-custom-config.Philippines',
    key: 'PH',
    value: 'PH',
  },
  {
    label: 'src.marketplace-custom-config.Pitcairn',
    key: 'PN',
    value: 'PN',
  },
  {
    label: 'src.marketplace-custom-config.Poland',
    key: 'PL',
    value: 'PL',
  },
  {
    label: 'src.marketplace-custom-config.Portugal',
    key: 'PT',
    value: 'PT',
  },
  {
    label: 'src.marketplace-custom-config.PuertoRico',
    key: 'PR',
    value: 'PR',
  },
  {
    label: 'src.marketplace-custom-config.Qatar',
    key: 'QA',
    value: 'QA',
  },
  {
    label: 'src.marketplace-custom-config.Reunion',
    key: 'RE',
    value: 'RE',
  },
  {
    label: 'src.marketplace-custom-config.Romania',
    key: 'RO',
    value: 'RO',
  },
  {
    label: 'src.marketplace-custom-config.RussianFederation',
    key: 'RU',
    value: 'RU',
  },
  {
    label: 'src.marketplace-custom-config.RWANDA',
    key: 'RW',
    value: 'RW',
  },
  {
    label: 'src.marketplace-custom-config.SaintHelena',
    key: 'SH',
    value: 'SH',
  },
  {
    label: 'src.marketplace-custom-config.SaintKittsandNevis',
    key: 'KN',
    value: 'KN',
  },
  {
    label: 'src.marketplace-custom-config.SaintLucia',
    key: 'LC',
    value: 'LC',
  },
  {
    label: 'src.marketplace-custom-config.SaintPierreandMiquelon',
    key: 'PM',
    value: 'PM',
  },
  {
    label: 'src.marketplace-custom-config.SaintVincentandtheGrenadines',
    key: 'VC',
    value: 'VC',
  },
  {
    label: 'src.marketplace-custom-config.Samoa',
    key: 'WS',
    value: 'WS',
  },
  {
    label: 'src.marketplace-custom-config.SanMarino',
    key: 'SM',
    value: 'SM',
  },
  {
    label: 'src.marketplace-custom-config.SaoTomeandPrincipe',
    key: 'ST',
    value: 'ST',
  },
  {
    label: 'src.marketplace-custom-config.SaudiArabia',
    key: 'SA',
    value: 'SA',
  },
  {
    label: 'src.marketplace-custom-config.Senegal',
    key: 'SN',
    value: 'SN',
  },
  {
    label: 'src.marketplace-custom-config.SerbiaandMontenegro',
    key: 'RS',
    value: 'RS',
  },
  {
    label: 'src.marketplace-custom-config.Seychelles',
    key: 'SC',
    value: 'SC',
  },
  {
    label: 'src.marketplace-custom-config.SierraLeone',
    key: 'SL',
    value: 'SL',
  },
  {
    label: 'src.marketplace-custom-config.Singapore',
    key: 'SG',
    value: 'SG',
  },
  {
    label: 'src.marketplace-custom-config.Slovakia',
    key: 'SK',
    value: 'SK',
  },
  {
    label: 'src.marketplace-custom-config.Slovenia',
    key: 'SI',
    value: 'SI',
  },
  {
    label: 'src.marketplace-custom-config.SolomonIslands',
    key: 'SB',
    value: 'SB',
  },
  {
    label: 'src.marketplace-custom-config.Somalia',
    key: 'SO',
    value: 'SO',
  },
  {
    label: 'src.marketplace-custom-config.SouthAfrica',
    key: 'ZA',
    value: 'ZA',
  },
  {
    label: 'src.marketplace-custom-config.SouthGeorgia',
    key: 'GS',
    value: 'GS',
  },
  {
    label: 'src.marketplace-custom-config.Spain',
    key: 'ES',
    value: 'ES',
  },
  {
    label: 'src.marketplace-custom-config.SriLanka',
    key: 'LK',
    value: 'LK',
  },
  {
    label: 'src.marketplace-custom-config.Sudan',
    key: 'SD',
    value: 'SD',
  },
  {
    label: 'src.marketplace-custom-config.Suriname',
    key: 'SR',
    value: 'SR',
  },
  {
    label: 'src.marketplace-custom-config.SvalbardandJanMayen',
    key: 'SJ',
    value: 'SJ',
  },
  {
    label: 'src.marketplace-custom-config.Swaziland',
    key: 'SZ',
    value: 'SZ',
  },
  {
    label: 'src.marketplace-custom-config.Sweden',
    key: 'SE',
    value: 'SE',
  },
  {
    label: 'src.marketplace-custom-config.Switzerland',
    key: 'CH',
    value: 'CH',
  },
  {
    label: 'src.marketplace-custom-config.SyrianArabRepublic',
    key: 'SY',
    value: 'SY',
  },
  {
    label: 'src.marketplace-custom-config.TaiwanProvinceofChina',
    key: 'TW',
    value: 'TW',
  },
  {
    label: 'src.marketplace-custom-config.Tajikistan',
    key: 'TJ',
    value: 'TJ',
  },
  {
    label: 'src.marketplace-custom-config.Tanzania',
    key: 'TZ',
    value: 'TZ',
  },
  {
    label: 'src.marketplace-custom-config.Thailand',
    key: 'TH',
    value: 'TH',
  },
  {
    label: 'src.marketplace-custom-config.Timor-Leste',
    key: 'TL',
    value: 'TL',
  },
  {
    label: 'src.marketplace-custom-config.Togo',
    key: 'TG',
    value: 'TG',
  },
  {
    label: 'src.marketplace-custom-config.Tokelau',
    key: 'TK',
    value: 'TK',
  },
  {
    label: 'src.marketplace-custom-config.Tonga',
    key: 'TO',
    value: 'TO',
  },
  {
    label: 'src.marketplace-custom-config.TrinidadTobago',
    key: 'TT',
    value: 'TT',
  },
  {
    label: 'src.marketplace-custom-config.Tunisia',
    key: 'TN',
    value: 'TN',
  },
  {
    label: 'src.marketplace-custom-config.Turkey',
    key: 'TR',
    value: 'TR',
  },
  {
    label: 'src.marketplace-custom-config.Turkmenistan',
    key: 'TM',
    value: 'TM',
  },
  {
    label: 'src.marketplace-custom-config.TurksandCaicos',
    key: 'TC',
    value: 'TC',
  },
  {
    label: 'src.marketplace-custom-config.Tuvalu',
    key: 'TV',
    value: 'TV',
  },
  {
    label: 'src.marketplace-custom-config.Uganda',
    key: 'UG',
    value: 'UG',
  },
  {
    label: 'src.marketplace-custom-config.Ukraine',
    key: 'UA',
    value: 'UA',
  },
  {
    label: 'src.marketplace-custom-config.UnitedArabEmirates',
    key: 'AE',
    value: 'AE',
  },
  {
    label: 'src.marketplace-custom-config.UnitedKingdom',
    key: 'GB',
    value: 'GB',
  },
  {
    label: 'src.marketplace-custom-config.UnitedStates',
    key: 'US',
    value: 'US',
  },
  {
    label: 'src.marketplace-custom-config.UnitedStatesMinorOutlyingIslands',
    key: 'UM',
    value: 'UM',
  },
  {
    label: 'src.marketplace-custom-config.Uruguay',
    key: 'UY',
    value: 'UY',
  },
  {
    label: 'src.marketplace-custom-config.Uzbekistan',
    key: 'UZ',
    value: 'UZ',
  },
  {
    label: 'src.marketplace-custom-config.Vanuatu',
    key: 'VU',
    value: 'VU',
  },
  {
    label: 'src.marketplace-custom-config.Venezuela',
    key: 'VE',
    value: 'VE',
  },
  {
    label: 'src.marketplace-custom-config.VietNam',
    key: 'VN',
    value: 'VN',
  },
  {
    label: 'src.marketplace-custom-config.VirginIslandsBritish',
    key: 'VG',
    value: 'VG',
  },
  {
    label: 'src.marketplace-custom-config.VirginIslandsUS',
    key: 'VI',
    value: 'VI',
  },
  {
    label: 'src.marketplace-custom-config.WallisFutuna',
    key: 'WF',
    value: 'WF',
  },
  {
    label: 'src.marketplace-custom-config.WesternSahara',
    key: 'EH',
    value: 'EH',
  },
  {
    label: 'src.marketplace-custom-config.Yemen',
    key: 'YE',
    value: 'YE',
  },
  {
    label: 'src.marketplace-custom-config.Zambia',
    key: 'ZM',
    value: 'ZM',
  },
  {
    label: 'src.marketplace-custom-config.Zimbabwe',
    key: 'ZW',
    value: 'ZW',
  },
];
export const bedroomOptions = [
  { label: 'src.marketplace-custom-config.yesLabel', key: 'yes' },
  { label: 'src.marketplace-custom-config.noTheyAreAllShared', key: 'no' },
];
export const privateTour = [{ label: 'Yes', key: 'yes' }, { label: 'No', key: 'no' }];
export const customOptions = [
  { label: 'src.marketplace-custom-config.yesLabel', key: 'yes', value: 'yes' },
  { label: 'src.marketplace-custom-config.noLabel', key: 'no', value: 'no' },
];
export const qualifiedTeacherOptions = [
  { label: 'src.marketplace-custom-config.yesLabel', key: 'yes', value: 'yes' },
  { label: 'src.marketplace-custom-config.noLabel', key: 'no', value: 'no' },
];
export const amenitiesOptions = [
  { label: 'src.marketplace-custom-config.Wifi', value: 'wifi', key: 'wifi' },
  {
    label: 'src.marketplace-custom-config.DedicatedWorkspace',
    value: 'dedicated_workspace',
    key: 'dedicated_workspace',
  },
  { label: 'src.marketplace-custom-config.TV', value: 'tv', key: 'tv' },
  { label: 'src.marketplace-custom-config.Kitchen', value: 'kitchen', key: 'kitchen' },
  { label: 'src.marketplace-custom-config.Breakfast', value: 'breakfast', key: 'breakfast' },
  {
    label: 'src.marketplace-custom-config.Coffeeandteafacilities',
    value: 'coffee_tea_facilities',
    key: 'coffee_tea_facilities',
  },
  {
    label: 'src.marketplace-custom-config.basicsOfCooking',
    value: 'basics_for_cooking',
    key: 'basics_for_cooking',
  },
  {
    label: 'src.marketplace-custom-config.Shampooandshowergel',
    value: 'shampoo_and_shower_gel',
    key: 'shampoo_and_shower_gel',
  },
  {
    label: 'src.marketplace-custom-config.Airconditioning',
    value: 'air_conditioning',
    key: 'air_conditioning',
  },
  { label: 'src.marketplace-custom-config.Heating', value: 'heating', key: 'heating' },
  {
    label: 'src.marketplace-custom-config.WashingMachine',
    value: 'washing_machine',
    key: 'washing_machine',
  },
  { label: 'src.marketplace-custom-config.Hairdryer', value: 'hair_dryer', key: 'hair_dryer' },
  { label: 'src.marketplace-custom-config.Iron', value: 'iron', key: 'iron' },
  {
    label: 'src.marketplace-custom-config.Petsallowed',
    value: 'pets_allowed',
    key: 'pets_allowed',
  },
  { label: 'src.marketplace-custom-config.Bedlinen', value: 'bed_linen', key: 'bed_linen' },
  { label: 'src.marketplace-custom-config.Towels', value: 'towels', key: 'towels' },
  // { label: 'Qualified teachers', value: 'qualified_teachers', key: 'qualified_teachers' },
  // { label: 'Activities provided', value: 'activities_provided', key: 'activities_provided' },
];
export const serviceOptions = [
  {
    label: 'src.marketplace-custom-config.PickupDropoff',
    value: 'pick_up_drop_off',
    key: 'pick_up_drop_off',
  },
  { label: 'src.marketplace-custom-config.Halfboard', value: 'half_board', key: 'half_board' },
  { label: 'src.marketplace-custom-config.Fullboard', value: 'full_board', key: 'full_board' },
  {
    label: 'src.marketplace-custom-config.Specialdiet',
    value: 'special_diet',
    key: 'special_diet',
  },
];
export const stayOptions = [
  {
    label: 'src.marketplace-custom-config.Onlymeasaguestathome',
    value: 'only_me_as_a_guest',
    key: 'only_me_as_a_guest',
  },
  {
    label: 'No other guests will speak same language as you',
    value: 'no_other_guests_will_speak_same_language_as_you',
    key: 'no_other_guests_will_speak_same_language_as_you',
  },
];
export const categoryOptions = [
  {
    label: 'src.marketplace-custom-config.lingoActivity',
    value: 'activity',
    key: 'activity',
    type: 'src.marketplace-custom-config.lingoActivity',
  },
  {
    label: 'src.marketplace-custom-config.categoryTeacherLabel',
    value: 'category1',
    key: 'category1',
    type: 'src.marketplace-custom-config.categoryTeacherType',
  },
  {
    label: 'src.marketplace-custom-config.privateTour',
    value: 'private_tours',
    key: 'private_tours',
    type: 'src.marketplace-custom-config.privateTourType',
  },
];

export const languageOptions = [
  { label: 'src.marketplace-custom-config.English', value: 'en', key: 'en' },
  { label: 'src.marketplace-custom-config.Spanish', value: 'es', key: 'es' },
  { label: 'src.marketplace-custom-config.French', value: 'fr', key: 'fr' },
  { label: 'src.marketplace-custom-config.German', value: 'de', key: 'de' },
  { label: 'src.marketplace-custom-config.Italian', value: 'it', key: 'it' },
  { label: 'src.marketplace-custom-config.Portuguese', value: 'pt', key: 'pt' },
  { label: 'src.marketplace-custom-config.Chinese', value: 'zh', key: 'zh' },
  { label: 'src.marketplace-custom-config.Japanese', value: 'ja', key: 'ja' },
  { label: 'src.marketplace-custom-config.Arabic', value: 'ar', key: 'ar' },
  { label: 'src.marketplace-custom-config.Armenian', value: 'hy', key: 'hy' },
  { label: 'src.marketplace-custom-config.Basque', value: 'eu', key: 'eu' },
  { label: 'src.marketplace-custom-config.Belarusian', value: 'be', key: 'be' },
  { label: 'src.marketplace-custom-config.Bengali', value: 'bn', key: 'bn' },
  { label: 'src.marketplace-custom-config.Bulgarian', value: 'bg', key: 'bg' },
  { label: 'src.marketplace-custom-config.Catalan', value: 'ca', key: 'ca' },
  { label: 'src.marketplace-custom-config.Croatian', value: 'hr', key: 'hr' },
  { label: 'src.marketplace-custom-config.Czech', value: 'cs', key: 'cs' },
  { label: 'src.marketplace-custom-config.Danish', value: 'da', key: 'da' },
  { label: 'src.marketplace-custom-config.Dutch', value: 'nl', key: 'nl' },
  { label: 'src.marketplace-custom-config.Finnish', value: 'fi', key: 'fi' },
  { label: 'src.marketplace-custom-config.Galician', value: 'gl', key: 'gl' },
  { label: 'src.marketplace-custom-config.Georgian', value: 'ka', key: 'ka' },
  { label: 'src.marketplace-custom-config.Greek', value: 'el', key: 'el' },
  { label: 'src.marketplace-custom-config.Hindi', value: 'hi', key: 'hi' },
  { label: 'src.marketplace-custom-config.Hebrew', value: 'he', key: 'he' },
  { label: 'src.marketplace-custom-config.Hungarian', value: 'hu', key: 'hu' },
  { label: 'src.marketplace-custom-config.Icelandic', value: 'is', key: 'is' },
  { label: 'src.marketplace-custom-config.IrishGaelic', value: 'ga', key: 'ga' },
  { label: 'src.marketplace-custom-config.Indonesian', value: 'id', key: 'id' },
  { label: 'src.marketplace-custom-config.Khmer', value: 'km', key: 'km' },
  { label: 'src.marketplace-custom-config.Korean', value: 'ko', key: 'ko' },
  { label: 'src.marketplace-custom-config.Kurdish', value: 'ku', key: 'ku' },
  { label: 'src.marketplace-custom-config.Latin', value: 'la', key: 'la' },
  { label: 'src.marketplace-custom-config.Lithuanian', value: 'lt', key: 'lt' },
  { label: 'src.marketplace-custom-config.Malay', value: 'ms', key: 'ms' },
  { label: 'src.marketplace-custom-config.Nepali', value: 'ne', key: 'ne' },
  { label: 'src.marketplace-custom-config.Norwegian', value: 'no', key: 'no' },
  { label: 'src.marketplace-custom-config.Persian', value: 'fa', key: 'fa' },
  { label: 'src.marketplace-custom-config.Polish', value: 'pl', key: 'pl' },
  { label: 'src.marketplace-custom-config.Punjabi', value: 'pa', key: 'pa' },
  { label: 'src.marketplace-custom-config.Quechua', value: 'qu', key: 'qu' },
  { label: 'src.marketplace-custom-config.Romanian', value: 'ro', key: 'ro' },
  { label: 'src.marketplace-custom-config.Russian', value: 'ru', key: 'ru' },
  { label: 'src.marketplace-custom-config.Sanskrit', value: 'sa', key: 'sa' },
  { label: 'src.marketplace-custom-config.ScottishGaelic', value: 'gd', key: 'gd' },
  { label: 'src.marketplace-custom-config.Serbian', value: 'sr', key: 'sr' },
  { label: 'src.marketplace-custom-config.Slovak', value: 'sk', key: 'sk' },
  { label: 'src.marketplace-custom-config.Slovenian', value: 'sl', key: 'sl' },
  { label: 'src.marketplace-custom-config.Swahili', value: 'sw', key: 'sw' },
  { label: 'src.marketplace-custom-config.Swedish', value: 'sv', key: 'sv' },
  { label: 'src.marketplace-custom-config.Tagalog', value: 'tl', key: 'tl' },
  { label: 'src.marketplace-custom-config.Tamil', value: 'ta', key: 'ta' },
  { label: 'src.marketplace-custom-config.Telugu', value: 'te', key: 'te' },
  { label: 'src.marketplace-custom-config.Tibetan', value: 'bo', key: 'bo' },
  { label: 'src.marketplace-custom-config.Thai', value: 'th', key: 'th' },
  { label: 'src.marketplace-custom-config.Turkish', value: 'tr', key: 'tr' },
  { label: 'src.marketplace-custom-config.Ukranian', value: 'uk', key: 'uk' },
  { label: 'src.marketplace-custom-config.Urdu', value: 'ur', key: 'ur' },
  { label: 'src.marketplace-custom-config.Vietnamese', value: 'vi', key: 'vi' },
  { label: 'src.marketplace-custom-config.Welsh', value: 'cy', key: 'cy' },
];
export const levelOptions = [
  { label: 'src.marketplace-custom-config.A1', value: 'A1', key: 'A1' },
  { label: 'src.marketplace-custom-config.A2', value: 'A2', key: 'A2' },
  { label: 'src.marketplace-custom-config.B1', value: 'B1', key: 'B1' },
  { label: 'src.marketplace-custom-config.B2', value: 'B2', key: 'B2' },
  { label: 'src.marketplace-custom-config.C1', value: 'C1', key: 'C1' },
  { label: 'src.marketplace-custom-config.C2', value: 'C2', key: 'C2' },
  { label: 'src.marketplace-custom-config.Native', value: 'Native', key: 'Native' },
];
export const privateSelectedOptions = [
  {
    subHeading: '',
    label: 'src.marketplace-custom-config.Justoneroom',

    value: 'one_room',
    key: 'one_room',
  },
  {
    label: 'src.marketplace-custom-config.Morethanoneroom',
    subHeading: 'src.marketplace-custom-config.moreThenOneSubheading',
    value: 'more_than_one_place',
    key: 'more_than_one_place',
  },
  {
    label: 'src.marketplace-custom-config.MorethanoneroomLabel',
    subHeading: 'src.marketplace-custom-config.moreThanOnePlaceMaySubHeading',
    value: 'more_than_one_place_may',
    key: 'more_than_one_place_may',
  },
];
export const TypeOfAcommodationOptions = [
  {
    label: 'src.marketplace-custom-config.sharedRoom',
    subHeading: 'src.marketplace-custom-config.sharedRoomSubheading',
    value: 'shared_room',
    key: 'shared_room',
  },
  {
    subHeading: 'src.marketplace-custom-config.privateRoomSubHeading',
    label: 'src.marketplace-custom-config.privateRoom',
    value: 'private_room',
    key: 'private_room',
  },
  {
    subHeading: 'src.marketplace-custom-config.entirePlaceSubheading',
    label: 'src.marketplace-custom-config.EntirePlaceLabel',
    value: 'entire_place',
    key: 'entire_place',
  },
];
export const typeOfPlaceOptions = [
  {
    label: 'src.marketplace-custom-config.sharedRoomTypeOfPlace',
    value: 'shared_room',
    key: 'shared_room',
  },
  {
    label: 'src.marketplace-custom-config.entirePlaceTypeOfPlace',
    value: 'entire_place',
    key: 'entire_place',
  },
  {
    label: 'src.marketplace-custom-config.privateRoomTypeOfPlace',
    value: 'private_room',
    key: 'private_room',
  },
];

export const paymentMethods = [
  {
    label: 'src.marketplace-custom-config.Creditcard',
    key: 'credit_card',
    infoText: 'src.marketplace-custom-config.creditCardInfo',
  },
  {
    label: 'src.marketplace-custom-config.cashArrival',
    key: 'cash_on_arrival',
    infoText: 'src.marketplace-custom-config.cashArrivalInfo',
  },
];

export const personsFilter = [
  {
    label: 'src.marketplace-custom-config.1',
    key: '1',
  },
  //upto 15
  {
    label: 'src.marketplace-custom-config.2',
    key: '2',
  },
  {
    label: 'src.marketplace-custom-config.3',
    key: '3',
  },
  {
    label: 'src.marketplace-custom-config.4',
    key: '4',
  },
  {
    label: 'src.marketplace-custom-config.5',
    key: '5',
  },
  {
    label: 'src.marketplace-custom-config.6',
    key: '6',
  },
  {
    label: 'src.marketplace-custom-config.7',
    key: '7',
  },
  {
    label: 'src.marketplace-custom-config.8',
    key: '8',
  },
  {
    label: 'src.marketplace-custom-config.9',
    key: '9',
  },
  {
    label: 'src.marketplace-custom-config.10',
    key: '10',
  },
  {
    label: 'src.marketplace-custom-config.11',
    key: '11',
  },
  {
    label: 'src.marketplace-custom-config.12',
    key: '12',
  },
  {
    label: 'src.marketplace-custom-config.13',
    key: '13',
  },
  {
    label: 'src.marketplace-custom-config.14',
    key: '14',
  },
  {
    label: 'src.marketplace-custom-config.15',
    key: '15',
  },
];
export const additionalPriceOptions = [
  {
    label: '1hr lesson',
    name: 'hr_extra_session',
    id: 'hr_extra_session',
    value: 'true',
    inputPlaceholder: 'price/hour',
  },
  // {
  //   label: 'Full board',
  //   name: 'full_board',
  //   id: 'full_board',
  //   value: 'true',
  //   inputPlaceholder: 'price/day',
  // },
  // {
  //   label: 'Cleaning fee',
  //   name: 'cleaning_fee',
  //   id: 'cleaning_fee',
  //   value: 'true',
  //   inputPlaceholder: 'price/stay',
  // },
  // {
  //   label: 'Special diet',
  //   name: 'special_diet',
  //   id: 'special_diet',
  //   value: 'true',
  //   inputPlaceholder: 'price/day',
  // },
  // {
  //   label: 'Pet fee',
  //   name: 'pet_fee',
  //   id: 'pet_fee',
  //   value: 'true',
  //   inputPlaceholder: 'price/stay',
  // },
  // {
  //   label: 'Pick up',
  //   name: 'pick_up',
  //   id: 'pick_up',
  //   value: 'true',
  //   inputPlaceholder: 'price',
  // },
  // {
  //   label: 'Half board',
  //   name: 'half_board',
  //   id: 'half_board',
  //   value: 'true',
  //   inputPlaceholder: 'price/day',
  // },
  // {
  //   label: 'Drop off',
  //   name: 'drop_off',
  //   id: 'drop_off',
  //   value: 'true',
  //   inputPlaceholder: 'price',
  // },
];
export const wifiFilter = [
  {
    label: 'src.marketplace-custom-config.wifiFilter',
    key: true,
  },
];
export const activitiesFilter = [
  {
    label: 'src.marketplace-custom-config.ActivitiesFilter',
    key: true,
  },
];
export const filters = [
  {
    id: 'dates',
    label: 'src.marketplace-custom-config.dateFilter',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'src.marketplace-custom-config.PriceFilter',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'src.marketplace-custom-config.KeywordFilter',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  {
    id: 'category',
    label: 'src.marketplace-custom-config.CategoryFilter',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...categoryOptions],
    },
  },
  {
    id: 'amenities',
    label: 'src.marketplace-custom-config.AmenitiesFilter',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...amenitiesOptions],
    },
  },
  {
    id: 'personsFilter',
    label: 'src.marketplace-custom-config.StudentsFilter',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_guestFilter'],
    config: {
      // searchMode: 'has_any',
      options: [...personsFilter],
    },
  },
  {
    id: 'level',
    label: 'src.marketplace-custom-config.LevelFilters',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_level'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...levelOptions]?.filter(item => item.key !== 'Native'),
    },
  },
  {
    id: 'stay',
    label: 'src.marketplace-custom-config.StayFilter',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_stay'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...stayOptions],
    },
  },
  {
    id: 'type_of_place',
    label: 'src.marketplace-custom-config.TypeofplaceFilter',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...typeOfPlaceOptions],
    },
  },
  // {
  //   id: 'service',
  //   label: 'Service',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_service'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [...serviceOptions],
  //   },
  // },
  // {
  //   id: 'Languages',
  //   label: 'Language',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_Languages'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [...languageOptions],
  //   },
  // },
  {
    id: 'wifi',
    label: 'src.marketplace-custom-config.wifiFilterLabel',
    type: 'SelectSingleFilter',
    group: 'primary',
    singleFilter: true,
    queryParamNames: ['pub_wifi'],
    config: {
      options: [...wifiFilter],
    },
  },
  {
    id: 'activities',
    label: 'src.marketplace-custom-config.ActivitiesFilterLabel',
    type: 'SelectSingleFilter',
    group: 'primary',
    singleFilter: true,
    queryParamNames: ['pub_activitiesSelected'],
    config: {
      options: [...activitiesFilter],
    },
  },
  {
    id: 'dedicatedWorkspace',
    label: 'src.marketplace-custom-config.DedicatedworkspaceFilter',
    type: 'SelectSingleFilter',
    group: 'primary',
    singleFilter: true,
    queryParamNames: ['pub_dedicatedWorkspace'],
    config: {
      options: [
        {
          label: 'src.marketplace-custom-config.DedicatedworkspaceLabelOption',
          key: true,
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'src.marketplace-custom-config.newest' },
    { key: '-createdAt', label: 'src.marketplace-custom-config.Oldest' },
    { key: '-price', label: 'src.marketplace-custom-config.Lowestprice' },
    { key: 'price', label: 'src.marketplace-custom-config.Highestprice' },
    { key: 'meta_rating,', label: 'src.marketplace-custom-config.HighestRating' },
    { key: '-meta_rating', label: 'src.marketplace-custom-config.LowestRating' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'src.marketplace-custom-config.relevanceLabel',
    },
  ],
};
