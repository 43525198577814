import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionHero.module.css';
import image from '../../assets/Images/hero.png';
const SectionHero = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.root}>
      <img className={css.heroImage} src={image} alt="Hero" />
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.headingLineOne' })}
        </h1>
        <h1 className={css.heroMainTitle}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.headingLineTwo' })}
        </h1>
        <h2 className={css.heroSubTitle}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.newSubheadingOne' })}
          <br />
          {intl.formatMessage({ id: 'SectionHero.SectionHero.newSubheadingTwo' })}
        </h2>
      </div>
      <div className={css.bottomContainer}>
        <h2 className={css.bottomHeading}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.bottomHeading' })}
        </h2>
        <p className={css.bottomText}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.bottomSubheadingOne' })}
        </p>
        <p className={css.bottomText}>
          {intl.formatMessage({ id: 'SectionHero.SectionHero.bottomSubheadingTwo' })}
        </p>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
