import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

const LISTINGS_FETCH_REQUEST = 'app/LandingPage/LISTINGS_FETCH_REQUEST';
const LISTINGS_FETCH_SUCCESS = 'app/LandingPage/LISTINGS_FETCH_SUCCESS';
const LISTINGS_FETCH_ERROR = 'app/LandingPage/LISTINGS_FETCH_ERROR';

const ACTIVITIES_FETCH_REQUEST = 'app/LandingPage/ACTIVITIES_FETCH_REQUEST';
const ACTIVITIES_FETCH_SUCCESS = 'app/LandingPage/ACTIVITIES_FETCH_SUCCESS';
const ACTIVITIES_FETCH_ERROR = 'app/LandingPage/ACTIVITIES_FETCH_ERROR';

const PRIVATE_TOURS_FETCH_REQUEST = 'app/LandingPage/PRIVATE_TOURS_FETCH_REQUEST';
const PRIVATE_TOURS_FETCH_SUCCESS = 'app/LandingPage/PRIVATE_TOURS_FETCH_SUCCESS';
const PRIVATE_TOURS_FETCH_ERROR = 'app/LandingPage/PRIVATE_TOURS_FETCH_ERROR';

const initialState = {
  listingsLoading: false,
  listingIds: null,
  listingsError: null,
  activitiesLoading: false,
  activitiesError: null,
  activityIds: null,
  privateToursLoading: false,
  privateToursError: null,
  privateTourIds: null,
};
const resultIds = data => data.map(l => l.id);
function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LISTINGS_FETCH_REQUEST:
      return { ...state, listingsLoading: true, listingsError: null };
    case LISTINGS_FETCH_SUCCESS:
      return { ...state, listingsLoading: false, listingIds: resultIds(payload) };
    case LISTINGS_FETCH_ERROR:
      return { ...state, listingsLoading: false, listingsError: payload };
    case ACTIVITIES_FETCH_REQUEST:
      return { ...state, activitiesLoading: true, activitiesError: null };
    case ACTIVITIES_FETCH_SUCCESS:
      return { ...state, activitiesLoading: false, activityIds: resultIds(payload) };
    case ACTIVITIES_FETCH_ERROR:
      return { ...state, activitiesLoading: false, activitiesError: payload };
    case PRIVATE_TOURS_FETCH_REQUEST:
      return { ...state, privateToursLoading: true, privateToursError: null };
    case PRIVATE_TOURS_FETCH_SUCCESS:
      return { ...state, privateToursLoading: false, privateTourIds: resultIds(payload) };
    case PRIVATE_TOURS_FETCH_ERROR:
      return { ...state, privateToursLoading: false, privateToursError: payload };
    default:
      return state;
  }
}
export default reducer;

export const fetchListingsRequest = () => ({ type: LISTINGS_FETCH_REQUEST });
export const fetchListingsSuccess = payload => ({ type: LISTINGS_FETCH_SUCCESS, payload });
export const fetchListingsError = error => ({ type: LISTINGS_FETCH_ERROR, payload: error });
export const fetchActivitiesRequest = () => ({ type: ACTIVITIES_FETCH_REQUEST });
export const fetchActivitiesSuccess = payload => ({ type: ACTIVITIES_FETCH_SUCCESS, payload });
export const fetchActivitiesError = error => ({ type: ACTIVITIES_FETCH_ERROR, payload: error });
export const fetchPrivateToursRequest = () => ({ type: PRIVATE_TOURS_FETCH_REQUEST });
export const fetchPrivateToursSuccess = payload => ({ type: PRIVATE_TOURS_FETCH_SUCCESS, payload });
export const fetchPrivateToursError = error => ({
  type: PRIVATE_TOURS_FETCH_ERROR,
  payload: error,
});

export const getPrivateTours = () => async (dispatch, getState, sdk) => {
  dispatch(fetchPrivateToursRequest());
  try {
    const response = await sdk.listings.query({
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'availabilityPlan'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
      perPage: 8,
      pub_category: 'private_tours',
      meta_featured: true,
      // sort: 'createdAt',
      sort: 'meta_priority',
    });
    dispatch(addMarketplaceEntities(response));
    const denormalisedResponse = denormalisedResponseEntities(response);
    dispatch(fetchPrivateToursSuccess(denormalisedResponse));

    return response;
  } catch (e) {
    console.log(e);
    dispatch(fetchPrivateToursError(storableError(e)));
  }
};

export const getActivities = () => async (dispatch, getState, sdk) => {
  dispatch(fetchActivitiesRequest());
  try {
    const response = await sdk.listings.query({
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'availabilityPlan'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
      perPage: 8,
      pub_category: 'activity',
      meta_featured: true,
      // sort: 'createdAt',
      sort: 'meta_priority',
    });
    dispatch(addMarketplaceEntities(response));
    const denormalisedResponse = denormalisedResponseEntities(response);
    dispatch(fetchActivitiesSuccess(denormalisedResponse));

    return response;
  } catch (e) {
    console.log(e);
    dispatch(fetchActivitiesError(storableError(e)));
  }
};
export const getAllListings = () => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());
  try {
    const response = await sdk.listings.query({
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'availabilityPlan'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 1,
      perPage: 8,
      pub_category: 'category1',
      meta_featured: true,
      sort: 'meta_priority',
      // sort: 'createdAt',
    });
    dispatch(addMarketplaceEntities(response));
    const denormalisedResponse = denormalisedResponseEntities(response);
    dispatch(fetchListingsSuccess(denormalisedResponse));

    return response;
  } catch (e) {
    console.log(e);
    dispatch(fetchListingsError(storableError(e)));
  }
};

export const loadData = params => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(getAllListings()),
    dispatch(getActivities()),
    dispatch(getPrivateTours()),
  ])
    .then(responses => {
      return responses;
    })
    .catch(e => {
      throw e;
    });
};
