import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './LandingPageSectionHeading.module.css';

const LandingPageSectionHeading = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="LandingPageSectionHeading.LandingPageSectionHeading.heading" />
      </div>
    </div>
  );
};

LandingPageSectionHeading.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

LandingPageSectionHeading.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageSectionHeading;
