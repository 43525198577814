import React from 'react';
import moment from 'moment';
import css from './TransactionPanel.module.css';
import { SecondaryButton } from '../Button/Button';
import { TRANSITION_ACCEPT, TRANSITION_CONFIRM_PAYMENT } from '../../util/transaction';
import { useDispatch, useSelector } from 'react-redux';
import {
  customerCancelBeforeAccept,
  onCancelCustomer,
  providerCancel,
} from '../../containers/TransactionPage/TransactionPage.duck';
import { useIntl } from 'react-intl';

const CustomerCancel = props => {
  const { currentTransaction, isCustomer, isProvider } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const state = useSelector(state => state.TransactionPage);
  const {
    customerCancelInProgress,
    customerCancelError,
    providerDeleteInProgress,
    providerDeleteError,
    customerCancelBeforeAcceptInProgress,
    customerCancelBeforeAcceptError,
  } = state;

  const lastTransitions = [TRANSITION_CONFIRM_PAYMENT, TRANSITION_ACCEPT];
  const transactionDate = currentTransaction?.attributes?.createdAt;
  const transactionStartDate = currentTransaction?.booking?.attributes?.start;
  const presentDate = moment().format('YYYY-MM-DD');
  const isStartDateGreaterThan7Days = moment(
    moment(transactionStartDate).format('YYYY-MM-DD')
  ).diff(presentDate, 'days');
  const isStartDateLessthanPresentDate = isStartDateGreaterThan7Days <= 0;

  const hoursTillBookingStart = moment
    .duration(moment(moment(new Date())).diff(moment(new Date(transactionDate))))
    .asHours();
  const lastTransition = currentTransaction?.attributes?.lastTransition;
  return (
    <>
      {isCustomer &&
      // (hoursTillBookingStart <= HOURS || isStartDateGreaterThan7Days >= DAYS) &&
      !isStartDateLessthanPresentDate &&
      lastTransition === TRANSITION_ACCEPT ? (
        <div className={css.buttonContainer}>
          {customerCancelError ? (
            <p className={css.error}>
              {customerCancelError?.message ||
                intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.errorMessage' })}
            </p>
          ) : null}
          <SecondaryButton
            className={css.cancelButton}
            inProgress={customerCancelInProgress}
            onClick={() => {
              dispatch(
                onCancelCustomer({
                  txId: currentTransaction.id.uuid,
                })
              );
            }}
            type="button"
          >
            {intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.cancelLabel' })}
          </SecondaryButton>
        </div>
      ) : null}
      {/* customer cancel before accept */}
      {isCustomer && lastTransition === TRANSITION_CONFIRM_PAYMENT ? (
        <div className={css.buttonContainer}>
          {customerCancelBeforeAcceptError ? (
            <p className={css.error}>
              {customerCancelBeforeAcceptError?.message ||
                intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.errorMessage' })}
            </p>
          ) : null}
          <SecondaryButton
            className={css.cancelButton}
            inProgress={customerCancelBeforeAcceptInProgress}
            onClick={() => {
              dispatch(customerCancelBeforeAccept(currentTransaction.id.uuid));
            }}
            type="button"
          >
            {intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.cancelLabel' })}
          </SecondaryButton>
        </div>
      ) : null}
      {isProvider && lastTransition === TRANSITION_ACCEPT && !isStartDateLessthanPresentDate ? (
        <div className={css.buttonContainer}>
          {providerDeleteError ? (
            <p className={css.error}>
              {providerDeleteError?.message ||
                intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.errorMessage' })}
            </p>
          ) : null}
          <SecondaryButton
            className={css.cancelButton}
            inProgress={providerDeleteInProgress}
            onClick={() => {
              dispatch(providerCancel(currentTransaction.id.uuid));
            }}
            type="button"
          >
            {intl.formatMessage({ id: 'TransactionPanel.CustomerCancel.cancelLabel' })}
          </SecondaryButton>
        </div>
      ) : null}
    </>
  );
};

export default CustomerCancel;
