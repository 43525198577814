import React from 'react';
import TagsInput from 'react-tagsinput';
import { Field } from 'react-final-form';
import css from './FieldTagInput.module.css';
import classNames from 'classnames';
import { ValidationError } from '../../components';

const FieldTagInputComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    modified,
    subHeading,
    ...rest
  } = props;
  /* eslint-enable no-unused-vars */

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Textarea doesn't need type.
  const { ...inputWithoutType } = input;
  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });
  const inputProps = { className: inputClasses, id, ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {subHeading ? <p className={css.subHeading}>{subHeading}</p> : null}
      {
        <TagsInput
          {...inputProps}
          // maxTags={8}

          addOnBlur={true}
          inputProps={{
            onBlur: input.onBlur,
            onFocus: input.onFocus,
            ...inputProps.inputProps,
          }}
        />
      }
      {/* {modified && <ValidationError removeTouched fieldMeta={fieldMeta} />} */}
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
  //   const { input } = props;
  //   return <TagsInput {...input} />;
};

export default function FieldTagInput(props) {
  return <Field component={FieldTagInputComponent} {...props} />;
}
