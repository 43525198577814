import React, { useMemo, useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import { AiTwotoneDelete } from 'react-icons/ai';
import {
  Button,
  Form,
  FieldCurrencyInput,
  CheckboxWithField,
  FieldCheckboxGroup,
  DatePicker,
  FieldSelectModern,
  FieldTextInput,
  TranslateText,
  FieldCheckbox,
} from '../../components';
import css from './EditListingPricingForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoMdAddCircle } from 'react-icons/io';
import moment from 'moment';
import PackOfLessons from './PackOfLessons';
const COMMISSION = +(process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE || 10);
const ACTIVITIES_LIMIT = 15;
const ACTIVITIES_MAX_LIMIT = 100;
export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        initialValues,
        values,
        isLanguageEnglish,
      } = formRenderProps;
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const [activitiesLimit, setActivitiesLimit] = useState(false);
      useEffect(() => {
        if (values?.activities?.length > ACTIVITIES_LIMIT) {
          setActivitiesLimit(true);
        } else {
          setActivitiesLimit(false);
        }
      }, [values?.activities]);

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: ACTIVITIES_MAX_LIMIT,
        }
      );
      const maxLengthActivities = validators.maxLength(maxLengthMessage, ACTIVITIES_MAX_LIMIT);
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || activitiesLimit;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const getMoney = () => breakdownAmount + (COMMISSION * breakdownAmount) / 100;
      const getAdditionalMoney = breakdownAmount =>
        breakdownAmount + (COMMISSION * breakdownAmount) / 100;

      const breakdownAmount = useMemo(() => {
        if (values?.price == null) return 0;
        if (values?.price?.amount) return values?.price?.amount;
        return initialValues?.price?.amount;
      }, [initialValues, values?.price?.amount]);

      const checkboxWithFieldPriceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <p className={css.tip}>
            {intl.formatMessage({ id: 'EditListingPricingForm.EditListingPricingForm.noteOne' })}
          </p>
          <FieldCurrencyInput
            id="week_price"
            name="week_price"
            className={css.weekPrice}
            label={intl.formatMessage({
              id: 'EditListingPricingForm.EditListingPricingForm.weekPrice',
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingPricingForm.EditListingPricingForm.weekPricePlaceholder',
            })}
            currencyConfig={config.currencyConfig}
            // validate={priceValidators}
          />
          <FieldCurrencyInput
            id="month_price"
            name="month_price"
            className={css.weekPrice}
            label={intl.formatMessage({
              id: 'EditListingPricingForm.EditListingPricingForm.monthPrice',
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingPricingForm.EditListingPricingForm.monthPricePlaceholder',
            })}
            currencyConfig={config.currencyConfig}
            // validate={priceValidators}
          />

          <div>
            <br />
            <FieldCurrencyInput
              id="hr_extra_session"
              name="hr_extra_session"
              className={css.priceInput}
              // autoFocus
              label={intl.formatMessage({
                id: 'EditListingPricingForm.EditListingPricingForm.perLessonLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingForm.EditListingPricingForm.perLessonPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
            {/* <p className={css.tip}>
              {intl.formatMessage({
                id: 'EditListingPricingForm.EditListingPricingForm.offerDayLabel',
              })}
            </p> */}

            <PackOfLessons values={values} intl={intl} config={config} />
            <div className={css.priceGrid}></div>
            {breakdownAmount ? (
              <div style={{ marginTop: '1rem' }}>
                <p
                  style={{
                    borderLeft: '5px solid #17a2b8',
                    padding: '1rem .5rem',
                    lineHeight: '1.4',
                    // color: '#17a2b8',
                    background: 'hsl(188deg 78% 89%)',
                  }}
                >
                  <p className={css.tip} style={{ marginBottom: '1px' }}>
                    {intl.formatMessage({
                      id: 'EditListingPricingForm.EditListingPricingForm.noteFour',
                    })}
                  </p>
                  <p className={css.tip}>
                    <FormattedMessage
                      id="EditListingPricingForm.EditListingPricingForm.noteFive"
                      values={{
                        price: formatMoney(intl, values.price),
                      }}
                    />

                    <span> {formatMoney(intl, new Money(getMoney(), config.currency))}</span>
                  </p>
                  {values?.hr_extra_session_field?.amount ? (
                    <p className={css.tip}>
                      <FormattedMessage
                        id="EditListingPricingForm.EditListingPricingForm.noteSix"
                        values={{
                          price: formatMoney(intl, values?.hr_extra_session_field),
                        }}
                      />{' '}
                      {formatMoney(
                        intl,
                        new Money(
                          getAdditionalMoney(values?.hr_extra_session_field?.amount),
                          config.currency
                        )
                      )}
                    </p>
                  ) : null}
                </p>
              </div>
            ) : null}
          </div>
          <div></div>
          <div>
            <FieldArray name="activities">
              {({ fields }) => (
                <div>
                  <h3 className={css.languageHeading}>
                    {intl.formatMessage({
                      id: 'EditListingPricingForm.EditListingPricingForm.activitiesLabel',
                    })}
                  </h3>
                  <p className={css.tipnote}>
                    {intl.formatMessage({
                      id: 'EditListingPricingForm.EditListingPricingForm.additionalActivitiesTip',
                    })}
                  </p>
                  {fields.map((name, index) => (
                    <div key={name} className={css.fieldArrayContainer}>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.nameOfActivityLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.nameOfActivityPlaceholder',
                              })}
                              validate={validators.composeValidators(
                                validators.required(
                                  intl.formatMessage({
                                    id:
                                      'EditListingPricingForm.EditListingPricingForm.nameOfActivityReq',
                                  })
                                ),
                                maxLengthActivities
                              )}
                              transalateComponent={
                                <TranslateText
                                  form={formRenderProps.form}
                                  value={values?.activities?.[index]?.es_name}
                                  fromKey={`${name}.es_name`}
                                  toKey={`${name}.name`}
                                  target="en"
                                  id={`${name}.name`}
                                />
                              }
                            />
                            {/* <TranslateText
                              form={formRenderProps.form}
                              value={values?.activities?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            /> */}
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.nameOfActivityLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.activitySpanishPlaceholder',
                              })}
                              validate={validators.composeValidators(
                                validators.required(
                                  intl.formatMessage({
                                    id:
                                      'EditListingPricingForm.EditListingPricingForm.nameOfActivityReqSpanish',
                                  })
                                ),
                                maxLengthActivities
                              )}
                              transalateComponent={
                                <TranslateText
                                  form={formRenderProps.form}
                                  value={values?.activities?.[index]?.name}
                                  fromKey={`${name}.name`}
                                  toKey={`${name}.es_name`}
                                  target="es"
                                  id={`${name}.es_name`}
                                />
                              }
                            />

                            {/* <TranslateText
                              form={formRenderProps.form}
                              value={values?.activities?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            /> */}
                          </>
                        )}
                      </div>
                      <div>
                        {isLanguageEnglish ? (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.es_name`}
                              name={`${name}.es_name`}
                              label={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.activityEsSpanish',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.activitySpanishPlaceholder',
                              })}
                              validate={validators.composeValidators(
                                validators.required(
                                  intl.formatMessage({
                                    id:
                                      'EditListingPricingForm.EditListingPricingForm.nameOfActivityReqSpanish',
                                  })
                                ),
                                maxLengthActivities
                              )}
                              transalateComponent={
                                <TranslateText
                                  form={formRenderProps.form}
                                  value={values?.activities?.[index]?.name}
                                  fromKey={`${name}.name`}
                                  toKey={`${name}.es_name`}
                                  target="es"
                                  id={`${name}.es_name`}
                                />
                              }
                            />

                            {/* <TranslateText
                              form={formRenderProps.form}
                              value={values?.activities?.[index]?.name}
                              fromKey={`${name}.name`}
                              toKey={`${name}.es_name`}
                              target="es"
                              id={`${name}.es_name`}
                            /> */}
                          </>
                        ) : (
                          <>
                            <FieldTextInput
                              className={css.language}
                              id={`${name}.name`}
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.activityEsSpanish',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingPricingForm.EditListingPricingForm.nameOfActivityPlaceholder',
                              })}
                              validate={validators.composeValidators(
                                validators.required(
                                  intl.formatMessage({
                                    id:
                                      'EditListingPricingForm.EditListingPricingForm.nameOfActivityReq',
                                  })
                                ),
                                maxLengthActivities
                              )}
                              transalateComponent={
                                <TranslateText
                                  form={formRenderProps.form}
                                  value={values?.activities?.[index]?.es_name}
                                  fromKey={`${name}.es_name`}
                                  toKey={`${name}.name`}
                                  target="en"
                                  id={`${name}.name`}
                                />
                              }
                            />
                            {/* <TranslateText
                              form={formRenderProps.form}
                              value={values?.activities?.[index]?.es_name}
                              fromKey={`${name}.es_name`}
                              toKey={`${name}.name`}
                              target="en"
                              id={`${name}.name`}
                            /> */}
                          </>
                        )}
                      </div>
                      <FieldTextInput
                        className={css.language}
                        id={`${name}.hours`}
                        name={`${name}.hours`}
                        type="number"
                        label={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.numberOfHoursLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.noOfHoursPlaceholders',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'EditListingPricingForm.EditListingPricingForm.noOfHoursRequired',
                          })
                        )}
                      />
                      <div className={css.subContainer}>
                        <FieldCurrencyInput
                          className={css.level}
                          id={`${name}.price`}
                          label={intl.formatMessage({
                            id: 'EditListingPricingForm.EditListingPricingForm.activityPriceLabel',
                          })}
                          name={`${name}.price`}
                          placeholder={intl.formatMessage({
                            id:
                              'EditListingPricingForm.EditListingPricingForm.activityPricePlaceholder',
                          })}
                          validate={validators.required(
                            intl.formatMessage({
                              id: 'EditListingPricingForm.EditListingPricingForm.activityReq',
                            })
                          )}
                          currencyConfig={config.currencyConfig}
                        />
                        {values?.activities?.length && (
                          <p className={css.close} onClick={() => fields.remove(index)}>
                            <AiTwotoneDelete className={css.delete} />
                          </p>
                        )}
                      </div>
                      <FieldCheckbox
                        id={`${name}.recuring`}
                        name={`${name}.recuring`}
                        label={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.recurring',
                        })}
                      />
                    </div>
                  ))}
                  {activitiesLimit ? (
                    <p className={css.error}>
                      <FormattedMessage
                        id="EditListingPricingForm.EditListingPricingForm.activitiesLimit"
                        values={{ limit: ACTIVITIES_LIMIT }}
                      />
                    </p>
                  ) : null}
                  <p
                    className={css.addAnotherLanguage}
                    onClick={() =>
                      fields.push({ name: '', es_name: '', hours: '', price: '', recuring: false })
                    }
                  >
                    {intl.formatMessage({
                      id: 'EditListingPricingForm.EditListingPricingForm.addActivityLabel',
                    })}
                  </p>
                </div>
              )}
            </FieldArray>
          </div>
          <p className={css.tipnote}>
            {intl.formatMessage({
              id: 'EditListingPricingForm.EditListingPricingForm.commissionNote',
            })}
          </p>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
