import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import {
  ListingLink,
  Modal,
  InlineTextButton,
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  TimeRange,
} from '../../components';
import { EditListingAvailabilityForm } from '../../forms';
import {
  requestAddAvailabilityException,
  requestDeleteAvailabilityException,
  requestCreateAvailabilityException,
} from '../../containers/EditListingPage/EditListingPage.duck';
import css from './EditListingAvailabilityPanel.module.css';
import { useDispatch } from 'react-redux';
import EditListingAvailabilityExceptionForm from './EditListingAvailabilityExceptionForm';
import useInitialValues from '../useInitialValueHook/useInitialValueHook';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useIntl } from 'react-intl';

const { Money } = sdkTypes;
const MAX_EXCEPTIONS_COUNT = 100;
const DATE_TYPE_DATE = 'date';
const DATE_TYPE_DATETIME = 'datetime';

const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availability,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    sectionCategoryLabel,
    availabilityExceptions,
    fetchExceptionsInProgress,
    exceptionErr,

    values,
    type,
    slug,
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const classes = classNames(rootClassName || css.root, className);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const sortExceptionsByStartTime = (a, b) => {
    return new Date(a?.attributes?.start)?.getTime() - new Date(b?.attributes?.start)?.getTime();
  };
  const [loading, setLoading] = React.useState();
  const defaultTimeZone = () => (typeof window !== 'undefined' ? 'Etc/UTC' : 'Etc/UTC');
  const defaultAvailabilityPlan = {
    type: 'availability-plan/day',
    entries: [
      { dayOfWeek: 'mon', seats: 1 },
      { dayOfWeek: 'tue', seats: 1 },
      { dayOfWeek: 'wed', seats: 1 },
      { dayOfWeek: 'thu', seats: 1 },
      { dayOfWeek: 'fri', seats: 1 },
      { dayOfWeek: 'sat', seats: 1 },
      { dayOfWeek: 'sun', seats: 1 },
    ],
  };
  let availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  // console.log(
  //   ' currentListing.attributes.availabilityPlan',
  //   currentListing.attributes.availabilityPlan
  // );
  availabilityPlan = {
    ...availabilityPlan,
  };
  const timestampToDate = timestamp => {
    return new Date(Number.parseInt(timestamp, 10));
  };
  const saveException = async values => {
    setLoading(true);
    const { availability, exceptionStartDate, exceptionEndDate } = values;
    const seats = availability === 'available' ? 1 : 0;
    return dispatch(
      requestCreateAvailabilityException(
        {
          listingId: listing.id,
          seats: 0,
          start: new Date(exceptionStartDate?.date?.toUTCString()).toISOString(),
          end: new Date(exceptionEndDate?.date?.toUTCString()).toISOString(),
          currentException: [],
        },
        'exc'
      )
    )
      .then(res => {
        setIsEditExceptionsModalOpen(false);
        if (res) {
          // window?.location?.reload(true);
          setIsEditExceptionsModalOpen(false);
        } else {
          setIsEditExceptionsModalOpen(true);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // Don't close modal if there was an error
      });
  };
  // console.log('availabilityExceptions', props.availability?.calendar);
  const avail = Object.entries(props.availability?.calendar)
    .flat()
    .filter(l => typeof l === 'object')
    .map(c =>
      c.exceptions.map(m => {
        return { ...m.availabilityException };
      })
    )
    .flat();
  let formatAvailExc =
    typeof availabilityExceptions === 'object'
      ? { availabilityException: availabilityExceptions?.availabilityException }
      : availabilityExceptions;
  // console.log('availabilityExceptions', availabilityExceptions);
  // removed format avail here from array spread.
  let newAvailabilityExceptions = formatAvailExc.availabilityException ? [...avail] : [...avail];
  // console.log('newAvailabilityExceptions', newAvailabilityExceptions);
  newAvailabilityExceptions = newAvailabilityExceptions?.filter(i => i.id);
  const newAvailabilityExceptionsWithOutDuplicates = newAvailabilityExceptions?.reduce(
    (acc, current) => {
      const x = acc.find(item => item.id.uuid === current.id.uuid);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    },
    []
  );
  newAvailabilityExceptions = newAvailabilityExceptionsWithOutDuplicates;
  const exceptionCount = newAvailabilityExceptions ? newAvailabilityExceptions?.length : 0;
  const sortedAvailabilityExceptions =
    newAvailabilityExceptions && newAvailabilityExceptions?.sort(sortExceptionsByStartTime);

  function onManageDisableScrolling() {
    console.log('...');
  }
  const { publicData } = currentListing.attributes;
  const oldInitialValues = {
    availabilityPlan,
    min_stay: publicData?.min_stay,
    max_stay: publicData?.max_stay,
    buffer_time: publicData?.buffer_time,
    datePriceArray:
      publicData?.datePriceArray?.map(each => {
        return {
          ...each,
          price: each.price ? new Money(each.price, config.currency) : {},
          start_date: each.start_date ? new Date(each.start_date) : '',
          end_date: each.end_date ? new Date(each.end_date) : '',
        };
      }) || [],
  };
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  const exceptionView = (
    <section className={css.section}>
      <p className={css.note}>
        {intl.formatMessage({
          id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.noteOne',
        })}
      </p>
      <header className={css.sectionHeader}>
        <h2 className={css.sectionTitle}>
          {fetchExceptionsInProgress ? (
            <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
          ) : (
            <FormattedMessage
              id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
              values={{ count: exceptionCount }}
            />
          )}
        </h2>
      </header>
      {fetchExceptionsInProgress ? (
        <div className={css.exceptionsLoading}>
          <IconSpinner />
        </div>
      ) : exceptionCount === 0 ? (
        <div className={css.noExceptions}>
          <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
        </div>
      ) : (
        <div className={css.exceptions}>
          {sortedAvailabilityExceptions?.map(availabilityException => {
            const { start, end, seats } = availabilityException?.attributes;
            return (
              <div key={availabilityException?.id?.uuid} className={css.exception}>
                <div className={css.exceptionHeader}>
                  <div className={css.exceptionAvailability}>
                    <div
                      className={classNames(css.exceptionAvailabilityDot, {
                        [css.isAvailable]: !!seats,
                      })}
                    />
                    <div className={css.exceptionAvailabilityStatus}>
                      {deleteLoading === availabilityException?.id?.uuid ? (
                        <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.deleting" />
                      ) : (
                        // <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                        <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                      )}

                      {/* {seats > 0 ? (
                        <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                      ) : (
                        ' Loading...'
                      )} */}
                    </div>
                  </div>
                  <button
                    className={css.removeExceptionButton}
                    type="button"
                    onClick={async () => {
                      try {
                        setDeleteLoading(availabilityException?.id?.uuid);
                        await dispatch(
                          requestDeleteAvailabilityException(
                            availabilityException,
                            'exc',
                            listing.id.uuid
                          )
                        );
                        setDeleteLoading(null);
                        // history.push(`/l/${slug}/${listing.id.uuid}/${type}/availability`);
                      } catch (e) {
                        console.log('e', e);
                      }
                    }}
                  >
                    <IconClose size="normal" className={css.removeIcon} />
                  </button>
                </div>
                <TimeRange
                  className={css.timeRange}
                  startDate={start}
                  endDate={end}
                  dateType={DATE_TYPE_DATETIME}
                  timeZone="Etc/UTC"
                />
              </div>
            );
          })}
        </div>
      )}
      {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
        <button
          type="button"
          className={css.addExceptionButton}
          onClick={() => setIsEditExceptionsModalOpen(true)}
          disabled={disabled}
        >
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.addAvailability',
          })}
        </button>
      ) : null}
    </section>
  );
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      <EditListingAvailabilityForm
        className={css.form}
        listingId={currentListing.id}
        initialValues={initialValues}
        availability={availability}
        availabilityPlan={availabilityPlan}
        intl={intl}
        listing={listing}
        exceptionView={exceptionView}
        onSubmit={values => {
          const { datePriceArray, min_stay, max_stay, buffer_time } = values;
          updateInitialValues({
            ...values,
            datePriceArray: datePriceArray
              ? datePriceArray?.map(each => {
                  return {
                    ...each,
                    start_date: each.start_date,
                    end_date: each.end_date,
                  };
                })
              : [],
          });
          onSubmit({
            availabilityPlan,
            publicData: {
              min_stay: +min_stay,
              max_stay: +max_stay,
              buffer_time: +buffer_time,
              datePriceArray: datePriceArray?.map(each => {
                // console.log(
                //   moment(each.date.toISOString())
                //     .utcOffset(0, true)
                //     .toISOString()
                // );
                return {
                  ...each,
                  price: each?.price?.amount,
                  start_date: each.start_date
                    ? moment(each.start_date.toISOString())
                        .utcOffset(0, true)
                        .toISOString()
                    : '',
                  end_date: each.end_date
                    ? moment(each.end_date.toISOString())
                        .utcOffset(0, true)
                        .toISOString()
                    : '',
                };
              }),
            },
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        publicData={listing?.attributes?.publicData}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
      />
      <Modal
        id="EditAvailabilityExceptions"
        isOpen={isEditExceptionsModalOpen}
        onClose={() => setIsEditExceptionsModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
        usePortal
      >
        {isEditExceptionsModalOpen ? (
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            initialValues={{
              exceptionStartDate: null,
              exceptionEndDate: null,
              availability: 'available',
            }}
            timeZone="Etc/UTC"
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={loading}
            fetchErrors={errors}
            exceptionErr={exceptionErr}
          />
        ) : null}
      </Modal>
    </div>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
  }).isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
