import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import css from './EditListingPricingForm.module.css';
import { AiTwotoneDelete } from 'react-icons/ai';
import { FieldCurrencyInput, FieldTextInput } from '../../components';
import { required } from '../../util/validators';
const PackOfLessons = ({ values, intl, config }) => {
  return (
    <div className={css.packOfLessonsContainer}>
      <FieldArray name="packOfLessons">
        {({ fields }) => (
          <div>
            <h3 className={css.languageHeading}>
              {intl.formatMessage({ id: 'EditListingPricingForm.PackOfLessons.setLessonPacks' })}
            </h3>

            {fields.map((name, index) => (
              <div key={name} className={css.fieldArrayContainer}>
                <div className={css.subContainer}>
                  <FieldTextInput
                    className={css.level}
                    id={`${name}.hours`}
                    name={`${name}.hours`}
                    type="number"
                    label={intl.formatMessage({
                      id: 'EditListingPricingForm.PackOfLessons.numberOfLessons',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'EditListingPricingForm.PackOfLessons.noOfLessonsPlaceholder',
                    })}
                    validate={required(
                      intl.formatMessage({
                        id: 'EditListingPricingForm.PackOfLessons.noOflessonReq',
                      })
                    )}
                  />
                </div>
                <div className={css.subContainer}>
                  <FieldCurrencyInput
                    className={css.level}
                    id={`${name}.price`}
                    label={intl.formatMessage({ id: 'EditListingPricingForm.PackOfLessons.price' })}
                    name={`${name}.price`}
                    placeholder={intl.formatMessage({
                      id: 'EditListingPricingForm.PackOfLessons.pricePlaceholder',
                    })}
                    validate={required(
                      intl.formatMessage({
                        id: 'EditListingPricingForm.PackOfLessons.priceRequired',
                      })
                    )}
                    currencyConfig={config.currencyConfig}
                  />
                  {values?.packOfLessons?.length && (
                    <p className={css.close} onClick={() => fields.remove(index)}>
                      <AiTwotoneDelete className={css.delete} />
                    </p>
                  )}
                </div>
              </div>
            ))}
            {values?.packOfLessons?.length < 5 ? (
              <p
                className={css.addAnotherLanguage}
                onClick={() => fields.push({ hours: '', price: '' })}
              >
                {intl.formatMessage({ id: 'EditListingPricingForm.PackOfLessons.addLessonPack' })}
              </p>
            ) : null}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default PackOfLessons;
