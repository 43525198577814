import React from 'react';
import css from './PrivacyPolicy.module.css';
export default function PrivacyEn() {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p className={css.lastUpdated}>Effective date: April 2023</p>

      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and
        disclosure of Your information when You use the Service and tells You about Your privacy
        rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You
        agree to the collection and use of information in accordance with this Privacy Policy.{' '}
      </p>
      <p>Please read carefully this Privacy Policy before using Our Service.</p>
      <h2>1 - Interpretation and Definitions</h2>
      <h3>1.1 - Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the
        following conditions. The following definitions shall have the same meaning regardless of
        whether they appear in the singular or in the plural.
      </p>
      <h3>1.2 - Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Account</b> means a unique account created for You to access our Service or parts of
          our Service.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Affiliate</b> means an entity that controls, is controlled by, or is under common
          control with a party, where "control" means ownership of 50% or more of the shares, equity
          interest, or other securities entitled to vote for the election of directors or other
          managing authority.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Application</b> means the software program provided by the Company or Operator
          downloaded by You on any electronic device, named LinGo Immersion.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Business</b> refers to the Company or Operator as the legal entity that collects
          Consumers' personal information and determines the purposes and means of the processing of
          Consumers' personal information, or on behalf of which such information is collected and
          that alone, or jointly with others, determines the purposes and means of the processing of
          consumers' personal information.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this
          Agreement) refers to LinGo Immersion. For the purpose of the GDPR, the Company or Operator
          is the Data Controller.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Consumer</b>, refers to You.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Cookies</b> are small files that are placed on Your computer, mobile device or any
          other device by a website, containing the details of Your browsing history on that website
          among its many uses.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Country</b> refers to Spain.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Data Controller</b>, for the purposes of the GDPR (General Data Protection Regulation),
          refers to the Company or Operator as the legal person which alone or jointly with others
          determines the purposes and means of the processing of Personal Data.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Device</b> means any device that can access the Service such as a computer, a
          cellphone, or a digital tablet.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Do Not Track</b> (DNT) is a concept that has been promoted by US regulatory
          authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
          industry to develop and implement a mechanism for allowing internet users to control the
          tracking of their online activities across websites.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Operator</b> (referred to as either "the Operator", "We", "Us" or "Our" in this
          Agreement) refers to LinGo Immersion. For the purpose of the GDPR, the Company or Operator
          is the Data Controller.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b> Personal Data </b>is any information that relates to an identified or identifiable
          individual.
          <br />
          For the purposes of GDPR, Personal Data means any information relating to You such as a
          name, an identification number, location data, online identifier or to one or more factors
          specific to the physical, physiological, genetic, mental, economic, cultural or social
          identity. For the purposes of the CCPA, Personal Data means any information that
          identifies, relates to, describes or is capable of being associated with, or could
          reasonably be linked, directly or indirectly, with You.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Sale</b>, means selling, renting, releasing, disclosing, disseminating, making
          available, transferring, or otherwise communicating orally, in writing, or by electronic
          or other means, a Consumer's personal information to another business or a third party for
          monetary or other valuable consideration.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Service</b> refers to the Application or the Website or both.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Service Provider</b> means any natural or legal person who processes the data on behalf
          of the Company or Operator. It refers to third-party companies or individuals employed by
          the Company or Operator to facilitate the Service, to provide the Service on behalf of the
          Company or Operator, to perform services related to the Service or to assist the Company
          or Operator in analyzing how the Service is used. For the purpose of the GDPR, Service
          Providers are considered Data Processors.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Third-party Social Media Service</b> refers to any website or any social network
          website through which a User can log in or create an account to use the Service.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Usage Data</b> refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example, the duration of a page
          visit).
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Website</b> refers lingoimmersion.com, accessible from the url
          https://www.lingoimmersion.com.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>You</b> means the individual accessing or using the Service, or the company, or other
          legal entity on behalf of which such individual is accessing or using the Service, as
          applicable. Under GDPR (General Data Protection Regulation), You can be referred to as the
          Data Subject or as the User as you are the individual using the Service.
        </li>
      </ul>
      <h2>2 - Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        By email: <a href="mailto:contact@lingoimmersion.com">contact@lingoimmersion.com</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        By visiting this page on our website:{' '}
        <a href="https://www.lingoimmersion.com/contact">https://www.lingoimmersion.com/contact</a>.
      </li>
      <h2>3 - Collecting and Using Your Personal Data</h2>
      <h3>3.1 - Types of Data Collected</h3>
      <p>3.1.1 - Personal Data</p>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable
        information that can be used to contact or identify You. Personally identifiable information
        may include, but is not limited to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Email address</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>First name and last name</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Phone number</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Address, State, Province, ZIP/Postal code, City
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Bank account information</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Credit card number and expiration date
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Usage Data</li>
      <p>3.1.2 - Usage Data</p>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
        address), browser type, browser version, the pages of our Service that You visit, the time
        and date of Your visit, the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may collect certain
        information automatically, including, but not limited to, the type of mobile device You use,
        Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
        system, the type of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You visit our Service or
        when You access the Service by or through a mobile device.
      </p>
      <p>3.1.3 - Information from Third-Party Social Media Services</p>
      <p>
        The Company or Operator may allow You to create an account and log in to use the Service
        through the following Third-party Social Media Services. These Third-party Social Media
        Services, may include, but is not limited to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Google</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Facebook</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Twitter</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>LinkedIn</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Apple</li>
      <p>
        If You decide to register through or otherwise grant us access to a Third-Party Social Media
        Service, We may collect Personal data that is already associated with Your Third-Party
        Social Media Service's account, such as Your name, Your email address, Your activities or
        Your contact list associated with that account.
      </p>
      <p>
        You may also have the option of sharing additional information with the Company or Operator
        through Your Third-Party Social Media Service's account. If You choose to provide such
        information and Personal Data, during registration or otherwise, You are giving the Company
        or Operator permission to use, share, and store it in a manner consistent with this Privacy
        Policy.
      </p>
      <p>3.1.4 - Tracking Technologies and Cookies</p>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Service and
        store certain information. Tracking technologies used are beacons, tags, and scripts to
        collect and track information and to improve and analyze Our Service. The technologies We
        use may include:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can
        instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
        However, if You do not accept Cookies, You may not be able to use some parts of our Service.
        Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service
        may use Cookies.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Web Beacons.</b> Certain sections of our Service and our emails may contain small
        electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
        single-pixel gifs) that permit the Company or Operator, for example, to count users who have
        visited those pages or opened an email and for other related application or website
        statistics (for example, recording the popularity of a certain section and verifying system
        and server integrity).
      </li>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal
        computer or mobile device when You go offline, while Session Cookies are deleted as soon as
        You close Your web browser.
      </p>
      <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Necessary / Essential Cookies</b>
        <br />
        <span style={{ marginLeft: '20px' }}>Type: Sespansion Cookies</span>
        <br />
        <span style={{ marginLeft: '20px' }}> Administered by: Us</span>
        <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Purpose: These Cookies are essential to provide You with services available through the
          Application or Website and to enable You to use some of its features. They help to
          authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the
          services that You have asked for cannot be provided, and We only use these Cookies to
          provide You with those services.
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Cookies Policy / Notice Acceptance Cookies</b>
        <br /> <span style={{ marginLeft: '20px' }}>Type: Persistent Cookies</span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administered by: Us</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Purpose: These Cookies identify if users have accepted the use of cookies on the
          Application or Website.
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Functionality Cookies</b>
        <br /> <span style={{ marginLeft: '20px' }}>Type: Persistent Cookies </span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administered by: Us</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Purpose: These Cookies allow us to remember choices You make when You use the Application
          or Website, such as remembering your login details or language preference. The purpose of
          these Cookies is to provide You with a more personal experience and to avoid You having to
          re-enter your preferences every time You use the Application or Website.
        </span>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Tracking and Performance Cookies</b>
        <br /> <span style={{ marginLeft: '20px' }}>Type: Persistent Cookies </span>
        <br />
        <span style={{ marginLeft: '20px' }}>Administered by: Third-Parties</span> <br />
        <span style={{ marginLeft: '20px', display: 'block' }}>
          Purpose: These Cookies are used to track information about traffic to the Application or
          Website and how users use the Application or Website. The information gathered via these
          Cookies may directly or indirectly identify you as an individual visitor. This is because
          the information collected is typically linked to a pseudonymous identifier associated with
          the device you use to access the Application or Website. We may also use these Cookies to
          test new pages, features or new functionality of the Application or Website to see how our
          users react to them.
        </span>
      </li>
      <h3>3.2 - Use of Your Personal Data</h3>
      <p>The Company or Operator may use Personal Data for the following purposes:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To provide and maintain our Service</b>, including to monitor the usage of our Service.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To manage Your Account:</b> to manage Your registration as a user of the Service. The
        Personal Data You provide can give You access to different functionalities of the Service
        that are available to You as a registered user.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>For the performance of a contract:</b> the development, compliance and undertaking of the
        purchase contract for the products, items or services You have purchased or of any other
        contract with Us through the Service.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent
        forms of electronic communication, such as a mobile application's push notifications
        regarding updates or informative communications related to the functionalities, products or
        contracted services, including the security updates, when necessary or reasonable for their
        implementation.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To provide You</b> with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you have already purchased
        or enquired about unless You have opted not to receive such information.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To manage Your requests:</b> To attend and manage Your requests to Us.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>To deliver targeted advertising to You:</b> We may use Your information to develop and
        display content and advertising (and work with third-party vendors who do so) tailored to
        Your interests and/or location and to measure its effectiveness.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>For business transfers:</b> We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
        or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service users is among the
        assets transferred.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>For other purposes:</b> We may use Your information for other purposes, such as data
        analysis, identifying usage trends, determining the effectiveness of our promotional
        campaigns and to evaluate and improve our Service, products, services, marketing and your
        experience.
      </li>
      <p>We may share Your personal information in the following situations:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>With Service Providers:</b> We may share Your personal information with Service Providers
        to monitor and analyze the use of our Service, to advertise on third party websites to You
        after You visited our Service, for payment processing, to contact You.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>For business transfers:</b> We may share or transfer Your personal information in
        connection with, or during negotiations of, any merger, sale of Company or Operator assets,
        financing, or acquisition of all or a portion of Our business to another company.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we
        will require those affiliates to honor this Privacy Policy. Affiliates include Our parent
        company and any other subsidiaries, joint venture partners or other companies that We
        control or that are under common control with Us.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>With business partners:</b> We may share Your information with Our business partners to
        offer You certain products, services or promotions.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>With other users:</b> when You share personal information or otherwise interact in the
        public areas with other users, such information may be viewed by all users and may be
        publicly distributed outside. If You interact with other users or register through a
        Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may
        see Your name, profile, pictures and description of Your activity. Similarly, other users
        will be able to view descriptions of Your activity, communicate with You and view Your
        profile.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>With Your consent:</b> We may disclose Your personal information for any other purpose
        with Your consent.
      </li>
      <h3>3.3 - Retention of Your Personal Data</h3>
      <p>
        The Company or Operator will retain Your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
        the extent necessary to comply with our legal obligations (for example, if we are required
        to retain your data to comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </p>
      <p>
        The Company or Operator will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period of time, except when this data is used to
        strengthen the security or to improve the functionality of Our Service, or We are legally
        obligated to retain this data for longer time periods.
      </p>
      <p>
        When Your Personal Data is no longer required by law or rights or obligations by Us or You,
        We will delete the Personal Data. In most cases, Personal Data will be deleted upon
        termination or expiry of the agreement between the You and the Company or Operator or upon
        Your written request.
      </p>
      <h3>3.4 - Transfer of Your Personal Data</h3>
      <p>
        Your information, including Personal Data, is processed at the Company’s or Operator's
        operating offices and in any other places where the parties involved in the processing are
        located. It means that this information may be transferred to — and maintained on —
        computers located outside of Your state, province, country or other governmental
        jurisdiction where the data protection laws may differ from those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such information
        represents Your agreement to that transfer.
      </p>
      <p>
        The Company or Operator will take all steps reasonably necessary to ensure that Your data is
        treated securely and in accordance with this Privacy Policy and no transfer of Your Personal
        Data will take place to an organization or a country unless there are adequate controls in
        place including the security of Your data and other personal information.
      </p>
      <h3>3.5 - Delete Your Personal Data</h3>
      <p>
        You have the right to delete or request that We assist in deleting the Personal Data that We
        have collected about You.
      </p>
      <p>
        Our Service may give You the ability to delete certain information about You from within the
        Service.
      </p>
      <p>
        You may update, amend, or delete Your information at any time by signing in to Your Account,
        if you have one, and visiting the account settings section that allows you to manage Your
        personal information. You may also contact Us to request access to, correct, or delete any
        personal information that You have provided to Us.
      </p>
      <p>
        Please note, however, that We may need to retain certain information when we have a legal
        obligation or lawful basis to do so.
      </p>
      <h3>3.6 - Disclosure of Your Personal Data</h3>
      <p>3.6.1 - Business Transactions</p>
      <p>
        If the Company or Operator is involved in a merger, acquisition or asset sale, Your Personal
        Data may be transferred. We will provide notice before Your Personal Data is transferred and
        becomes subject to a different Privacy Policy.
      </p>
      <p>3.6.2 - Law enforcement</p>
      <p>
        Under certain circumstances, the Company or Operator may be required to disclose Your
        Personal Data if required to do so by law or in response to valid requests by public
        authorities (e.g. a court or a government agency).
      </p>
      <p>3.6.3 - Other legal requirements</p>
      <p>
        The Company or Operator may disclose Your Personal Data in the good faith belief that such
        action is necessary to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>Comply with a legal obligation</li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Protect and defend the rights or property of the Company or Operator
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Prevent or investigate possible wrongdoing in connection with the Service
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Protect the personal safety of Users of the Service or the public
      </li>
      <h3>3.7 - Security of Your Personal Data</h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure. While We
        strive to use commercially acceptable means to protect Your Personal Data, We cannot
        guarantee its absolute security.
      </p>
      <h2>4 - Detailed Information on the Processing of Your Personal Data</h2>
      <p>
        The Service Providers We use may have access to Your Personal Data. These third-party
        vendors collect, store, use, process and transfer information about Your activity on Our
        Service in accordance with their Privacy Policies.
      </p>
      <h3>4.1 - Analytics</h3>
      <p>
        We may use third-party Service providers to monitor and analyze the use of our Service. They
        may include, but are not limited to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Google Analytics</b> <br /> Google Analytics is a web analytics service offered by Google
        that tracks and reports website traffic. Google uses the data collected to track and monitor
        the use of our Service. This data is shared with other Google services. Google may use the
        collected data to contextualize and personalize the ads of its own advertising network.
        <br />
        You can opt-out of having made your activity on the Service available to Google Analytics by
        installing the Google Analytics opt-out browser add-on. The add-on prevents the Google
        Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google
        Analytics about visits activity.
        <br /> You may opt-out of certain Google Analytics features through your mobile device
        settings, such as your device advertising settings or by following the instructions provided
        by Google in their Privacy Policy:{' '}
        <a href="https://policies.google.com/privacy ">https://policies.google.com/privacy </a>
        <br />
        For more information on the privacy practices of Google, please visit the Google Privacy &
        Terms web page:{' '}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Matomo</b> <br /> Matomo is a web analytics service. You can visit their Privacy Policy
        page here: <a href="https://matomo.org/privacy-policy">https://matomo.org/privacy-policy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Fathom</b> <br /> Fathom is a web analytics service. You can visit their Privacy Policy
        page here: <a href="https://www.fathomhq.com/privacy">https://www.fathomhq.com/privacy</a>
      </li>
      <h3>4.2 - Email Marketing</h3>
      <p>
        We may use Your Personal Data to contact You with newsletters, marketing or promotional
        materials and other information that may be of interest to You. You may opt-out of receiving
        any, or all, of these communications from Us by following the unsubscribe link or
        instructions provided in any email We send or by contacting Us.
      </p>
      <p>
        We may use Email Marketing Service Providers to manage and send emails to You. They may
        include, but are not limited to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Mailchimp</b> <br />
        Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.{' '}
        <br />
        For more information on the privacy practices of Mailchimp, please visit their Privacy
        policy:{' '}
        <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a>
      </li>
      <h3>4.3 - Payments</h3>
      <p>
        We may provide paid products and/or services within the Service. In that case, we may use
        third-party services for payment processing (e.g. payment processors).
      </p>
      <p>
        We will not store or collect Your payment card details. That information is provided
        directly to Our third-party payment processors whose use of Your personal information is
        governed by their Privacy Policy. These payment processors adhere to the standards set by
        PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the
        secure handling of payment information.
      </p>
      <p>They may include, but are not limited to:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Stripe</b> <br /> Their Privacy Policy can be viewed at{' '}
        <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>PayPal</b> <br /> Their Privacy Policy can be viewed at{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
        >
          {' '}
          https://www.paypal.com/us/webapps/mpp/ua/privacy-full
        </a>
      </li>
      <h3>4.4 - Behavioral Remarketing</h3>
      <p>
        The Company or Operator uses remarketing services to advertise to You after You accessed or
        visited our Service. We and Our third-party vendors use cookies and non-cookie technologies
        to help Us recognize Your Device and understand how You use our Service so that We can
        improve our Service to reflect Your interests and serve You advertisements that are likely
        to be of more interest to You.
      </p>
      <p>
        These third-party vendors collect, store, use, process and transfer information about Your
        activity on Our Service in accordance with their Privacy Policies and to enable Us to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Measure and analyze traffic and browsing activity on Our Service
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Show advertisements for our products and/or services to You on third-party websites or apps
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        Measure and analyze the performance of Our advertising campaigns
      </li>
      <p>
        Some of these third-party vendors may use non-cookie technologies that may not be impacted
        by browser settings that block cookies. Your browser may not permit You to block such
        technologies. You can use the following third-party tools to decline the collection and use
        of information for the purpose of serving You interest-based advertising:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        The NAI's opt-out platform:{' '}
        <a href="http://www.networkadvertising.org/choices/">
          http://www.networkadvertising.org/choices/
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        The EDAA's opt-out platform{' '}
        <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        The DAA's opt-out platform:{' '}
        <a href="http://optout.aboutads.info/?c=2&lang=EN">
          http://optout.aboutads.info/?c=2&lang=EN
        </a>
      </li>
      <p>
        You may opt-out of all personalized advertising by enabling privacy features on Your mobile
        device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See
        Your mobile device Help system for more information.
      </p>
      <p>
        We may share information, such as hashed email addresses (if available) or other online
        identifiers collected on Our Service with these third-party vendors. This allows Our
        third-party vendors to recognize and deliver You ads across devices and browsers. To read
        more about the technologies used by these third-party vendors and their cross-device
        capabilities please refer to the Privacy Policy of each vendor listed below.
      </p>
      <p>The third-party vendors We may use are, but are not limited to:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Google Ads (AdWords)</b> <br /> Google Ads (AdWords) remarketing service is provided by
        Google Inc.
        <br />
        You can opt-out of Google Analytics for Display Advertising and customise the Google Display
        Network ads by visiting the Google Ads Settings page:{' '}
        <a href="http://www.google.com/settings/ads">
          http://www.google.com/settings/ads
        </a> <br /> Google also recommends installing the Google Analytics Opt-out Browser Add-on -
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </a>{' '}
        - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the
        ability to prevent their data from being collected and used by Google Analytics. <br />
        For more information on the privacy practices of Google, please visit the Google Privacy &
        Terms web page:{' '}
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Twitter</b> Twitter remarketing service is provided by Twitter Inc. <br />
        You can opt-out from Twitter's interest-based ads by following their instructions:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://support.twitter.com/articles/20170405">
          https://support.twitter.com/articles/20170405{' '}
        </a>
        <br /> You can learn more about the privacy practices and policies of Twitter by visiting
        their Privacy Policy page:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://twitter.com/privacy">
          https://twitter.com/privacy
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Facebook / Meta</b>
        <br />
        Facebook or Meta remarketing service is provided by Facebook Inc. and Meta Inc.
        <br />
        You can learn more about interest-based advertising from Facebook by visiting this page:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/help/516147308587266">
          https://www.facebook.com/help/516147308587266
        </a>
        <br />
        To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/help/568137493302217">
          https://www.facebook.com/help/568137493302217
        </a>
        <br />
        Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising
        established by the Digital Advertising Alliance. You can also opt-out from Facebook and
        other participating companies through the Digital Advertising Alliance in the USA{' '}
        <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, the
        Digital Advertising Alliance of Canada in Canada{' '}
        <a href="http://youradchoices.ca/">http://youradchoices.ca/ </a>or the European Interactive
        Digital Advertising Alliance in Europe{' '}
        <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/ </a>, or opt-out
        using your mobile device settings.
        <br />
        For more information on the privacy practices of Facebook, please visit Facebook's Data
        Policy:{' '}
        <a style={{ wordBreak: 'break-word' }} href="https://www.facebook.com/privacy/explanation">
          https://www.facebook.com/privacy/explanation
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Pinterest</b> <br /> Pinterest remarketing service is provided by Pinterest Inc.
        <br />
        You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track"
        functionality of your web browser or by following Pinterest instructions:{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="http://help.pinterest.com/en/articles/personalization-and-data"
        >
          http://help.pinterest.com/en/articles/personalization-and-data
        </a>{' '}
        <br />
        You can learn more about the privacy practices and policies of Pinterest by visiting their
        Privacy Policy page:{' '}
        <a href="https://about.pinterest.com/en/privacy-policy">
          {' '}
          https://about.pinterest.com/en/privacy-policy
        </a>
      </li>
      <h3>4.5 - Usage, Performance and Miscellaneous</h3>
      <p>
        We may use third-party Service Providers to provide better improvement of our Service. They
        may include, but are not limited to:
      </p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Intercom</b> <br /> Their Privacy Policy can be viewed at{' '}
        <a href="https://www.intercom.com/legal/privacy">https://www.intercom.com/legal/privacy</a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Facebook Messenger</b> <br /> Their Privacy Policy can be viewed at{' '}
        <a href="https://www.facebook.com/privacy/policy">
          https://www.facebook.com/privacy/policy
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Zendesk</b> <br />
        Their Privacy Policy can be viewed at{' '}
        <a
          style={{ wordBreak: 'break-word' }}
          href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"
        >
          https://www.zendesk.com/company/agreements-and-terms/privacy-notice/
        </a>
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Tawk</b> <br /> Their Privacy Policy can be viewed at{' '}
        <a href="https://www.tawk.to/privacy-policy/"> https://www.tawk.to/privacy-policy/ </a>
      </li>
      <h2>5 - Links to Other Websites</h2>
      <p>
        Our Service may contain links to other websites that are not operated by Us. If You click on
        a third party link, You will be directed to that third party's site. We strongly advise You
        to review the Privacy Policy of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.
      </p>
      <h2>6 - GDPR Privacy</h2>
      <h3>6.1 - Legal Basis for Processing Personal Data under GDPR</h3>
      <p>We may process Personal Data under the following conditions:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Consent:</b> You have given Your consent for processing Personal Data for one or more
        specific purposes.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Performance of a contract:</b> Provision of Personal Data is necessary for the
        performance of an agreement with You and/or for any pre-contractual obligations thereof.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Legal obligations:</b> Processing Personal Data is necessary for compliance with a legal
        obligation to which the Company or Operator is subject.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Vital interests:</b> Processing Personal Data is necessary in order to protect Your vital
        interests or of another natural person.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Public interests:</b> Processing Personal Data is related to a task that is carried out
        in the public interest or in the exercise of official authority vested in the Company or
        Operator.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Legitimate interests:</b> Processing Personal Data is necessary for the purposes of the
        legitimate interests pursued by the Company or Operator.
      </li>
      <p>
        In any case, the Company or Operator will gladly help to clarify the specific legal basis
        that applies to the processing, and in particular whether the provision of Personal Data is
        a statutory or contractual requirement, or a requirement necessary to enter into a contract.
      </p>
      <h3>6.2 - Your Rights under the GDPR</h3>
      <p>
        The Company or Operator undertakes to respect the confidentiality of Your Personal Data and
        to guarantee You can exercise Your rights.
      </p>
      <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Request access to Your Personal Data.</b> The right to access, update or delete the
        information We have on You. Whenever made possible, you can access, update or request
        deletion of Your Personal Data directly within Your account settings section. If you are
        unable to perform these actions yourself, please contact Us to assist You. This also enables
        You to receive a copy of the Personal Data We hold about You.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Request correction of the Personal Data that We hold about You.</b> You have the right to
        have any incomplete or inaccurate information We hold about You corrected.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Object to processing of Your Personal Data.</b> This right exists where We are relying on
        a legitimate interest as the legal basis for Our processing and there is something about
        Your particular situation, which makes You want to object to our processing of Your Personal
        Data on this ground. You also have the right to object where We are processing Your Personal
        Data for direct marketing purposes.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Request erasure of Your Personal Data.</b> You have the right to ask Us to delete or
        remove Personal Data when there is no good reason for Us to continue processing it.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Request the transfer of Your Personal Data.</b> We will provide to You, or to a
        third-party You have chosen, Your Personal Data in a structured, commonly used,
        machine-readable format. Please note that this right only applies to automated information
        which You initially provided consent for Us to use or where We used the information to
        perform a contract with You.
      </li>
      <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
        <b>Withdraw Your consent.</b> You have the right to withdraw Your consent on using your
        Personal Data. If You withdraw Your consent, We may not be able to provide You with access
        to certain specific functionalities of the Service.
      </li>
      <h3>6.3 - Exercising of Your GDPR Data Protection Rights</h3>
      <p>
        You may exercise Your rights of access, rectification, cancellation and opposition by
        contacting Us. Please note that we may ask You to verify Your identity before responding to
        such requests. If You make a request, We will try our best to respond to You as soon as
        possible.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about Our collection and use
        of Your Personal Data. For more information, if You are in the European Economic Area (EEA),
        please contact Your local data protection authority in the EEA.
      </p>
      <h2>7 - Changes to this Privacy Policy</h2>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You of any changes by
        posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service, prior to the change
        becoming effective and update the "Last updated" date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.
      </p>
    </>
  );
}
