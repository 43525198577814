import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, convertLabel, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  FieldSelectModern,
  FieldVideoUpload,
  NamedLink,
  TranslateText,
  FieldCheckboxGroup,
  FieldTagInput,
  FieldInputCounter,
} from '../../components';

import css from './ProfileSettingsForm.module.css';
import config from '../../config';
import { AiTwotoneDelete } from 'react-icons/ai';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const TEACHING_STYLE_MAX_LENGTH = 1200;
const ABOUT_ME_MAX_LENGTH = 600;
class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            isLanguageEnglish,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const userId = user?.id?.uuid;
          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const maxLengthMessageTeachingStyle = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: TEACHING_STYLE_MAX_LENGTH,
            }
          );
          const maxLengthTeachingStyle = validators.maxLength(
            maxLengthMessageTeachingStyle,
            TEACHING_STYLE_MAX_LENGTH
          );
          const maxLengthMessageAboutMe = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: ABOUT_ME_MAX_LENGTH,
            }
          );
          const maxLengthAboutMe = validators.maxLength(
            maxLengthMessageAboutMe,
            ABOUT_ME_MAX_LENGTH
          );
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;
          const options = config.custom.countries || [];
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                </h3>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  <FormattedMessage id="ProfileSettingsForm.tip" />
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
              </div>
              <FieldSelectModern
                className={css.country}
                id="country"
                name="country"
                label={intl.formatMessage({
                  id: 'ProfileSettingsForm.ProfileSettingsForm.countryLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ProfileSettingsForm.ProfileSettingsForm.countryPlaceholder',
                })}
                options={options}
                validate={validators.required(
                  <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.countryRequiredMessage" />
                )}
              />
              {/* <div className={classNames(css.sectionContainer, css.lastSection)}> */}
              {/* <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.bioHeading" />
                </h3> */}
              {isLanguageEnglish ? (
                <>
                  <FieldTextInput
                    className={css.country}
                    type="textarea"
                    id="bio"
                    name="bio"
                    label={bioLabel}
                    placeholder={bioPlaceholder}
                    validate={validators.composeValidators(
                      validators.required(
                        <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.aboutMeRequired" />
                      ),
                      maxLengthAboutMe
                    )}
                    showCounter={true}
                    maxCharacters={ABOUT_ME_MAX_LENGTH}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.es_bio}
                        fromKey="es_bio"
                        toKey="bio"
                        target="en"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.es_bio}
                    fromKey="es_bio"
                    toKey="bio"
                    target="en"
                  /> */}
                  <FieldTextInput
                    className={css.country}
                    type="textarea"
                    id="es_bio"
                    name="es_bio"
                    label={intl.formatMessage({
                      id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioPlaceholder',
                    })}
                    // validate={maxLengthAboutMe}
                    validate={validators.composeValidators(
                      validators.required(
                        <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.aboutMeRequired" />
                      ),
                      maxLengthAboutMe
                    )}
                    showCounter={true}
                    maxCharacters={ABOUT_ME_MAX_LENGTH}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.bio}
                        fromKey="bio"
                        toKey="es_bio"
                        target="es"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.bio}
                    fromKey="bio"
                    toKey="es_bio"
                    target="es"
                  /> */}
                </>
              ) : (
                <>
                  <FieldTextInput
                    className={css.country}
                    type="textarea"
                    id="es_bio"
                    name="es_bio"
                    label={bioLabel}
                    placeholder={bioPlaceholder}
                    // label={intl.formatMessage({
                    //   id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioLabel',
                    // })}
                    // placeholder={intl.formatMessage({
                    //   id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioPlaceholder',
                    // })}
                    // validate={maxLengthAboutMe}
                    validate={validators.composeValidators(
                      validators.required(
                        <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.aboutMeRequired" />
                      ),
                      maxLengthAboutMe
                    )}
                    showCounter={true}
                    maxCharacters={ABOUT_ME_MAX_LENGTH}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.bio}
                        fromKey="bio"
                        toKey="es_bio"
                        target="es"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.bio}
                    fromKey="bio"
                    toKey="es_bio"
                    target="es"
                  /> */}
                  <FieldTextInput
                    className={css.country}
                    type="textarea"
                    id="bio"
                    name="bio"
                    label={intl.formatMessage({
                      id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.ProfileSettingsForm.spanishBioPlaceholder',
                    })}
                    // validate={maxLengthAboutMe}
                    validate={validators.composeValidators(
                      validators.required(
                        <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.aboutMeRequired" />
                      ),
                      maxLengthAboutMe
                    )}
                    showCounter={true}
                    maxCharacters={ABOUT_ME_MAX_LENGTH}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.es_bio}
                        fromKey="es_bio"
                        toKey="bio"
                        target="en"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.es_bio}
                    fromKey="es_bio"
                    toKey="bio"
                    target="en"
                  /> */}
                </>
              )}
              <FieldSelectModern
                className={css.country}
                id="profileType"
                name="profileType"
                label={intl.formatMessage({
                  id: 'ProfileSettingsForm.ProfileSettingsForm.profileTypeLabel',
                })}
                options={config.custom.profileTypes}
                validate={validators.required(
                  <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.profileTypeRequiredMessage" />
                )}
                onFieldChange={() => {
                  form.change(`teachingStyle`, undefined);
                  form.change(`learingGoals`, undefined);
                }}
              />

              {values?.profileType?.key === 'teacher' ? (
                <div>
                  {isLanguageEnglish ? (
                    <>
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="teachingStyle"
                        name="teachingStyle"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStyleLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStylePlaceholder',
                        })}
                        validate={validators.composeValidators(
                          validators.required(
                            <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.teachingStyleRequired" />
                          ),
                          maxLengthTeachingStyle
                        )}
                        showCounter={true}
                        maxCharacters={TEACHING_STYLE_MAX_LENGTH}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.es_teachingStyle}
                            fromKey="es_teachingStyle"
                            toKey="teachingStyle"
                            target="en"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.es_teachingStyle}
                        fromKey="es_teachingStyle"
                        toKey="teachingStyle"
                        target="en"
                      /> */}
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="es_teachingStyle"
                        name="es_teachingStyle"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStyleLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id:
                            'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStylePlaceholder',
                        })}
                        validate={validators.composeValidators(
                          validators.required(
                            <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.SpanishteachingStyleRequired" />
                          ),
                          maxLengthTeachingStyle
                        )}
                        showCounter={true}
                        maxCharacters={TEACHING_STYLE_MAX_LENGTH}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.teachingStyle}
                            fromKey="teachingStyle"
                            toKey="es_teachingStyle"
                            target="es"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.teachingStyle}
                        fromKey="teachingStyle"
                        toKey="es_teachingStyle"
                        target="es"
                      /> */}
                    </>
                  ) : (
                    <>
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="es_teachingStyle"
                        name="es_teachingStyle"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStyleLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStylePlaceholder',
                        })}
                        // label={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStyleLabel',
                        // })}
                        // placeholder={intl.formatMessage({
                        //   id:
                        //     'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStylePlaceholder',
                        // })}
                        validate={validators.composeValidators(
                          validators.required(
                            <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.SpanishteachingStyleRequired" />
                          ),
                          maxLengthTeachingStyle
                        )}
                        showCounter={true}
                        maxCharacters={TEACHING_STYLE_MAX_LENGTH}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.teachingStyle}
                            fromKey="teachingStyle"
                            toKey="es_teachingStyle"
                            target="es"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.teachingStyle}
                        fromKey="teachingStyle"
                        toKey="es_teachingStyle"
                        target="es"
                      /> */}

                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="teachingStyle"
                        name="teachingStyle"
                        // label={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStyleLabel',
                        // })}
                        // placeholder={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.teachingStylePlaceholder',
                        // })}
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStyleLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id:
                            'ProfileSettingsForm.ProfileSettingsForm.spanishTeachingStylePlaceholder',
                        })}
                        validate={validators.composeValidators(
                          validators.required(
                            <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.teachingStyleRequired" />
                          ),
                          maxLengthTeachingStyle
                        )}
                        showCounter={true}
                        maxCharacters={TEACHING_STYLE_MAX_LENGTH}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.es_teachingStyle}
                            fromKey="es_teachingStyle"
                            toKey="teachingStyle"
                            target="en"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.es_teachingStyle}
                        fromKey="es_teachingStyle"
                        toKey="teachingStyle"
                        target="en"
                      /> */}
                    </>
                  )}
                  <div className={css.teachingPage}>
                    <FieldArray name="languages_spoken">
                      {({ fields }) => (
                        <div>
                          <h3 className={css.languageHeading}>
                            {intl.formatMessage({
                              id:
                                'EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenLabel',
                            })}
                          </h3>
                          {fields.map((name, index) => (
                            <div key={name} className={css.fieldArrayContainer}>
                              <FieldSelectModern
                                className={css.language}
                                id={`${name}.language`}
                                name={`${name}.language`}
                                placeholder={intl.formatMessage({
                                  id:
                                    'EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenPlaceholder',
                                })}
                                options={config.custom.languageOptions}
                                validate={validators.required(
                                  <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenReq" />
                                )}
                              />
                              <div className={css.subContainer}>
                                <FieldSelectModern
                                  className={css.level}
                                  id={`${name}.level`}
                                  name={`${name}.level`}
                                  placeholder={intl.formatMessage({
                                    id:
                                      'EditListingPoliciesForm.EditListingPoliciesForm.levelPlaceholder',
                                  })}
                                  options={config.custom.levelOptions}
                                  validate={validators.required(
                                    <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.levelRequiredMessage" />
                                  )}
                                />
                                {values?.languages_spoken?.length > 1 && (
                                  <p className={css.close} onClick={() => fields.remove(index)}>
                                    <AiTwotoneDelete className={css.delete} />
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                          <p
                            className={css.addAnotherLanguage}
                            onClick={() => fields.push({ language: '', level: '' })}
                          >
                            {intl.formatMessage({
                              id:
                                'EditListingPoliciesForm.EditListingPoliciesForm.addAnotherLanguageLabel',
                            })}
                          </p>
                        </div>
                      )}
                    </FieldArray>
                    <FieldArray name="languages_taught">
                      {({ fields }) => (
                        <div>
                          <h3 className={css.languageHeading}>
                            {intl.formatMessage({
                              id:
                                'EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtLabel',
                            })}
                          </h3>
                          {fields.map((name, index) => (
                            <div key={name} className={css.fieldArrayContainer}>
                              <FieldSelectModern
                                className={css.language}
                                id={`${name}.language`}
                                name={`${name}.language`}
                                placeholder={intl.formatMessage({
                                  id:
                                    'EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtPlaceholder',
                                })}
                                options={config.custom.languageOptions}
                                validate={validators.required(
                                  <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtReq" />
                                )}
                              />
                              <div className={css.subContainer}>
                                <FieldSelectModern
                                  className={css.level}
                                  id={`${name}.level`}
                                  name={`${name}.level`}
                                  isMulti={true}
                                  placeholder={intl.formatMessage({
                                    id:
                                      'EditListingPoliciesForm.EditListingPoliciesForm.levelTaughtPlaceholder',
                                  })}
                                  options={config.custom.levelOptions?.filter(
                                    i => i.key !== 'Native'
                                  )}
                                  validate={validators.required(
                                    <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.levelTaughtRequired" />
                                  )}
                                />
                                {values?.languages_taught?.length > 1 && (
                                  <p className={css.close} onClick={() => fields.remove(index)}>
                                    <AiTwotoneDelete className={css.delete} />
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                          <p
                            className={css.addAnotherLanguage}
                            onClick={() => fields.push({ language: '', level: '' })}
                          >
                            {intl.formatMessage({
                              id:
                                'EditListingPoliciesForm.EditListingPoliciesForm.addAnotherLanguageTaught',
                            })}
                          </p>
                        </div>
                      )}
                    </FieldArray>
                    <FieldInputCounter
                      className={css.policy}
                      id="yearsOfExperience"
                      name="yearsOfExperience"
                      type="number"
                      min={1}
                      label={intl.formatMessage({
                        id:
                          'EditListingPoliciesForm.EditListingPoliciesForm.yearsOfExperienceLabel',
                      })}
                      max={50}
                      isBlock={true}
                    />
                    <FieldSelectModern
                      className={css.policy}
                      id="customOption"
                      name="customOption"
                      placeholder={intl.formatMessage({
                        id: 'EditListingPoliciesForm.EditListingPoliciesForm.selectPlaceholder',
                      })}
                      label={<FormattedMessage id="EditListingPoliciesForm.customOptionLabel" />}
                      options={config.custom.customOptions}
                      validate={validators.requiredFieldArrayCheckbox(
                        <FormattedMessage id="EditListingPoliciesForm.customOptionRequired" />
                      )}
                    />
                    {values?.customOption?.key === 'yes' ? (
                      <div className={css.otherField}>
                        <FieldCheckboxGroup
                          id="customOptionList"
                          name="customOptionList"
                          options={config.custom.customProgramOptions}
                          validate={validators.requiredFieldArrayCheckbox(
                            <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.customOptionRequiredMessage" />
                          )}
                          twoColumns={true}
                        />
                        {values?.customOptionList?.includes('other') ? (
                          <FieldTagInput
                            id="otherCustomOption"
                            name="otherCustomOption"
                            onlyUnique
                            inputProps={{
                              placeholder: intl.formatMessage({
                                id:
                                  'EditListingPoliciesForm.EditListingPoliciesForm.otherCustomOptionPlaceholder',
                              }),
                            }}
                          />
                        ) : null}
                      </div>
                    ) : null}

                    <FieldSelectModern
                      className={css.policy}
                      id="qualifiedTeacher"
                      name="qualifiedTeacher"
                      label={
                        <FormattedMessage id="EditListingPoliciesForm.qualifiedTeacherLabel" />
                      }
                      placeholder={intl.formatMessage({
                        id: 'EditListingPoliciesForm.EditListingPoliciesForm.selectPlaceholder',
                      })}
                      options={config.custom.qualifiedTeacherOptions}
                      validate={validators.requiredFieldArrayCheckbox(
                        <FormattedMessage id="EditListingPoliciesForm.qualifiedTeacherRequired" />
                      )}
                    />
                    {values?.qualifiedTeacher?.key === 'yes' ? (
                      <div className={css.otherField}>
                        <FieldCheckboxGroup
                          id="qualification"
                          name="qualification"
                          options={config.custom.qualificationOptions}
                          validate={validators.requiredFieldArrayCheckbox(
                            <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.qualificationReq" />
                          )}
                        />
                        {values?.qualification?.includes('other') ? (
                          <>
                            <label className={css.label}></label>
                            <FieldTagInput
                              // className={css.policy}
                              id="otherQualification"
                              name="otherQualification"
                              onlyUnique
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id:
                                    'EditListingPoliciesForm.EditListingPoliciesForm.qualificationPlaceholder',
                                }),
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <h3>
                    {intl.formatMessage({
                      id: 'ProfileSettingsForm.ProfileSettingsForm.uploadVideoLabel',
                    })}
                  </h3>
                  <FieldVideoUpload
                    parentValues={values}
                    urlParam={userId}
                    parentForm={form}
                    publicDataHint="videos"
                  />
                  <div style={{ marginTop: '16px' }}>
                    <p className={css.videoTip}>
                      <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.UploadVideoNoteOne" />
                    </p>
                    <p className={css.videoTip}>
                      <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.uploadVideoNoteTwo" />
                    </p>
                    <p className={css.videoTip}>
                      <FormattedMessage
                        id="ProfileSettingsForm.ProfileSettingsForm.uploadVideoNoteThree"
                        values={{
                          link: (
                            <NamedLink className={css.link} name="VideoGuidelinePage">
                              <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.uploadVideoNoteThreeLink" />
                            </NamedLink>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              ) : null}
              {values?.profileType?.key === 'student' ? (
                <>
                  {isLanguageEnglish ? (
                    <>
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="learingGoals"
                        name="learingGoals"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.learingLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.learningPlaceholder',
                        })}
                        // validate={validators.required(
                        //   <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.learningRequired" />
                        // )}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.es_learingGoals}
                            fromKey="es_learingGoals"
                            toKey="learingGoals"
                            target="en"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.es_learingGoals}
                        fromKey="es_learingGoals"
                        toKey="learingGoals"
                        target="en"
                      /> */}
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="es_learingGoals"
                        name="es_learingGoals"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id:
                            'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalPlaceholder',
                        })}
                        // validate={validators.required(
                        //   <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.learningRequired" />
                        // )}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.learingGoals}
                            fromKey="learingGoals"
                            toKey="es_learingGoals"
                            target="es"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.learingGoals}
                        fromKey="learingGoals"
                        toKey="es_learingGoals"
                        target="es"
                      /> */}
                    </>
                  ) : (
                    <>
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="es_learingGoals"
                        name="es_learingGoals"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.learingLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.learningPlaceholder',
                        })}
                        // label={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalLabel',
                        // })}
                        // placeholder={intl.formatMessage({
                        //   id:
                        //     'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalPlaceholder',
                        // })}
                        // validate={validators.required(
                        //   <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.learningRequired" />
                        // )}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.learingGoals}
                            fromKey="learingGoals"
                            toKey="es_learingGoals"
                            target="es"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.learingGoals}
                        fromKey="learingGoals"
                        toKey="es_learingGoals"
                        target="es"
                      /> */}
                      <FieldTextInput
                        className={css.country}
                        type="textarea"
                        id="learingGoals"
                        name="learingGoals"
                        label={intl.formatMessage({
                          id: 'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id:
                            'ProfileSettingsForm.ProfileSettingsForm.spanishLearningGoalPlaceholder',
                        })}
                        // label={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.learingLabel',
                        // })}
                        // placeholder={intl.formatMessage({
                        //   id: 'ProfileSettingsForm.ProfileSettingsForm.learningPlaceholder',
                        // })}
                        // validate={validators.required(
                        //   <FormattedMessage id="ProfileSettingsForm.ProfileSettingsForm.learningRequired" />
                        // )}
                        transalateComponent={
                          <TranslateText
                            form={form}
                            value={values?.es_learingGoals}
                            fromKey="es_learingGoals"
                            toKey="learingGoals"
                            target="en"
                          />
                        }
                      />
                      {/* <TranslateText
                        form={form}
                        value={values?.es_learingGoals}
                        fromKey="es_learingGoals"
                        toKey="learingGoals"
                        target="en"
                      /> */}
                    </>
                  )}
                </>
              ) : null}
              {/* <p className={css.bioInfo}>
                  <FormattedMessage id="ProfileSettingsForm.bioInfo" />
                </p>
              </div> */}
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
