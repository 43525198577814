import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import config from '../../config';
import {
  Button,
  FieldInputCounter,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
  TranslateText,
} from '../../components';
import { propTypes } from '../../util/types';
import css from './DurationForm.module.css';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  maxLength,
  required,
  zeroNumberValidate,
} from '../../util/validators';
import { ACTIVITY, PRIVATE_TOUR } from '../../components/EditListingWizard/EditListingWizardTab';
const MEETING_POINT_MAX = 100;
const TRANSPORT_MAX = 300;
const identity = v => v;
const ActivityTransportationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        invalid,
        form,
        values,
        isLanguageEnglish,
        intl,
        category,
      } = formRenderProps;
      const isActivity = category == ACTIVITY;
      const isPrivateTour = category == PRIVATE_TOUR;
      console.log({ isActivity, isPrivateTour });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: MEETING_POINT_MAX,
        }
      );
      const maxLengthTMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TRANSPORT_MAX,
        }
      );

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = (
        <span className={css.optional}>
          {' '}
          {intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          })}
        </span>
      );

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });
      const maxLengthMeetingMessage = maxLength(maxLengthMessage, MEETING_POINT_MAX);
      const maxLengthTransportMessage = maxLength(maxLengthTMessage, TRANSPORT_MAX);
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="DurationForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="DurationForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <p className={css.note}>
            {isPrivateTour
              ? intl.formatMessage({
                  id: 'DurationForm.ActivityTransportationForm.privateTourNote',
                })
              : intl.formatMessage({ id: 'DurationForm.ActivityTransportationForm.activityNote' })}
          </p>
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            labelClassName={css.label}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <FieldTextInput
            className={css.building}
            type="text"
            name="building"
            id="building"
            label={buildingMessage}
            placeholder={buildingPlaceholderMessage}
          />
          {isActivity ? (
            <div>
              {isLanguageEnglish ? (
                <div>
                  <FieldTextInput
                    id="transportation"
                    name="transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationLabel',
                    })}
                    type="textarea"
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationPlaceholder',
                    })}
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'DurationForm.DurationForm.transportationRequired',
                        })
                      ),
                      maxLengthTransportMessage
                    )}
                    showCounter={true}
                    maxCharacters={TRANSPORT_MAX}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.es_transportation}
                        fromKey="es_transportation"
                        toKey="transportation"
                        target="en"
                        id="transportation"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.es_transportation}
                    fromKey="es_transportation"
                    toKey="transportation"
                    target="en"
                    id="transportation"
                  /> */}
                  <FieldTextInput
                    id="es_transportation"
                    name="es_transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transporationSpanishLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationSpanishPlaceholder',
                    })}
                    type="textarea"
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'DurationForm.DurationForm.transportationRequired',
                        })
                      ),
                      maxLengthTransportMessage
                    )}
                    showCounter={true}
                    maxCharacters={TRANSPORT_MAX}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.transportation}
                        fromKey="transportation"
                        toKey="es_transportation"
                        target="es"
                        id="es_transportation"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.transportation}
                    fromKey="transportation"
                    toKey="es_transportation"
                    target="es"
                    id="es_transportation"
                  /> */}
                </div>
              ) : (
                <div>
                  <FieldTextInput
                    id="es_transportation"
                    name="es_transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transporationSpanishLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationPlaceholder',
                    })}
                    type="textarea"
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'DurationForm.DurationForm.transportationRequired',
                        })
                      ),
                      maxLengthTransportMessage
                    )}
                    showCounter={true}
                    maxCharacters={TRANSPORT_MAX}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.transportation}
                        fromKey="transportation"
                        toKey="es_transportation"
                        target="es"
                        id="es_transportation"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.transportation}
                    fromKey="transportation"
                    toKey="es_transportation"
                    target="es"
                    id="es_transportation"
                  /> */}
                  <FieldTextInput
                    id="transportation"
                    name="transportation"
                    className={css.counter}
                    label={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DurationForm.DurationForm.transportationSpanishPlaceholder',
                    })}
                    type="textarea"
                    validate={composeValidators(
                      required(
                        intl.formatMessage({
                          id: 'DurationForm.DurationForm.transportationRequired',
                        })
                      ),
                      maxLengthTransportMessage
                    )}
                    showCounter={true}
                    maxCharacters={TRANSPORT_MAX}
                    transalateComponent={
                      <TranslateText
                        form={form}
                        value={values?.es_transportation}
                        fromKey="es_transportation"
                        toKey="transportation"
                        target="en"
                        id="transportation"
                      />
                    }
                  />
                  {/* <TranslateText
                    form={form}
                    value={values?.es_transportation}
                    fromKey="es_transportation"
                    toKey="transportation"
                    target="en"
                    id="transportation"
                  /> */}
                </div>
              )}
            </div>
          ) : null}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

ActivityTransportationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

ActivityTransportationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const ActivityTransportationForm = ActivityTransportationFormComponent;

export default injectIntl(ActivityTransportationForm);
