import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldVideoUpload, NamedLink } from '../../components';

import css from './EditListingVideosForm.module.css';
import { composeValidators, required } from '../../util/validators';

export const EditListingVideosFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        currentListing,
        id,
        previous,
        slug,
        backButtonMessage,
        editListingLinkType,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingVideosForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingVideosForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const currentListingId = currentListing.id.uuid;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldVideoUpload
            parentValues={values}
            urlParam={currentListingId}
            parentForm={form}
            publicDataHint="videos"
          />
          <span style={{ color: 'grey', fontSize: '12px', marginTop: '30px' }}>
            *Maximum video size cannot exceed 500mb.
          </span>
          <div className={css.buttons}>
            {editListingLinkType === 'draft' ? (
              <NamedLink
                // className={previous}
                name="EditListingPage"
                params={{ id, slug, type: editListingLinkType, tab: 'photos' }}
              >
                {/* <BsFillArrowLeftCircleFill className={css.backIcon} /> */}
                {backButtonMessage}
                {/* Back */}
              </NamedLink>
            ) : null}
            <NamedLink className={css.link} name="VideoGuidelinePage">
              <FormattedMessage id="EditListingPhotosForm.EditListingVideosForm.guideline" />
            </NamedLink>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
              {/* <AiOutlineArrowRight /> */}
            </Button>
          </div>
          {/* <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button> */}
        </Form>
      );
    }}
  />
);

EditListingVideosFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingVideosFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingVideosFormComponent);
