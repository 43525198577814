import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;
export const customerCommission = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE
  ? +process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE
  : 10;

export function getTotalWithCommission(amount) {
  const commissionAmount = (amount * customerCommission) / 100;
  const total = amount + commissionAmount;
  return total;
}
export function getTotalWithCommissionWithRound(amount) {
  const commissionAmount = (amount * customerCommission) / 100;
  const total = amount + commissionAmount;
  const roundTotal = Math.ceil(total / 100) * 100;
  return roundTotal;
  return total;
}
/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};
const lineItemsTotalCustomer = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    const totalAmount = getTotalWithCommission(item.lineTotal.amount);
    console.log(totalAmount);
    return total.plus(totalAmount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(item => !isCommission(item) && item.reversal);
};
// Function to check line items
function checkLineTotal(lineItems = [], target) {
  return lineItems.filter(item => item.includeFor.includes(target));
}
const LineItemRefundMaybe = props => {
  const { transaction, intl, customer } = props;

  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(transaction);
  const refund = customer
    ? checkLineTotal(refundLineItems, 'customer')
    : //  lineItemsTotalCustomer(refundLineItems)
      checkLineTotal(refundLineItems, 'provider');
  // lineItemsTotal(refundLineItems);
  const formattedRefund =
    refundLineItems.length > 0 && refund?.length ? formatMoney(intl, refund[0]?.lineTotal) : null;

  return formattedRefund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refund" />
      </span>
      <span className={css.itemValue}>{formattedRefund}</span>
    </div>
  ) : null;
};

LineItemRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
