import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import bgImage from '../../assets/backgroundNew.png';
import css from './SectionHeroUI.module.css';
import config from '../../config';
import { SearchBar } from '../../components';
const SectionHeroUI = props => {
  const { rootClassName, className, onSearchSubmit } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div>
      <div className={classes}>
        <div className={css.heroContent}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHeroUI.title" />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHeroUI.subTitle" />
          </h2>
          <div className={css.landingPageMobile}>
            <SearchBar onSubmit={onSearchSubmit} />
          </div>
        </div>
        <img className={css.heroImage} src={bgImage} />
      </div>
      {/* <div className={css.landingPageSearch}>
        <SearchBar
          onSubmit={() => {
            console.log('SearchBar onSubmit');
          }}
        />
      </div> */}
    </div>
  );
};

SectionHeroUI.defaultProps = { rootClassName: null, className: null };

SectionHeroUI.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroUI;
