import React, { useEffect } from 'react';

export default function TermsSp({ divId }) {
  useEffect(() => {
    if (divId === '#order-cancel') {
      document.getElementById('order-cancel').scrollIntoView();
    } else {
      return;
    }
  }, []);
  return (
    <>
      <h1>Condiciones Generales de Uso</h1>
      <p>
        ¡Gracias por utilizar LinGo Immersion! A continuación, te presentamos las Condiciones
        Generales de Uso. Para cualquier cuestión relativa a este tema no dude en escribirnos a
        <a href="mailto:contact@lingoimmersion.com">contact@lingoimmersion.com</a>
      </p>
      <p>
        Lea atentamente las siguientes CONDICIONES GENERALES DE USO antes de utilizar nuestro
        Servicio.
      </p>
      <p>Fecha de vigencia: abril de 2023</p>
      <h2> 1 - Interpretación y Definiciones</h2>
      <h3>1.1 - Interpretación</h3>
      <p>
        Las palabras cuya letra inicial está en mayúscula tienen significados definidos en el punto
        1.2. Las siguientes definiciones tendrán el mismo significado independientemente de que
        aparezcan en singular o en plural.
      </p>
      <h3>1 - Definición</h3>
      <p>A los efectos de estas Condiciones de servicio: :</p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Aplicación</b> significa el programa de software proporcionado por la Compañía
          descargado por Usted en cualquier dispositivo electrónico, denominado LinGo Immersion.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Bienes</b> se refiere a los servicios ofrecidos como medio de comercio en el Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Comentarios</b> significa comentarios, innovaciones o sugerencias enviadas por Usted
          con respecto a los atributos, el rendimiento o las características de nuestro Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Compañía</b> (referida como "la Compañía" o "Nosotros") se refiere a LinGo Immersion.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Comprador</b> se refiere a los usuarios del Servicio que realizan Pedidos de Bienes.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Contenido</b> se refiere a contenido como texto, imágenes u otra información que Usted
          puede publicar, cargar, vincular o poner a disposición de otro modo, independientemente de
          la forma de ese contenido.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Cuenta</b> significa una cuenta única creada para que Usted acceda a nuestro Servicio o
          a partes de nuestro Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Dispositivo</b> significa cualquier dispositivo que pueda acceder al Servicio, como un
          ordenador, un teléfono móvil o una tableta digital.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Pedido</b> significa una solicitud por parte de Usted para comprar o comercializar por
          cualquier medio Bienes en la Aplicación o el Sitio web.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Servicio</b> se refiere a la Aplicación o al Sitio Web o a ambos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b> Servicio de Redes Rociales de Terceros</b> significa cualquier servicio o contenido
          (incluidos datos, información, productos o servicios) proporcionado por un tercero que
          puede mostrarse, incluirse o ponerse a disposición mediante el Servicio.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Sitio web </b> se refiere a lingoimmersion.com, accesible desde la URL
          https://www.lingoimmersion.com.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Usted</b> (también referido como Ud.) se refiere a la persona que accede o utiliza el
          Servicio, o la Compañía u otra entidad legal en nombre de la cual dicha persona accede o
          utiliza el Servicio, según corresponda.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          <b>Vendedor</b> se refiere a los usuarios del Servicio que publican Bienes y los ponen a
          disposición para el comercio.
        </li>
      </ul>
      <h2>2 - Contacto</h2>
      <p>
        Si tiene alguna pregunta sobre estas Condiciones Generales de Uso, puede contactarnos: :
      </p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Por correo electrónico:{' '}
          <a href="mailto:contact@lingoimmersion.com">contact@lingoimmersion.com</a>
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Visitando esta página en nuestro sitio web:{' '}
          <a href="https://www.lingoimmersion.com/contact">
            https://www.lingoimmersion.com/contact
          </a>
          .
        </li>
      </ul>
      <h2>3 - Objeto</h2>
      <p>
        Estas son las Condiciones Generales que rigen el uso de este Servicio y el acuerdo que opera
        entre Usted y la Compañía. Estas Condiciones Generales de Uso establecen los derechos y
        obligaciones de todos los usuarios con respecto al uso del Servicio.
      </p>
      <p>
        Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estas
        Condiciones Generales de Uso. Estas Condiciones Generales de Uso se aplican a todos los
        visitantes, usuarios y otras personas que acceden o usan el Servicio.
      </p>
      <p>
        Al acceder o utilizar el Servicio, Usted acepta estar sujeto a estas Condiciones Generales
        de Uso. Si no está de acuerdo con alguna parte de estas Condiciones Generales de Uso, no
        acceda al Servicio.
      </p>
      <p>
        Usted declara que es mayor de edad de acuerdo con las leyes de su país o de España,
        cualquiera que sea mayor. La Compañía no permite que los menores de esa edad utilicen el
        Servicio.
      </p>
      <p>
        Su acceso y uso del Servicio también está condicionado a su aceptación y cumplimiento de la
        Política de Privacidad de la Compañía. Nuestra Política de privacidad describe Nuestras
        políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información
        personal cuando utiliza la Aplicación o el Sitio web y le informa sobre sus derechos de
        privacidad y cómo la ley lo protege. Lea detenidamente Nuestra Política de Privacidad antes
        de utilizar nuestro Servicio.
      </p>
      <h2>4 - Ley Aplicable</h2>
      <p>
        Las leyes de España, excluyendo sus conflictos de leyes, regirán estas Condiciones Generales
        de Uso y su uso del Servicio. Su uso de la Aplicación o el Sitio web también puede estar
        sujeto a otras leyes locales, estatales, nacionales o internacionales.
      </p>
      <h3>4.1 – Para Usuarios de la Unión Europea (UE)</h3>
      <p>
        Si es Ud. un consumidor de la Unión Europea, se beneficiará de las disposiciones
        obligatorias de la ley del país en el que reside.
      </p>
      <h3>4.2 – Divisibilidad</h3>
      <p>
        Si alguna disposición de estas Condiciones se considera inaplicable o inválida, dicha
        disposición se cambiará e interpretará para lograr los objetivos de dicha disposición en la
        mayor medida posible según la ley aplicable y las disposiciones restantes continuarán en
        pleno vigor y efecto.
      </p>
      <h3>4.3 – Exención</h3>
      <p>
        Salvo lo dispuesto en el presente, la falta de ejercer un derecho o de exigir el
        cumplimiento de una obligación en virtud de estas Condiciones no afectará la capacidad de
        una parte para ejercer dicho derecho o exigir dicho cumplimiento en cualquier momento
        posterior, ni la renuncia a un incumplimiento constituirá una renuncia de cualquier
        incumplimiento posterior.
      </p>
      <h2>5 – Cuentas de Usuarios</h2>
      <h3>5.1 – Creación de la Cuenta</h3>
      <p>
        Cuando Usted crea una cuenta con Nosotros, Ud. debe proporcionarnos información que sea
        precisa, completa y actualizada en todo momento. El no hacerlo constituye un incumplimiento
        de las Condiciones, lo que puede resultar en la terminación inmediata de su cuenta en
        nuestro Servicio.
      </p>
      <p>
        No puede usar como nombre de usuario el nombre de otra persona o entidad o que no esté
        legalmente disponible para su uso, un nombre o marca registrada que esté sujeto a cualquier
        derecho de otra persona o entidad que no sea Usted sin la autorización correspondiente, o un
        nombre que resulte ofensivo, vulgar u obsceno.
      </p>
      <h3>5.2 – Información de la Cuenta</h3>
      <p>
        Es posible que se le solicite que proporcione cierta información relevante para su cuenta,
        que incluye, entre otros, su nombre, su correo electrónico, su número de teléfono y su
        dirección.
      </p>
      <p>
        Es posible que deba proporcionar documentos para cumplir con la verificación de identidad.
      </p>
      <p>
        Antes o durante el envío de mercancías, se le puede solicitar que proporcione, sin
        limitación, los detalles de su cuenta bancaria y sus documentos de identidad.
      </p>
      <p>
        Antes o durante la realización de un Pedido, se le puede solicitar que proporcione, entre
        otros, su número de tarjeta de crédito, la fecha de vencimiento de su tarjeta de crédito y
        su dirección de facturación.
      </p>
      <h3>5.3 – Revisión de la Cuenta</h3>
      <p>
        Aunque la Compañía revisa la información de la cuenta, no se hace responsable de la
        veracidad, precisión e integridad de la información proporcionada por los usuarios.
      </p>
      <h3>5.4 - Contraseña de la Cuenta</h3>
      <p>
        Usted es responsable de salvaguardar la contraseña que usa para acceder al Servicio y de
        cualquier actividad o acción bajo su contraseña, ya sea que su contraseña sea con nuestro
        Servicio o con un Servicio de Redes Sociales de Terceros.
      </p>
      <p>
        Usted acepta no revelar su contraseña a ningún tercero. Debe notificarnos inmediatamente si
        Ud. es consciente de cualquier violación de seguridad o uso no autorizado de su cuenta.
      </p>
      <h3>5.5 - Terminación de la Cuenta</h3>
      <p>
        Podemos rescindir o suspender su cuenta de inmediato, sin previo aviso ni responsabilidad,
        por cualquier motivo, incluido, entre otros, si incumple estas Condiciones Generales de Uso.
        Tras la rescisión, su derecho a utilizar el Servicio cesará inmediatamente.
      </p>
      <p>
        Si desea cancelar su Cuenta, simplemente puede dejar de usar el Servicio o eliminar su
        Cuenta del Servicio, o comunicarse con Nosotros para obtener ayuda.
      </p>
      <h2>6 – Contenido</h2>
      <h3>6.1 - Su Derecho a Publicar Contenido</h3>
      <p>
        Nuestro Servicio le permite publicar Contenido. Usted es responsable del Contenido que
        publica en el Servicio, incluida su legalidad, veracidad y adecuación.
      </p>
      <p>
        Al publicar Contenido en el Servicio, nos otorga el derecho y la licencia para usar, mostrar
        públicamente, modificar, reproducir y distribuir dicho Contenido en el Servicio y a través
        de este. Usted conserva todos y cada uno de sus derechos sobre cualquier Contenido que
        envíe, publique o muestre en o a través del Servicio y es responsable de proteger esos
        derechos. Usted da el consentimiento de que Nosotros pongamos su Contenido a disposición de
        otros usuarios del Servicio, quienes también pueden usar su Contenido sujeto a estas
        Condiciones.
      </p>
      <p>
        Usted declara y garantiza que: (i) el Contenido es suyo (Ud. es el dueño) o tiene derecho a
        usarlo y otorgarnos los derechos y la licencia según lo dispuesto en estas Condiciones, y
        (ii) la publicación de su contenido en o a través del Servicio no viola los derechos de
        privacidad, derechos de publicidad, derechos de autor o cualquier otro derecho de cualquier
        persona.
      </p>
      <h3>6.2 – Restricciones de Contenido</h3>
      <p>
        La Compañía no es responsable por el contenido de los usuarios del Servicio. Usted comprende
        y acepta expresamente que es el único responsable del Contenido y de toda la actividad que
        ocurra en su cuenta, ya sea que la realice Usted o cualquier tercero que use su cuenta.
      </p>
      <p>
        No puede transmitir ningún Contenido que sea ilegal, ofensivo, perturbador, con la intención
        de disgustar, amenazante, calumnioso, difamatorio, obsceno o de otra manera objetable. Los
        ejemplos de dicho Contenido objetable incluyen, entre otros, los siguientes:
      </p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Actividades ilícitas o que promuevan actividades ilícitas.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Contenido difamatorio, discriminatorio o mezquino, incluidas referencias o comentarios
          sobre religión, raza, orientación sexual, género, origen nacional/étnico u otros grupos
          específicos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Publicidad spam, generada aleatoriamente o por una máquina, que constituya publicidad no
          autorizada o no solicitada, cartas en cadena, cualquier otra forma de solicitud no
          autorizada, o cualquier forma de lotería o juego.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Contener o instalar virus, gusanos, malware, troyanos u otro contenido diseñado o
          destinado a interrumpir, dañar o limitar el funcionamiento de cualquier software, hardware
          o equipo de telecomunicaciones o para dañar u obtener acceso no autorizado a cualquier
          dato u otra información de una tercera persona.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Infringir los derechos de propiedad de cualquier parte, incluidos patentes, marcas
          registradas, secretos comerciales, derechos de autor, derechos de publicidad u otros
          derechos.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Hacerse pasar por cualquier persona o entidad, incluida la Compañía y sus empleados o
          representantes.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Violar la privacidad de cualquier tercera persona.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Información y características falsas.
        </li>
      </ul>
      <p>
        La Compañía se reserva el derecho, pero no la obligación, de, a su exclusivo criterio,
        determinar si algún Contenido es o no apropiado y cumple con estas Condiciones, rechazar o
        eliminar este Contenido. La Compañía se reserva además el derecho de formatear, editar y
        cambiar la forma de cualquier Contenido. La Compañía también puede limitar o revocar el uso
        del Servicio si publica dicho Contenido objetable. Como la Compañía no puede controlar todo
        el contenido publicado por los usuarios y/o terceros en el Servicio, Usted acepta usar el
        Servicio bajo su propio riesgo. Usted comprende que al usar el Servicio puede estar expuesto
        a contenido que puede encontrar ofensivo, indecente, incorrecto u objetable, y acepta que,
        bajo ninguna circunstancia, la Compañía será responsable de ningún modo por ningún
        contenido, incluidos los errores u omisiones en cualquier contenido, o cualquier pérdida o
        daño de cualquier tipo incurrido como resultado del uso que Ud. haga de cualquier contenido.
      </p>
      <h3>6.3 - Copias de Seguridad de Contenido</h3>
      <p>
        Aunque se realizan copias de seguridad regulares del Contenido, la Compañía no garantiza que
        no habrá pérdida o corrupción de datos.
      </p>
      <p>
        Los puntos de copia de seguridad corruptos o inválidos pueden ser causados, entre otros, por
        Contenido que está dañado antes de que se realice la copia de seguridad o que cambia durante
        el tiempo que se realiza la copia de seguridad.
      </p>
      <p>
        La Compañía brindará soporte e intentará solucionar cualquier problema conocido o
        descubierto que pueda afectar a las copias de seguridad del Contenido. Pero Usted reconoce
        que la Compañía no tienen ninguna responsabilidad relacionada con la integridad del
        Contenido o el fallo en restaurar el Contenido a un estado utilizable.
      </p>
      <p>
        Usted acepta mantener una copia completa y precisa de cualquier Contenido en una ubicación
        independiente del Servicio.
      </p>
      <h3>6.4 - Propiedad Intelectual de Terceros e Infracción de Derechos de Autor</h3>
      <p>
        Respetamos la propiedad intelectual y los derechos de autor de otros. Usted puede ser
        responsable por daños y perjuicios por tergiversar que cualquier Contenido está infringiendo
        sus derechos de autor. Nuestra política es responder a cualquier reclamo de que el Contenido
        publicado en el Servicio infringe los derechos de autor u otra infracción de propiedad
        intelectual de cualquier persona.
      </p>
      <p>
        Estamos listos para cumplir con las regulaciones locales en esa materia (Ley de derechos de
        autor del milenio digital (DMCA), Directiva de derechos de autor de la UE, ...).
      </p>
      <p>
        Si es propietario de derechos de autor o está autorizado en nombre de uno, y cree que el
        trabajo protegido por derechos de autor se ha copiado de una manera que constituye una
        infracción de derechos de autor que se está produciendo a través del Servicio, debe enviar
        su notificación por escrito por correo electrónico (consulte apartado 2 - Contacto) e
        incluya en su notificación la siguiente información relacionada con la supuesta infracción:
      </p>
      <ul>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Una firma electrónica o física de la persona autorizada para actuar en nombre del
          propietario de los derechos de autor.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Una descripción del trabajo protegido por derechos de autor que Usted afirma que se ha
          infringido, incluida la URL (es decir, la dirección de la página web) de la ubicación
          donde existe el trabajo protegido por derechos de autor o una copia del trabajo protegido
          por derechos de autor.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Identificación de la URL u otra ubicación específica en el Servicio donde se encuentra el
          material que Usted afirma que está infringiendo.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Su dirección, número de teléfono y dirección de correo electrónico.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por
          el propietario de los derechos de autor, su agente o la ley.
        </li>
        <li style={{ listStyle: 'disc', marginLeft: '20px' }}>
          Una declaración suya, realizada bajo pena de perjurio, de que la información anterior en
          su aviso es precisa y que Usted es el propietario de los derechos de autor o está
          autorizado para actuar en nombre del propietario de los derechos de autor.
        </li>
      </ul>
      <p>
        Al recibir una notificación, la Compañía tomará las medidas que, a su entera discreción,
        consideren apropiadas, incluida la eliminación del contenido cuestionado del Servicio.
      </p>
      <h2>7 - Pedido de Bienes</h2>
      <p>
        Al realizar un Pedido de Bienes a través del Servicio, Usted garantiza que es legalmente
        capaz de celebrar contratos vinculantes.
      </p>
      <h3>7.1 - Posición del Servicio en los Pedidos</h3>
      <p>
        Nuestro papel es el de un facilitador entre Usted y los Vendedores, utilizando el Servicio.
        Somos, por lo tanto, un tercero en los Pedidos, lo que limita Nuestras responsabilidades en
        cualquier disputa entre Usted y los Vendedores a la Política de Cancelación.
      </p>
      <p>
        No somos parte de ningún acuerdo que tenga con los Vendedores. Cualquier acuerdo que celebre
        con los Vendedores no forma parte de ningún acuerdo que tengamos con Usted.
      </p>
      <h3>7.2 - Su Información como Comprador</h3>
      <p>
        Si Ud. desea realizar un Pedido de Bienes disponibles en el Servicio, se le puede solicitar
        que proporcione cierta información relevante para su Pedido, que incluye, entre otros, su
        nombre, su correo electrónico, su número de teléfono, su número de tarjeta de crédito, la
        fecha de vencimiento de su tarjeta de crédito y su dirección de facturación.
      </p>
      <p>
        Usted declara y garantiza que: (i) tiene el derecho legal de usar cualquier tarjeta de
        crédito o débito u otro método de pago en relación con cualquier Pedido; y que (ii) la
        información que nos proporciona es verdadera, correcta y completa.
      </p>
      <p>
        Al enviar dicha información, nos otorga el derecho de proporcionar la información a terceros
        que procesan pagos con el fin de facilitar la finalización de su pedido.
      </p>
      <h3>7.3 - Política de Precios</h3>
      <p>
        La Compañía y el Vendedor se reservan el derecho de revisar sus precios en cualquier momento
        antes de aceptar un Pedido.
      </p>
      <h3>7.5 - Pagos</h3>
      <p>
        El pago se puede realizar a través de tarjetas de crédito o débito a través de la pasarela
        de pago Stripe. Confiamos en Stripe, que es una operad de procesamiento de pagos con el más
        estricto nivel de certificación en el pago en línea y tienen sus propias Condiciones
        Generales de Uso y pueden tener sus propias limitaciones.
      </p>
      <p>
        Las tarjetas de pago (tarjetas de crédito o tarjetas de débito) están sujetas a controles de
        validación y autorización por parte del emisor de su tarjeta. Si no recibimos la
        autorización requerida, no seremos responsables de ninguna cancelación de su Pedido.
      </p>
      <h3>7.6 - Tarifas de Servicio</h3>
      <p>
        Podemos cobrarle algunas tarifas (e Impuestos aplicables) por el derecho a solicitar un
        Bien. Durante su Pedido se muestra más información sobre cuándo se aplican las tarifas de
        servicio y cómo se calculan. Nos reservamos el derecho de cambiar las tarifas de servicio en
        cualquier momento.
      </p>
      <h3>7.7 - Modificación de Pedido</h3>
      <p>
        Usted y los Vendedores son responsables de cualquier modificación del Pedido que acuerden
        realizar a través del Servicio.
      </p>
      <div id="order-cancel">
        <h3>7.8 - Cancelación de Pedidos</h3>
        <p>7.8.1 - Cancelación de Pedidos por parte de los Compradores</p>
        <p>
          Si Usted como Comprador cancela un Pedido, la cantidad reembolsada dependerá de cuándo se
          cancele el Pedido:
        </p>
        <p>
          Si un Comprador cancela dentro de las 48 horas posteriores a la realización del pago o más
          de 14 días antes del primer día de la estancia, el Comprador recibirá un reembolso
          completo.
        </p>
        <p>
          Si un Comprador cancela entre 14 y 8 días antes del comienzo de la estancia, el Comprador
          recibirá un reembolso del 50% del importe total, y el otro 50% se transfiere al Vendedor.
        </p>
      </div>
      <p>
        Si un Comprador cancela 7 días antes o menos del inicio de la estancia, el Comprador no
        recibirá ningún reembolso y el Vendedor recibirá el 100% del importe total.
      </p>
      <p>
        En cualquiera de los casos anteriores, la Compañía retendrá las Tarifas del Servicio. Por lo
        tanto, "reembolso completo" o "cantidad total" solo se refiere al precio del Pedido y no
        incluye las Tarifas de servicio cobradas por la Compañía.
      </p>
      <p>7.8.2 - Cancelación de Pedidos por parte de los Vendedores</p>
      <p>
        Si Usted, como Vendedor, cancela un Pedido en cualquier momento antes de que comience la
        estancia, la cantidad que pagó el Comprador (incluidas las Tarifas del Servicio, que serán
        cargadas a Ud.) se reembolsará al Comprador y no se transferirá al Vendedor.
      </p>
      <p>
        Si Usted como Vendedor cancela un Pedido durante la estancia en curso, deberá reembolsar al
        Comprador el importe correspondiente a los días restantes de estancia (incluidas las Tarifas
        del Servicio, que serán cargadas a Ud.).
      </p>
      <h3>7.9 - Disputa de Pedido</h3>
      <p>
        Si un Comprador o un Vendedor disputan un Pedido, se debe notificar a la Compañía. La
        disputa se resolverá a nuestro exclusivo criterio y se podrá procesar un reembolso total o
        parcial.
      </p>
      <h3>7.10 – Contratiempo en el Pedido</h3>
      <p>
        Si algún contratiempo impide el correcto desarrollo del Pedido, la Compañía podrá tramitar
        un reembolso total o parcial al Comprador a su sola discreción.
      </p>
      <h2>8 - Renuncia de Garantías y Limitación de Responsabilidad</h2>
      <h3>8.1 - Limitación de Responsabilidad</h3>
      <p>
        Sin perjuicio de los daños en los que Usted pueda incurrir, la responsabilidad total de la
        Compañía en virtud de cualquier disposición de estas Condiciones Generales de Uso y su
        compensación exclusiva para todo lo precedente se limitará a la cantidad pagada por Usted a
        través del Servicio o a 100 USD (o su equivalente en la moneda local del Servicio) si no ha
        comprado nada a través del Servicio.
      </p>
      <p>
        En la medida máxima permitida por la ley aplicable, en ningún caso la Compañía será
        responsables de daños o pérdidas indirectas o consecuentes (incluidos, entre otros, daños
        por pérdida de beneficios, pérdida de datos u otra información, por interrupción del
        negocio, por lesiones personales, por materiales personales, pérdida de privacidad que surja
        o esté relacionada de alguna manera con el uso o la incapacidad de usar el Servicio,
        software de terceros y/o hardware de terceros utilizado con el Servicio, o de otro modo en
        relación con cualquier disposición de estas Condiciones Generales de Uso), incluso si la
        Compañía o cualquier proveedor han sido informados de la posibilidad de tales daños e
        incluso si el remedio no cumple con su propósito esencial.
      </p>
      <p>
        Algunas jurisdicciones no permiten la exclusión de garantías implícitas o la limitación de
        responsabilidad por daños incidentales o consecuentes, lo que significa que algunas de las
        limitaciones anteriores pueden no aplicarse. En estas jurisdicciones, la responsabilidad de
        cada parte se limitará en la mayor medida permitida por la ley.
      </p>
      <h3>8.2 - Descargo de Responsabilidad</h3>
      <p>
        La Compañía no es responsable de que el Servicio cumpla con sus requisitos o que se logren
        los resultados de aprendizaje previstos.
      </p>
      <p>
        La Compañía no hace ninguna representación o garantía de ningún tipo, expresa o implícita:
        (i) en cuanto a la operación o disponibilidad del Servicio, o la información, contenido y
        materiales o productos incluidos en el mismo; (ii) que el Servicio será ininterrumpido o
        libre de contratiempos; (iii) en cuanto a la precisión, veracidad o actualidad de cualquier
        información o contenido proporcionado a través del Servicio; o (iv) que el Servicio, sus
        servidores, el contenido o los correos electrónicos enviados desde o en nombre de la
        Compañía están libres de virus, secuencias de comandos, troyanos, gusanos, malware u otros
        componentes dañinos.
      </p>
      <p>
        Algunas jurisdicciones no permiten la exclusión de ciertos tipos de garantías o limitaciones
        de los derechos legales aplicables de un consumidor, por lo que es posible que algunas o
        todas las exclusiones y limitaciones anteriores no se apliquen a Usted. Pero en tal caso,
        las exclusiones y limitaciones establecidas en esta sección se aplicarán en la mayor medida
        exigible según la ley aplicable.
      </p>
      <h3>8.3 – Enlaces a otros Sitios Webs</h3>
      <p>
        Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son
        propiedad ni están controlados por la Compañía.
      </p>
      <p>
        La Compañía no tiene control ni asume ninguna responsabilidad por el contenido, las
        políticas de privacidad o las prácticas de los sitios web o servicios de terceros. Además,
        Ud. reconoce y acepta que la Compañía no será responsable, directa o indirectamente, de
        ningún daño o pérdida causados ​​o presuntamente causados ​​por o en relación con el uso o
        la confianza en dicho contenido, bienes o servicios disponibles en o a través de tales
        sitios web o servicios.
      </p>
      <p>
        Le recomendamos encarecidamente que lea las Condiciones Generales de Uso y las Políticas de
        Privacidad de cualquier sitio web o servicio de terceros que visite.
      </p>
      <h3>8.4 – Interpretación de la Traducción</h3>
      <p>
        Estas Condiciones Generales de Uso pueden haber sido traducidos si los hemos puesto a su
        disposición en nuestro Servicio. Usted acepta que el texto original en inglés prevalecerá en
        caso de disputa.
      </p>
      <h3>8.5 – Preguntas Frecuentes (FAQs)</h3>
      <p>
        Nuestro Servicio contiene una página web con Preguntas Frecuentes (FAQs). El contenido de
        dicha página web está de acuerdo con estas Condiciones de Generales de Uso. En el caso de
        que el contenido de las Preguntas Frecuentes no esté totalmente de acuerdo con estas
        Condiciones de Generales de Uso, el contenido de las Condiciones de Generales de Uso
        prevalecerá sobre el de las Preguntas frecuentes.
      </p>
      <h2>9 - Resolución de Disputas sobre el Servicio</h2>
      <p>
        Si tiene algún problema o disputa sobre el Servicio, acepta intentar primero resolver la
        disputa de manera informal comunicándose con la Compañía.
      </p>
      <h2>10 - Propiedad Intelectual del Servicio</h2>
      <p>
        El Servicio y su contenido original (excluyendo el Contenido proporcionado por Usted u otros
        usuarios), las características y la funcionalidad son y seguirán siendo propiedad exclusiva
        de la Compañía.
      </p>
      <p>
        El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto de
        España como de países extranjeros.
      </p>
      <p>
        Nuestras marcas registradas e imagen comercial no se pueden usar en relación con ningún
        producto o servicio sin el consentimiento previo por escrito de la Compañía.
      </p>
      <h2>11 - Sus Comentarios para Nosotros</h2>
      <p>
        Usted asigna todos los derechos, títulos e intereses en cualquier Comentario que proporcione
        a la Compañía. Si por alguna razón dicha cesión no es efectiva, Usted acepta otorgar a la
        Compañía un derecho y una licencia no exclusivos, perpetuos, irrevocables, gratuitas e
        internacionales para usar, reproducir, divulgar, sublicenciar, distribuir, modificar y
        explotar dicha retroalimentación sin restricción.
      </p>
      <h2>12 - Cambios a estas Condiciones Generales de Uso</h2>
      <p>
        Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estas
        Condiciones Generales de Uso. Si una revisión es importante, haremos todos los esfuerzos
        razonables para proporcionar un aviso de al menos 30 días antes de que entren en vigencia
        las nuevas Condiciones Generales de Uso. Lo que constituye un cambio material se determinará
        a nuestro exclusivo criterio.
      </p>
      <p>
        Al continuar accediendo o utilizando nuestro Servicio después de que esas revisiones entren
        en vigencia, Usted acepta estar sujeto a las Condiciones revisadas. Si no está de acuerdo
        con las nuevas Condiciones, en su totalidad o en parte, deje de usar la Aplicación o el
        Sitio web y el Servicio.
      </p>
    </>
  );
}
