import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { Avatar, NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import css from './ListingCard.module.css';
import { getTotalWithCommission } from '../BookingBreakdown/LineItemRefundMaybe';
import { useSelector } from 'react-redux';
import { ACTIVITY, PRIVATE_TOUR } from '../EditListingWizard/EditListingWizardTab';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    isLandingPage = false,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const state = useSelector(state => state);
  const { language } = state.user;
  const lang = language === 'english' ? 'en' : 'es';
  let { title = '', price, publicData } = currentListing.attributes;
  const {
    es_title = title,
    category,
    es_subtitle,
    subtitle,
    people,
    duration,
    priceType,
    week_price,
    month_price,
    includeAccommodation,
    price_original,
  } = publicData || {};
  if (language === 'english') {
    title = title;
  } else {
    title = es_title;
  }
  const isFixedPrice = priceType === 'fixed_price';
  const pricePerPerson = publicData?.pricePerPerson
    ? publicData?.pricePerPerson?.sort((a, b) => a.price - b.price)?.filter(item => item.price > 0)
    : [];
  let priceRange = null;

  if (pricePerPerson?.length > 1 && !isFixedPrice) {
    priceRange =
      formatMoney(intl, new Money(pricePerPerson[0]?.price, config.currency)) +
      ' - ' +
      formatMoney(
        intl,
        new Money(pricePerPerson[pricePerPerson.length - 1]?.price, config.currency)
      );
  }

  const isStay = category === 'category1';
  const isActivity = category === ACTIVITY;
  const isPrivate = category === PRIVATE_TOUR;
  let subtitleShow = null;
  if (isActivity || isPrivate) {
    if (language === 'english') {
      subtitleShow = subtitle;
    } else {
      subtitleShow = es_subtitle;
    }
  }
  const isPrivateOrActivity = category == PRIVATE_TOUR || category == ACTIVITY;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const modifiedPrice = price_original
    ? price
    : price && price.amount
    ? new Money(getTotalWithCommission(price.amount), price.currency)
    : null;
  const { formattedPrice, priceTitle } = priceData(modifiedPrice, intl);
  const weekPrice = week_price ? new Money(week_price, config.currency) : null;
  const monthPrice = month_price ? new Money(month_price, config.currency) : null;
  const weekPriceLabel = weekPrice ? formatMoney(intl, weekPrice) : null;
  const monthPriceLabel = monthPrice ? formatMoney(intl, monthPrice) : null;
  const getTitle = title => (title.length > 80 ? title.substring(0, 70) + '...' : title);
  const getMainTitle = title => (title.length > 70 ? title.substring(0, 60) + '...' : title);
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  if (!publicData) return null;
  let categoryForSlug = category;
  switch (category) {
    case 'category1':
      categoryForSlug = 'stay';
      break;
    case 'private_tours':
      categoryForSlug = 'immersion_programs';
      break;
    case 'activity':
      categoryForSlug = 'immersion-activities';
      break;
    default:
      categoryForSlug = category;
  }
  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ lang, id, slug, category: categoryForSlug || 'category1' }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        <div className={css.avtarContainer}>
          <Avatar className={css.avatar} user={author} disableProfileLink disableProfileName />
          <span className={css.authorname}>{authorName}</span>
        </div>
      </div>
      {isStay ? (
        <div className={css.infoStay}>
          <div className={css.titleLanding} title={title}>
            {richText(getMainTitle(title), {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={isLandingPage ? css.stayPriceLanding : css.stayPrice}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
              <p className={css.stayLabel}>
                <FormattedMessage id="ListingCard.ListingCard.pernight" />
              </p>
            </div>
            {weekPriceLabel ? (
              <div className={css.priceValue} title={priceTitle}>
                {weekPriceLabel}
                <p className={css.stayLabel}>
                  {' '}
                  <FormattedMessage id="ListingCard.ListingCard.perweek" />
                </p>
              </div>
            ) : null}
            {monthPriceLabel ? (
              <div className={css.priceValue} title={priceTitle}>
                {monthPriceLabel}
                <p className={css.stayLabel}>
                  {' '}
                  <FormattedMessage id="ListingCard.ListingCard.permonth" />
                </p>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={css.info}>
          <div className={css.price}>
            {isLandingPage ? (
              <div className={css.priceValueLanding} title={priceTitle}>
                {priceRange || formattedPrice}
              </div>
            ) : (
              <div className={css.priceValue} title={priceTitle}>
                {priceRange || formattedPrice}
              </div>
            )}
            <div className={css.perUnit}>
              {isPrivateOrActivity ? (
                isPrivate && isFixedPrice ? (
                  <FormattedMessage id="BookingPanel.BookingPanel.perProgram" />
                ) : isActivity && isFixedPrice ? (
                  <FormattedMessage id="BookingPanel.BookingPanel.perActivity" />
                ) : (
                  <FormattedMessage id="ListingPage.SectionHeading.perperson" />
                )
              ) : (
                // <FormattedMessage id="ListingCard.ListingCard.perperson" />
                <FormattedMessage id={unitTranslationKey} />
              )}
            </div>
          </div>
          <div className={css.mainInfo}>
            {isLandingPage ? (
              <div className={css.titleLanding}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            ) : (
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            )}
            {isActivity ? (
              <div className={css.activityContent}>
                {subtitleShow ? (
                  <p className={css.subTitle} title={subtitleShow}>
                    {getTitle(subtitleShow)}
                  </p>
                ) : null}
                {people ? (
                  <p className={css.people}>
                    {intl.formatMessage({ id: 'ListingCard.ListingCard.forUpTo' })}{' '}
                    <span className={css.green}>
                      <FormattedMessage
                        id="ListingCard.ListingCard.people"
                        values={{ people: people }}
                      />
                    </span>
                  </p>
                ) : null}
                {duration ? (
                  <p className={css.duration}>
                    {intl.formatMessage({ id: 'ListingCard.ListingCard.duration' })}{' '}
                    <span className={css.green}>{duration}h</span>
                  </p>
                ) : null}
              </div>
            ) : isPrivate ? (
              <div className={css.activityContent}>
                {subtitleShow ? (
                  <p className={css.subTitle} title={subtitleShow}>
                    {getTitle(subtitleShow)}
                  </p>
                ) : null}
                {people ? (
                  <p className={css.people}>
                    {intl.formatMessage({ id: 'ListingCard.ListingCard.forUpTo' })}{' '}
                    <span className={css.green}>
                      <FormattedMessage
                        id="ListingCard.ListingCard.people"
                        values={{ people: people }}
                      />
                    </span>
                  </p>
                ) : null}
                {duration ? (
                  <p className={css.duration}>
                    {intl.formatMessage({ id: 'ListingCard.ListingCard.duration' })}{' '}
                    <span className={css.green}>
                      <FormattedMessage
                        id="ListingCard.ListingCard.days"
                        values={{ duration: duration }}
                      />
                    </span>
                  </p>
                ) : null}
                {includeAccommodation == 'yes' ? (
                  <p className={css.duration}>
                    <span className={css.greenBold}>
                      {intl.formatMessage({ id: 'ListingCard.ListingCard.include' })}{' '}
                      <span>
                        {intl.formatMessage({ id: 'ListingCard.ListingCard.accommodation' })}
                      </span>
                    </span>
                  </p>
                ) : null}
              </div>
            ) : (
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
              </div>
            )}
          </div>
        </div>
      )}
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
